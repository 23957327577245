var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, CrossIcon, Pane, Paragraph, TextInput } from 'evergreen-ui';
import { useEffect, useState } from 'react';
//@ts-ignore
import { motion } from 'framer-motion/dist/framer-motion';
export var FavoritesGroupEditBar = function (_a) {
    var _b;
    var selectedFav = _a.selectedFav, group = _a.group, resetGroup = _a.resetGroup, update = _a.update;
    var _c = useState(''), name = _c[0], setName = _c[1];
    useEffect(function () {
        if (group === null || group === void 0 ? void 0 : group.name)
            setName(group === null || group === void 0 ? void 0 : group.name);
    }, [group]);
    return (_jsx(_Fragment, { children: group && (_jsx(motion.div, __assign({ hover: { scale: 0.95 }, initial: { opacity: 0, y: 100 }, animate: {
                y: group ? 0 : 50,
                opacity: group ? 1 : 0,
            }, transition: { duration: 0.5 }, style: { position: 'absolute', zIndex: 200, bottom: 20 } }, { children: _jsxs(Pane, __assign({ elevation: 2, display: "flex", padding: 15, justifyContent: "space-between", alignItems: "center", position: "relative", style: {
                    backgroundColor: '#2D3B51',
                    width: '65vw',
                    borderRadius: 24,
                } }, { children: [_jsx("div", { children: _jsxs(Pane, __assign({ marginBottom: 6 }, { children: [_jsxs(Paragraph, __assign({ color: "white", fontWeight: "bold" }, { children: ["Gruppe bearbeiten (inkludiert ", (_b = group === null || group === void 0 ? void 0 : group.ids) === null || _b === void 0 ? void 0 : _b.length, " Favoriten)"] })), _jsx(TextInput, { borderRadius: 8, value: name, onChange: function (e) { return setName(e.target.value); } })] })) }), _jsxs(Pane, __assign({ display: "flex", justifyItems: "center", alignItems: "center", gap: 10 }, { children: [_jsxs(Button, __assign({ onClick: function () { return update(name); } }, { children: [selectedFav === null || selectedFav === void 0 ? void 0 : selectedFav.length, " Favoritengruppe updaten"] })), _jsx(CrossIcon, { onClick: resetGroup, marginLeft: 14, cursor: "pointer", color: "white" })] }))] })) }))) }));
};
