var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Heading, Pane, Paragraph } from "evergreen-ui";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import firebase from "../config";
import LogoBig from "./LogoBig";
import TourAccountingLayoutSection from "./TourAccountingLayoutSection";
var TourAccountingLayout = React.forwardRef(function (_a, ref) {
    var tour = _a.tour, driver = _a.driver;
    var _b = useState([]), relevantTasks = _b[0], setRelevantTasks = _b[1];
    var _c = useState([]), payedTaskIds = _c[0], setPayedTaskIds = _c[1];
    useEffect(function () {
        (function () { return __awaiter(void 0, void 0, void 0, function () {
            var moneyStoreIds, notPayedIds, docs, noZero;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        moneyStoreIds = tour.moneyStore.map(function (m) { return m.id; });
                        notPayedIds = tour.successfullTasks.filter(function (id) { return !moneyStoreIds.includes(id); });
                        return [4 /*yield*/, Promise.all(__spreadArray(__spreadArray([], notPayedIds, true), moneyStoreIds, true).map(function (taskId) { return __awaiter(void 0, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, firebase.firestore().collection("tasks").doc(taskId).get()];
                                        case 1: return [2 /*return*/, (_a.sent()).data()];
                                    }
                                });
                            }); }))];
                    case 1:
                        docs = _a.sent();
                        noZero = docs.filter(function (t) { return t.task_open_amount > 0 && t.task_status === "successfull"; });
                        setPayedTaskIds(moneyStoreIds);
                        setRelevantTasks(noZero);
                        return [2 /*return*/];
                }
            });
        }); })();
    }, [tour.moneyStore, tour.successfullTasks]);
    var separatedByLocations = useMemo(function () {
        return relevantTasks.reduce(function (acc, obj) {
            var index = obj.task_origin;
            if (acc[index]) {
                acc[index].push(obj);
            }
            else {
                acc[index] = [obj];
            }
            return acc;
        }, {});
    }, [relevantTasks]);
    return (_jsxs(Pane, __assign({ ref: ref, style: { width: "100%", height: "100%", padding: 10 } }, { children: [_jsxs(Pane, __assign({ style: {
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                } }, { children: [_jsx(LogoBig, {}), _jsx(Heading, __assign({ style: { color: "black", marginTop: 20, fontSize: 28 } }, { children: "TOUR-ABRECHNUNG" })), _jsx(Pane, { style: { width: 54, height: 54 } })] })), _jsxs(Pane, __assign({ pageBreakBefore: "always", display: "flex", flexDirection: "row", alignItems: "center" }, { children: [_jsxs(Paragraph, { children: ["Datum: ", moment(tour.date, "YY-MM-DD").format("DD.MM.YYYY")] }), _jsxs(Paragraph, __assign({ marginLeft: "5" }, { children: ["Fahrer: ", driver === null || driver === void 0 ? void 0 : driver.firstName, " ", driver === null || driver === void 0 ? void 0 : driver.lastName] }))] })), _jsxs(Pane, __assign({ pageBreakBefore: "always", display: "flex", flexDirection: "row", alignItems: "center" }, { children: [_jsxs(Paragraph, { children: ["Startzeit: ", tour.actualStartTime, " Uhr"] }), _jsxs(Paragraph, __assign({ marginLeft: "5" }, { children: ["Endzeit: ", tour.actualEndTime, " Uhr"] }))] })), Object.entries(separatedByLocations).map(function (_a) {
                var k = _a[0], v = _a[1];
                return (_jsx(TourAccountingLayoutSection, { id: k, tasks: v, tour: tour, payedTaskIds: payedTaskIds }));
            })] })));
});
export default TourAccountingLayout;
