var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Badge, Button, Combobox, LayoutHierarchyIcon, Pane, Popover, Position, } from "evergreen-ui";
import "firebase/firestore";
import { useContext, useState } from "react";
import firebase from "../config";
import { DriverContext } from "../contexts/DriverContext";
import { PaymentContext } from "../contexts/PaymentContext";
import { optimizeTour } from "../utility/optimizeTour";
export default function ReoptimizeBadge(_a) {
    var _this = this;
    var tour = _a.tour;
    var _b = useState(false), loading = _b[0], setLoading = _b[1];
    //PaymentDialog
    var quotaManagementState = useContext(PaymentContext).quotaManagementState;
    var _ = quotaManagementState[0], setQuotaManagementShown = quotaManagementState[1];
    var drivers = useContext(DriverContext).drivers;
    var allDrivers = drivers[0];
    var makeDriverChange = function (_a) {
        var data = _a.data;
        firebase
            .firestore()
            .collection("tours")
            .doc(tour.id)
            .update({ driver: data.id });
    };
    var addTasksToTour = function (tour) { return __awaiter(_this, void 0, void 0, function () {
        var result, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    setLoading(true);
                    return [4 /*yield*/, optimizeTour(tour)];
                case 1:
                    result = _a.sent();
                    if (result.data.msg === "error: usagequota exhausted")
                        setQuotaManagementShown(true);
                    setLoading(false);
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    setLoading(false);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (_jsx(Popover, __assign({ position: Position.BOTTOM_LEFT, shouldCloseOnExternalClick: true, content: function (_a) {
            var close = _a.close;
            return tour.driver ? (_jsx(Pane, __assign({ padding: 5 }, { children: _jsx(Button, __assign({ isLoading: loading, onClick: function () {
                        addTasksToTour(tour);
                        close();
                    }, iconAfter: LayoutHierarchyIcon, intent: "success" }, { children: "Jetzt neu optimieren" })) }))) : (_jsx(Combobox, { style: { width: 180 }, marginLeft: 10, items: allDrivers.map(function (driver) { return ({
                    name: driver.firstName + " " + driver.lastName,
                    data: driver,
                }); }), selectedItem: undefined, onChange: makeDriverChange, itemToString: function (item) { return (item ? item.name : ""); }, placeholder: "Fahrer w\u00E4hlen" }));
        } }, { children: _jsx(Badge, __assign({ isInteractive: true, color: "#dd3b30" }, { children: "Optimieren" })) })));
}
