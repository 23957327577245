var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useContext, useEffect } from "react";
import { Pane, Paragraph, Heading, Button, ChevronLeftIcon, toaster, TextInput, } from "evergreen-ui";
import validator from "validator";
import { sendNewCode } from "../utility/sendNewCode";
import NavigatorBar from "../container/NavigatorBar";
import { useHistory } from "react-router-dom";
import firebase from "../config";
import "firebase/auth";
import "firebase/functions";
import "firebase/firestore";
import { UserContext } from "../contexts/UserContext";
export default function Account() {
    var _a = useContext(UserContext), user = _a.user, ready = _a.ready;
    var isUser = user[0];
    var _b = useState(false), isLoading = _b[0], setLoading = _b[1];
    //UserData
    var _c = useState(0), fieldsChanged = _c[0], setFieldsChanged = _c[1];
    var _d = useState(isUser.businessName), businessName = _d[0], setBusinessName = _d[1];
    var _e = useState(isUser.firstName), firstName = _e[0], setFirstName = _e[1];
    var _f = useState(isUser.lastName), lastName = _f[0], setLastName = _f[1];
    var _g = useState(undefined), password = _g[0], setPassword = _g[1];
    var _h = useState(isUser.email), email = _h[0], setEmail = _h[1];
    var _j = useState(false), isPortalLoading = _j[0], setPortalLoading = _j[1];
    var history = useHistory();
    function updateProfileData() {
        firebase
            .firestore()
            .collection("users")
            .doc(firebase.auth().currentUser.uid)
            .update({
            lastName: lastName,
            firstName: firstName,
            email: email,
            businessName: businessName,
        })
            .then(function () {
            setLoading(false);
            toaster.success("Ihre Stammdaten wurden aktualisiert!");
        })
            .catch(function (err) {
            setLoading(false);
            console.log(err);
            toaster.danger("Ihre Stammdaten konnten nicht aktualisiert werden!");
        });
    }
    function updateEmailOfUser(email) {
        var _this = this;
        setLoading(true);
        if (validator.isEmail(email)) {
            firebase
                .auth()
                .currentUser.updateEmail(email)
                .then(function () {
                firebase
                    .firestore()
                    .collection("users")
                    .doc(firebase.auth().currentUser.uid)
                    .update({
                    email: email,
                })
                    .then(function () { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        setLoading(false);
                        sendNewCode(email, isUser.firstName);
                        toaster.success("Ihre E-Mail Adressen wurde erfolgreich aktualisiert!");
                        return [2 /*return*/];
                    });
                }); })
                    .catch(function (error) {
                    console.log(error);
                    toaster.danger("Ihre E-Mail Adressen sind nicht in Syncronisation. Bitte beim Support melden!");
                    setLoading(false);
                });
            })
                .catch(function (error) {
                if (error.message === "CREDENTIAL_TOO_OLD_LOGIN_AGAIN") {
                    toaster.danger("Für Ihre Sicherheit! Sie sind zu lange angemeldet! Bitte melden Sie sich aus und nochmal ein, damit Sie die E-Mail Adresse ändern können");
                }
                setLoading(false);
            });
        }
        else {
            toaster.warning("Sie müssen eine gültige E-Mail Adresse angeben!");
            setLoading(false);
        }
    }
    function updatePassword(password) {
        if (password.length < 6) {
            toaster.warning("Das Passwort muss mindestens 6 Zeichen haben!");
            setLoading(false);
            return;
        }
        firebase
            .auth()
            .currentUser.updatePassword(password)
            .then(function () {
            setLoading(false);
            toaster.success("Ihre Passwort wurde erfolgreich aktualisiert!");
        })
            .catch(function (error) {
            if (error.code === "auth/requires-recent-login") {
                toaster.danger("Für Ihre Sicherheit! Sie sind zu lange angemeldet! Bitte melden Sie sich aus und nochmal ein, damit Sie das Passwort ändern können!");
            }
            console.log(error);
            setLoading(false);
        });
    }
    function saveChanges() {
        if (email !== isUser.email) {
            updateEmailOfUser(email);
            updateProfileData();
        }
        if (password !== undefined) {
            updatePassword(password);
        }
        if (firstName !== isUser.firstName ||
            lastName !== isUser.lastName ||
            businessName !== isUser.businessName) {
            updateProfileData();
        }
        setFieldsChanged(0);
    }
    function resetChanges() {
        setFirstName(isUser.firstName);
        setLastName(isUser.lastName);
        setBusinessName(isUser.businessName);
        setEmail(isUser.email);
        setFieldsChanged(0);
    }
    useEffect(function () {
        var count = 0;
        if (firstName !== isUser.firstName) {
            count++;
        }
        if (lastName !== isUser.lastName) {
            count++;
        }
        if (email !== isUser.email) {
            count++;
        }
        if (password !== undefined) {
            count++;
        }
        if (businessName !== isUser.businessName) {
            count++;
        }
        setFieldsChanged(count);
    }, [
        firstName,
        lastName,
        email,
        password,
        isUser.firstName,
        isUser.lastName,
        isUser.email,
        isUser.businessName,
        isUser.latestOrderTime,
        businessName,
    ]);
    return (_jsxs(Pane, __assign({ style: {
            display: "flex",
            flexDirection: "row",
            width: "100%",
            background: "linear-gradient(-3deg,#E4EBF5 0%, rgba(255,255,255,1) 100%)",
        } }, { children: [_jsx(NavigatorBar, {}), _jsxs(Pane, __assign({ style: { padding: 25, margin: 25, width: "50vw", overflow: "scroll" } }, { children: [_jsx(Button, __assign({ onClick: function () { return history.push("/settings"); }, marginY: 8, marginRight: 12, iconBefore: ChevronLeftIcon }, { children: "Zur\u00FCck" })), _jsx(Heading, __assign({ size: 900 }, { children: "Account" })), _jsx(Paragraph, __assign({ size: 400 }, { children: "Allgemeine Kontoinformationen" })), _jsx("form", __assign({ autoComplete: "off" }, { children: _jsxs(Pane, __assign({ style: { display: "flex", flexDirection: "column", margin: 20 } }, { children: [_jsx(Heading, __assign({ size: 400 }, { children: "Gesch\u00E4ftsname:" })), _jsx(TextInput, { autoComplete: "off", margin: 5, onChange: function (e) { return setBusinessName(e.target.value); }, value: businessName }), _jsx(Heading, __assign({ size: 400 }, { children: "Vorname:" })), _jsx(TextInput, { autoComplete: false, margin: 5, onChange: function (e) { return setFirstName(e.target.value); }, value: firstName }), _jsx(Heading, __assign({ size: 400 }, { children: "Nachname:" })), _jsx(TextInput, { autoComplete: false, margin: 5, onChange: function (e) { return setLastName(e.target.value); }, value: lastName }), _jsx(Heading, __assign({ size: 400 }, { children: "E-Mail Adresse:" })), _jsx(TextInput, { margin: 5, onChange: function (e) { return setEmail(e.target.value); }, value: email }), _jsx(Heading, __assign({ size: 400 }, { children: "Passwort:" })), _jsx(TextInput, { autoComplete: "off", type: "password", margin: 5, password: true, onChange: function (e) { return setPassword(e.target.value); }, value: password }), _jsx(Paragraph, __assign({ style: { color: "grey" } }, { children: firebase.auth().currentUser.uid }))] })) }))] })), _jsxs(Pane, __assign({ elevation: 4, padding: 20, style: {
                    zIndex: 20,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    position: "absolute",
                    width: "100%",
                    height: "10%",
                    top: fieldsChanged !== 0 ? "90%" : "100%",
                    opacity: fieldsChanged !== 0 ? "100%" : "0%",
                    transition: "300ms ease",
                    backgroundColor: "#c1c4d6",
                } }, { children: [_jsx(Button, __assign({ isLoading: isLoading, margin: 10, className: "bodytext", appearance: "primary", onClick: function () { return saveChanges(); } }, { children: "Speichern" })), _jsx(Button, __assign({ disabled: isLoading, margin: 10, className: "bodytext", onClick: function () { return resetChanges(); } }, { children: "Verwerfen" })), _jsxs(Paragraph, __assign({ className: "bodytext" }, { children: ["Sie haben ", fieldsChanged, " Einstellung", fieldsChanged > 1 && "en", " ", "ge\u00E4ndert"] }))] }))] })));
}
