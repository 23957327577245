var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import moment from "moment";
import { createContext, useEffect, useState } from "react";
import firebase from "../config";
export var TaskContext = createContext(null);
export default function TaskContextProvider(_a) {
    var children = _a.children;
    //Store for all tasks of an organization
    var _b = useState([]), areTasks = _b[0], setTasks = _b[1];
    //Store for the Headers which are mandatory for the CSVImporter
    var _c = useState([]), areHeaders = _c[0], setHeaders = _c[1];
    //Store for all tours of an organization
    var _d = useState([]), areTours = _d[0], setTours = _d[1];
    //Open and Done Task Save
    var _e = useState([]), openTasks = _e[0], setOpenTasks = _e[1];
    var _f = useState([]), doneTasks = _f[0], setDoneTasks = _f[1];
    var _g = useState([]), allOwnersOpenTasks = _g[0], setAllOwnersOpenTasks = _g[1];
    useEffect(function () {
        setupDoneTaskListener();
        setupTaskListener();
        setupTourListener();
        setupAllOwnersOpenTasks();
    }, []);
    // Get and continuously update the tours for the current user
    function setupTourListener() {
        var _this = this;
        firebase
            .firestore()
            .collection("tours")
            .where("owner", "==", firebase.auth().currentUser.uid)
            .where("status", "in", ["prepared", "ontheway", "empty", "pickedup"])
            .onSnapshot(function (snap) { return __awaiter(_this, void 0, void 0, function () {
            var openTours, rawProcessedTours, processedTours;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        openTours = snap.docs.map(function (rawTour) { return rawTour.data(); });
                        return [4 /*yield*/, firebase
                                .firestore()
                                .collection("tours")
                                .where("owner", "==", firebase.auth().currentUser.uid)
                                .where("status", "==", "finished")
                                .where("processed_date", ">=", moment().startOf("day").toDate())
                                .where("processed_date", "<=", moment().endOf("day").toDate())
                                .orderBy("processed_date", "desc")
                                .get()];
                    case 1:
                        rawProcessedTours = _a.sent();
                        processedTours = rawProcessedTours.docs.map(function (rawTour) {
                            return rawTour.data();
                        });
                        setTours(openTours.concat(processedTours));
                        return [2 /*return*/];
                }
            });
        }); });
    }
    // Get and continuously update all tasks that belong to the current user
    function setupAllOwnersOpenTasks() {
        firebase
            .firestore()
            .collection("tasks")
            .where("task_owner", "==", firebase.auth().currentUser.uid)
            .where("task_status", "in", [
            "unassigned",
            "prepared",
            "assigned",
            "ontheway",
            "attached",
            "optimizing",
            "error",
            "pickedup",
        ])
            // .where("task_date", "<=", moment().endOf("day").toDate())
            // .orderBy("task_date", "desc")
            .onSnapshot(function (snap) {
            setAllOwnersOpenTasks(snap.docs.map(function (rawTask) { return rawTask.data(); }));
        });
    }
    //HINT -> Here could be some filters involved the same like in
    //TaskBar but serverside -> ease the client data base
    // Get and continuously update the tasks that are not completed for the current user
    function setupTaskListener() {
        firebase
            .firestore()
            .collection("tasks")
            .where("task_owner", "==", firebase.auth().currentUser.uid)
            .where("task_status", "in", [
            "unassigned",
            "prepared",
            "assigned",
            "ontheway",
            "attached",
            "optimizing",
            "error",
            "pickedup",
        ])
            .where("task_date", "<=", moment().endOf("day").toDate())
            .orderBy("task_date", "desc")
            .onSnapshot(function (snap) {
            setOpenTasks(snap.docs.map(function (rawTask) { return rawTask.data(); }));
        });
    }
    // Get and continuously update the tasks that were completed today for the current user
    function setupDoneTaskListener() {
        firebase
            .firestore()
            .collection("tasks")
            .where("task_owner", "==", firebase.auth().currentUser.uid)
            .where("task_status", "in", ["successfull", "successful", "declined"])
            .where("processed_date", ">=", moment().startOf("day").toDate())
            .where("processed_date", "<=", moment().endOf("day").toDate())
            .orderBy("processed_date", "desc")
            .onSnapshot(function (snap) {
            setDoneTasks(snap.docs.map(function (rawTask) { return rawTask.data(); }));
        });
    }
    useEffect(function () {
        setTasks(openTasks.concat(doneTasks));
    }, [doneTasks, openTasks]);
    useEffect(function () {
        setAllOwnersOpenTasks(allOwnersOpenTasks);
    }, [allOwnersOpenTasks]);
    return (_jsx(TaskContext.Provider, __assign({ value: {
            tasks: [areTasks, setTasks],
            tours: [areTours, setTours],
            headers: [areHeaders, setHeaders],
            ownersOpenTasks: [allOwnersOpenTasks, setAllOwnersOpenTasks],
        } }, { children: children })));
}
