var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useMemo } from "react";
import { Pane, Paragraph } from "evergreen-ui";
export default function TourProgressBar(_a) {
    var involvedTasks = _a.involvedTasks, status = _a.status;
    var doneTasks = useMemo(function () {
        return status !== "finished"
            ? involvedTasks.filter(function (task) {
                var _a, _b;
                return ((_a = task.data) === null || _a === void 0 ? void 0 : _a.task_status) === "successfull" ||
                    ((_b = task.data) === null || _b === void 0 ? void 0 : _b.task_status) === "declined";
            }).length
            : involvedTasks.length;
    }, [involvedTasks, status]);
    var progress = useMemo(function () { return (doneTasks / (involvedTasks.length / 100)).toFixed(0); }, [doneTasks, involvedTasks.length]);
    return (_jsx(Pane, __assign({ style: { display: "flex", flexDirection: "column" } }, { children: !isNaN(progress) && (_jsxs(_Fragment, { children: [_jsxs(Paragraph, __assign({ className: "bodytext", style: { fontSize: "12px", textAlign: "end" } }, { children: [progress, "%"] })), _jsx(Pane, __assign({ style: {
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        width: 50,
                        overflow: "hidden",
                        borderRadius: 2,
                        height: 4,
                        backgroundColor: "#8898AA",
                    } }, { children: _jsx(Pane, { style: {
                            height: 5,
                            width: "".concat(doneTasks / (involvedTasks.length / 100), "%"),
                            backgroundColor: "#49beef",
                        } }) }))] })) })));
}
