var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Pane, Paragraph, TextInput, toaster } from 'evergreen-ui';
import { useContext, useEffect, useState } from 'react';
import firebase from '../../config';
import { LocationContext } from '../../contexts/LocationContext';
import { UserContext } from '../../contexts/UserContext';
export default function Relay() {
    var _a, _b;
    var user = useContext(UserContext).user;
    var isUser = user[0];
    var availableLocations = useContext(LocationContext).availableLocations;
    var _c = useState(false), loading = _c[0], setLoading = _c[1];
    var _d = useState(false), checkLoading = _d[0], setCheckLoading = _d[1];
    var _e = useState(Object.entries((_b = (_a = isUser === null || isUser === void 0 ? void 0 : isUser.apomapRelayConfig) === null || _a === void 0 ? void 0 : _a.locationMap) !== null && _b !== void 0 ? _b : {}).map(function (_a) {
        var k = _a[0], v = _a[1];
        return ({
            id: k,
            newId: v.newId,
            interfaceToken: v.interfaceToken,
            interfaceId: v.interfaceId,
        });
    })), locationMapping = _e[0], setLocationMapping = _e[1];
    var submit = function () {
        setLoading(true);
        var locationMap = Object.fromEntries(locationMapping.map(function (l) { return [l.id, l]; }));
        firebase
            .firestore()
            .collection('users')
            .doc(firebase.auth().currentUser.uid)
            .update({
            apomapRelayConfig: {
                locationMap: locationMap,
            },
        })
            .then(function () { })
            .catch(function (err) {
            console.log(err);
        })
            .finally(function () { return setLoading(false); });
    };
    var check = function () {
        setCheckLoading(true);
        var checkRelaySettings = firebase
            .app()
            .functions('europe-west3')
            .httpsCallable('checkRelaySettings');
        checkRelaySettings()
            .then(function (result) {
            toaster.success('Die angegebenen Daten sind korrekt');
            setCheckLoading(false);
        })
            .catch(function (err) {
            toaster.warning('Die angegebenen Daten sind nicht korrekt');
            setCheckLoading(false);
        });
    };
    useEffect(function () {
        console.log(locationMapping);
    }, [locationMapping]);
    return (_jsxs(Pane, __assign({ display: "flex", flexDirection: "column" }, { children: [_jsx(Button, __assign({ intent: "info", onClick: function () {
                    return (window.location.href =
                        'https://apomap-v3.web.app/settings/interfaces');
                } }, { children: "Schnittstelle im neuen System erstellen" })), _jsx(Paragraph, __assign({ fontSize: "medium", fontWeight: "bold", marginTop: 8 }, { children: "Standort-Mapping" })), _jsx(Pane, { children: availableLocations.map(function (l) {
                    var _a, _b, _c;
                    return (_jsxs(Pane, __assign({ display: "flex", flexDirection: "column" }, { children: [_jsx(Paragraph, __assign({ fontWeight: "bold" }, { children: l.name })), _jsx(TextInput, { value: (_a = locationMapping.find(function (_a) {
                                    var id = _a.id;
                                    return id === l.id;
                                })) === null || _a === void 0 ? void 0 : _a.newId, onChange: function (e) {
                                    return setLocationMapping(function (s) {
                                        var empty = s.filter(function (e) { return e.id !== l.id; });
                                        var state = s.find(function (e) { return e.id === l.id; });
                                        empty.push({
                                            id: l.id,
                                            newId: e.target.value,
                                            interfaceToken: state === null || state === void 0 ? void 0 : state.interfaceToken,
                                            interfaceId: state === null || state === void 0 ? void 0 : state.interfaceId,
                                        });
                                        return empty;
                                    });
                                }, placeholder: "ID der Apotheke im neuen System", marginLeft: 10 }), _jsx(TextInput, { value: (_b = locationMapping.find(function (_a) {
                                    var id = _a.id;
                                    return id === l.id;
                                })) === null || _b === void 0 ? void 0 : _b.interfaceId, onChange: function (e) {
                                    return setLocationMapping(function (s) {
                                        var empty = s.filter(function (e) { return e.id !== l.id; });
                                        var state = s.find(function (e) { return e.id === l.id; });
                                        empty.push({
                                            id: l.id,
                                            newId: state === null || state === void 0 ? void 0 : state.newId,
                                            interfaceToken: state === null || state === void 0 ? void 0 : state.interfaceToken,
                                            interfaceId: e.target.value,
                                        });
                                        return empty;
                                    });
                                }, placeholder: "ID der Schnittstelle f\u00FCr diese Apotheke", marginLeft: 10 }), _jsx(TextInput, { type: "password", value: (_c = locationMapping.find(function (_a) {
                                    var id = _a.id;
                                    return id === l.id;
                                })) === null || _c === void 0 ? void 0 : _c.interfaceToken, onChange: function (e) {
                                    return setLocationMapping(function (s) {
                                        var empty = s.filter(function (e) { return e.id !== l.id; });
                                        var state = s.find(function (e) { return e.id === l.id; });
                                        empty.push({
                                            id: l.id,
                                            newId: state === null || state === void 0 ? void 0 : state.newId,
                                            interfaceToken: e.target.value,
                                            interfaceId: state === null || state === void 0 ? void 0 : state.interfaceId,
                                        });
                                        return empty;
                                    });
                                }, placeholder: "Geheimschl\u00FCssel der Schnittstelle", marginLeft: 10 })] })));
                }) }), _jsx(Pane, __assign({ className: "flex flex-row" }, { children: _jsx(Button, __assign({ marginLeft: 8, onClick: submit, marginTop: 8, isLoading: loading }, { children: "Sichern" })) }))] })));
}
