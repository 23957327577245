var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IbanElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Button, CrossIcon, Dialog, Heading, Pane, Paragraph, toaster, } from "evergreen-ui";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import { useContext, useState } from "react";
import firebase from "../config";
import { UserContext } from "../contexts/UserContext";
export default function PayWall(props) {
    var _a = useState(false), isLoading = _a[0], setLoading = _a[1];
    var _b = useContext(UserContext), user = _b.user, ready = _b.ready;
    var isUser = user[0];
    var stripe = useStripe();
    var elements = useElements();
    function upgradePlan() {
        return __awaiter(this, void 0, void 0, function () {
            var setupSepa;
            var _this = this;
            return __generator(this, function (_a) {
                console.log("Paywall");
                setupSepa = firebase
                    .app()
                    .functions("europe-west3")
                    .httpsCallable("enableUnlimitedDeliveries");
                setupSepa()
                    .then(function (resp) { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        setLoading(false);
                        props.setShown(false);
                        toaster.success("Sie haben nun unbegrenzt Lieferungen!");
                        return [2 /*return*/];
                    });
                }); })
                    .catch(function (err) {
                    setLoading(false);
                });
                return [2 /*return*/];
            });
        });
    }
    function setupSepaForCustomer() {
        return __awaiter(this, void 0, void 0, function () {
            var setupSepa;
            var _this = this;
            return __generator(this, function (_a) {
                setupSepa = firebase
                    .app()
                    .functions("europe-west3")
                    .httpsCallable("setupSepa");
                setupSepa()
                    .then(function (resp) { return __awaiter(_this, void 0, void 0, function () {
                    var iban, result, setDefaultSepa;
                    var _this = this;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                iban = elements.getElement(IbanElement);
                                return [4 /*yield*/, stripe.confirmSepaDebitSetup(resp.data, {
                                        payment_method: {
                                            sepa_debit: iban,
                                            billing_details: {
                                                name: isUser.firstName + " " + isUser.lastName,
                                                email: firebase.auth().currentUser.email,
                                            },
                                        },
                                    })];
                            case 1:
                                result = _a.sent();
                                if (resp.error) {
                                    // Show error to your customer.
                                    console.log(result.error.message);
                                    setLoading(false);
                                }
                                else {
                                    setDefaultSepa = firebase
                                        .app()
                                        .functions("europe-west3")
                                        .httpsCallable("setDefaultSepa");
                                    setDefaultSepa({ default_payment_method: resp.payment_method })
                                        .then(function (res) { return __awaiter(_this, void 0, void 0, function () {
                                        return __generator(this, function (_a) {
                                            upgradePlan();
                                            return [2 /*return*/];
                                        });
                                    }); })
                                        .catch(function (res) {
                                        console.log(res.error);
                                        setLoading(false);
                                    });
                                }
                                return [2 /*return*/];
                        }
                    });
                }); })
                    .catch(function (err) {
                    toaster.notify(err);
                });
                return [2 /*return*/];
            });
        });
    }
    return (_jsx(Dialog, __assign({ isShown: props.isShown, hasHeader: false, hasFooter: false, shouldCloseOnOverlayClick: false, shouldCloseOnEscapePress: false, width: "50vw", height: "90vh" }, { children: _jsxs(Pane, __assign({ style: {
                height: "70vh",
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between",
            } }, { children: [_jsxs(Pane, __assign({ style: {
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: 10,
                        width: "90%",
                    } }, { children: [_jsx(Heading, __assign({ className: "headline", style: { color: "#49beef" } }, { children: "Kostenlose Obergrenze erreicht" })), _jsx(CrossIcon, { onClick: function () { return props.setShown(false); } })] })), _jsxs(Pane, __assign({ style: {
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                        padding: 10,
                    } }, { children: [_jsx(Paragraph, __assign({ className: "bodytext" }, { children: "Sie haben ihr kostenloses Kontingent aufgebraucht und k\u00F6nnen nun mit dem Hinterlegen einer Zahlungsmethode weitermachen!" })), _jsx(IbanElement, { options: { supportedCountries: ["SEPA"], placeholderCountry: "DE" }, style: { width: "50%", height: 40 } })] })), _jsxs(Pane, __assign({ style: {
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: 20,
                        width: "90%",
                    } }, { children: [_jsx(Button, __assign({ isDisabled: isLoading, onClick: function () { return props.setShown(false); } }, { children: "Abbrechen" })), _jsx(Button, __assign({ isLoading: isLoading, onClick: function () { return setupSepaForCustomer(); } }, { children: "Hinterlegen" }))] }))] })) })));
}
