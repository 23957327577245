var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Paragraph } from "evergreen-ui";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { create } from "zustand";
import firebase from "../../../config";
import { usePlan } from "../../../hooks/usePlan";
var useAddonsStore = create(function (set) { return ({
    isBooked: false,
    shouldManage: false,
    shownModal: null,
    selectedAddon: null,
    setManaged: function (state) { return set(function () { return ({ shouldManage: state }); }); },
    setBooked: function (state) { return set(function () { return ({ isBooked: state }); }); },
    setAddon: function (selectedAddon) { return set(function () { return ({ selectedAddon: selectedAddon }); }); },
    setShownModal: function (shownModal) { return set(function () { return ({ shownModal: shownModal }); }); },
}); });
export var useAddons = function () {
    var _a = useAddonsStore(), shownModal = _a.shownModal, setShownModal = _a.setShownModal, selectedAddon = _a.selectedAddon, setAddon = _a.setAddon, isBooked = _a.isBooked, setBooked = _a.setBooked, shouldManage = _a.shouldManage, setManaged = _a.setManaged;
    var plan = usePlan();
    var _b = useState(false), loading = _b[0], setLoading = _b[1];
    var _c = useState(false), isInitializing = _c[0], setIsInitializing = _c[1];
    var _d = useState([]), availableAddons = _d[0], setAvailableAddons = _d[1];
    var _e = useState(false), updateAmountLoading = _e[0], setUpdateAmountLoading = _e[1];
    var checkPriceIdUsability = function (priceId) {
        return (plan === null || plan === void 0 ? void 0 : plan.addons)
            ? (plan === null || plan === void 0 ? void 0 : plan.addons[priceId])
                ? (plan === null || plan === void 0 ? void 0 : plan.addons[priceId].cancelledAtPeriodEnd)
                    ? moment().isBefore(moment((plan === null || plan === void 0 ? void 0 : plan.addons[priceId].currentPeriodEnd) * 1000))
                        ? true
                        : false
                    : true
                : false
            : false;
    };
    var checkPriceIdBookability = function (priceId) {
        return (plan === null || plan === void 0 ? void 0 : plan.addons)
            ? (plan === null || plan === void 0 ? void 0 : plan.addons[priceId])
                ? (plan === null || plan === void 0 ? void 0 : plan.addons[priceId].cancelledAtPeriodEnd)
                    ? moment().isAfter(moment((plan === null || plan === void 0 ? void 0 : plan.addons[priceId].currentPeriodEnd) * 1000))
                        ? true
                        : false
                    : false
                : true
            : true;
    };
    var subscribe = function (_a) {
        var quantity = _a.quantity, priceId = _a.priceId, promotionCode = _a.promotionCode, contactInfo = _a.contactInfo;
        return new Promise(function (res, rej) {
            setLoading(true);
            var subscribeToProduct = firebase
                .app()
                .functions("europe-west3")
                .httpsCallable("subscribeToProduct");
            subscribeToProduct({ priceId: priceId, quantity: quantity, promotionCode: promotionCode, contactInfo: contactInfo })
                .then(function (result) {
                console.log(result);
                setLoading(false);
                res("");
            })
                .catch(function (err) {
                console.log("err");
                console.log(err);
                setLoading(false);
                rej();
            })
                .finally(function () { return window.location.reload(); });
        });
    };
    var cancel = function (_a) {
        var subscriptionId = _a.subscriptionId;
        return new Promise(function (res, rej) {
            setLoading(true);
            var cancelAddon = firebase
                .app()
                .functions("europe-west3")
                .httpsCallable("cancelAddon");
            cancelAddon({ subscriptionId: subscriptionId })
                .then(function (result) {
                console.log(result);
                setLoading(false);
                res("");
            })
                .catch(function (err) {
                console.log("err");
                console.log(err);
                setLoading(false);
                rej();
            })
                .finally(function () { return window.location.reload(); });
        });
    };
    var getAvailableAddOns = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var getAvailableAddons;
        return __generator(this, function (_a) {
            getAvailableAddons = firebase
                .app()
                .functions("europe-west3")
                .httpsCallable("getAvailableAddons");
            getAvailableAddons()
                .then(function (result) {
                setLoading(false);
                var available = result.data;
                setAvailableAddons(available);
            })
                .catch(function (err) {
                console.log("err");
                console.log(err);
                setLoading(false);
            })
                .finally(function () { return setIsInitializing(false); });
            return [2 /*return*/];
        });
    }); }, []);
    var updateAddonAmount = function (_a) {
        var price_id = _a.price_id, amount = _a.amount;
        return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_b) {
                return [2 /*return*/, new Promise(function (res, rej) {
                        setUpdateAmountLoading(true);
                        var updateAddonAmount = firebase
                            .app()
                            .functions("europe-west3")
                            .httpsCallable("updateAddonAmount");
                        updateAddonAmount({ price_id: price_id, amount: amount })
                            .then(function () {
                            res("Done");
                        })
                            .catch(function (err) {
                            console.log("err");
                            console.log(err);
                            rej("Failed");
                        })
                            .finally(function () { return setUpdateAmountLoading(false); });
                    })];
            });
        });
    };
    var getDetailedDescription = function (priceId) {
        switch (priceId) {
            case "price_1Nrb89GjTI7kgs1jReGKe5Qn":
                return (_jsx(_Fragment, { children: _jsxs(Paragraph, { children: ["Das Messen der Temperatur ist auch bei der Lieferung von hoher Bedeutung. Daher haben wir mit unserem Partner Tec4med einen Tracker eingebunden, welcher permanent die Temperatur messen kann. Dieser ist direkt \u00FCber die apomap Fahrer-App angebunden und ist direkt nutzbar.", _jsx("br", {}), " - Temperatur Kontrolle direkt \u00FCber apomap ", _jsx("br", {}), " - direkte Anbindung zum apomap Account ", _jsx("br", {}), " - Einfaches scannen mit der apomap Driver App ", _jsx("br", {}), " - Daten werden direkt \u00FCber Cloud gespeichert ", _jsx("br", {}), " - ohne l\u00E4stiges auslesen ", _jsx("br", {}), " - Sekundenschnelle \u00DCbertragung zu apomap"] }) }));
            default:
                return "Keine Beschreibung";
        }
    };
    useEffect(function () {
        setIsInitializing(true);
        getAvailableAddOns();
    }, [getAvailableAddOns]);
    return {
        shownModal: shownModal,
        setShownModal: setShownModal,
        subscribe: subscribe,
        loading: loading,
        availableAddons: availableAddons,
        selectedAddon: selectedAddon,
        setAddon: setAddon,
        cancel: cancel,
        isBooked: isBooked,
        setBooked: setBooked,
        isInitializing: isInitializing,
        checkPriceIdUsability: checkPriceIdUsability,
        checkPriceIdBookability: checkPriceIdBookability,
        getDetailedDescription: getDetailedDescription,
        shouldManage: shouldManage,
        setManaged: setManaged,
        updateAddonAmount: updateAddonAmount,
        updateAmountLoading: updateAmountLoading,
    };
};
