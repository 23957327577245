var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
//Cloudfunctions for this feature are getHealviConfig, sendHealvi, sendTaskToElastic, updateTask
import { Badge, Button, Pane, Paragraph, SelectMenu, Switch, Tab, Tablist, TextInput, toaster, } from "evergreen-ui";
import { useCallback, useContext, useEffect, useMemo, useRef, useState, } from "react";
import firebase from "../../../config";
import { UserContext } from "../../../contexts/UserContext";
import { useLocation } from "../../../hooks/useLocations";
import HealviLocationConfig from "./HealviLocationConfig";
export var HealviConfig = function () {
    var _a, _b, _c, _d, _e, _f;
    // @ts-ignore
    var user = useContext(UserContext).user;
    var isUser = user[0];
    var _g = useState(false), submitting = _g[0], setSubmitting = _g[1];
    var locations = useLocation();
    var availableLocations = useMemo(function () { return locations.filter(function (l) { return !l.archived; }); }, [locations]);
    var locationsMap = useMemo(function () { return Object.fromEntries(availableLocations.map(function (l) { return [l.id, l]; })); }, [availableLocations]);
    var _h = useState(false), loading = _h[0], setLoading = _h[1];
    var _j = useState((_b = (_a = isUser.healviConfig) === null || _a === void 0 ? void 0 : _a.active) !== null && _b !== void 0 ? _b : false), active = _j[0], setActive = _j[1];
    var _k = useState((_d = (_c = isUser.healviConfig) === null || _c === void 0 ? void 0 : _c.apiKey) !== null && _d !== void 0 ? _d : undefined), apiToken = _k[0], setApiToken = _k[1];
    // Location-Configuration-Array
    var _l = useState((_f = (_e = isUser.healviConfig) === null || _e === void 0 ? void 0 : _e.config) !== null && _f !== void 0 ? _f : []), config = _l[0], setConfig = _l[1];
    // Indicates which locationTab is selected
    var _m = useState(0), selectedIndex = _m[0], setSelectedIndex = _m[1];
    // Templates-Configuration from Healvi API
    var _o = useState([]), templates = _o[0], setTemplates = _o[1];
    var templatesMap = useMemo(function () { return Object.fromEntries(templates.map(function (t) { return [t.template_id, t]; })); }, [templates]);
    var configElementRefs = useRef([]);
    useEffect(function () {
        configElementRefs.current = configElementRefs.current.slice(0, config.length);
    }, [config]);
    var toggleActivity = function () {
        var _a, _b;
        firebase
            .firestore()
            .collection("users")
            .doc((_a = firebase.auth().currentUser) === null || _a === void 0 ? void 0 : _a.uid)
            .update({
            healviConfig: {
                apiKey: apiToken,
                active: !((_b = isUser === null || isUser === void 0 ? void 0 : isUser.healviConfig) === null || _b === void 0 ? void 0 : _b.active),
                config: isUser === null || isUser === void 0 ? void 0 : isUser.healviConfig.config,
            },
        })
            .then(function () { return setActive(function (s) { return !s; }); })
            .catch(function () { return toaster.warning("Es ist etwas schief gelaufen"); });
    };
    var submit = function () {
        var _a;
        setSubmitting(true);
        var config = configElementRefs.current.map(function (ref) {
            return ref.getConfiguration();
        });
        firebase
            .firestore()
            .collection("users")
            .doc((_a = firebase.auth().currentUser) === null || _a === void 0 ? void 0 : _a.uid)
            .update({
            healviConfig: {
                apiKey: apiToken,
                active: true,
                config: config,
            },
        })
            .then(function (doc) { return toaster.success("Healvi erfolgreich aktiviert"); })
            .catch(function (err) { return toaster.warning("Ein Fehler ist unterlaufen"); })
            .finally(function () { return setSubmitting(false); });
    };
    var getOptions = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            try {
                setLoading(true);
                firebase
                    .app()
                    .functions("europe-west3")
                    .httpsCallable("getHealviConfig")({ apiToken: apiToken })
                    .then(function (result) {
                    var data = result.data;
                    setTemplates(data);
                })
                    .catch(function (err) {
                    console.log(err);
                })
                    .finally(function () { return setLoading(false); });
            }
            catch (error) {
                console.log(error);
            }
            return [2 /*return*/];
        });
    }); }, [apiToken]);
    useEffect(function () {
        if (apiToken)
            getOptions();
    }, [apiToken, getOptions]);
    return (_jsxs(Pane, { children: [_jsxs(Pane, __assign({ marginY: 12, display: "flex", flexDirection: "row", alignItems: "center" }, { children: [active ? (_jsx(Badge, __assign({ color: "green" }, { children: "Aktiv" }))) : (_jsx(Badge, __assign({ color: "neutral" }, { children: "Inaktiv" }))), _jsx(Switch, { disabled: !apiToken, checked: active !== null && active !== void 0 ? active : false, onChange: toggleActivity, marginLeft: 8 })] })), _jsxs(Pane, __assign({ marginTop: 4, display: "flex", flexDirection: "row", alignItems: "center" }, { children: [_jsx(TextInput, { type: "password", placeholder: "Healvi-Api Key", onChange: function (e) { return setApiToken(e.target.value); }, value: apiToken }), _jsx(Button, __assign({ isLoading: loading, onClick: getOptions }, { children: "Pr\u00FCfen" }))] })), templates.length ? (_jsxs(_Fragment, { children: [_jsx(Paragraph, __assign({ fontWeight: 600, marginTop: 4 }, { children: "2. W\u00E4hle einen oder mehrere Standorte aus" })), _jsx(SelectMenu, __assign({ title: "Verf\u00FCgbare Standorte", options: availableLocations.map(function (location) { return ({
                            label: location.name,
                            value: location.id,
                        }); }), selected: [], onSelect: function (item) {
                            return setConfig(function (s) { return __spreadArray(__spreadArray([], s, true), [{ locationId: item.value.toString() }], false); });
                        } }, { children: _jsx(Button, __assign({ marginTop: 4 }, { children: "Standort w\u00E4hlen" })) }))] })) : null, config.length ? (_jsx(Paragraph, __assign({ fontWeight: 600, marginTop: 12 }, { children: "3. Konfiguriere deine Einstellungen pro Standort" }))) : null, _jsx(Tablist, __assign({ marginTop: 12 }, { children: config.map(function (tab, index) {
                    var _a, _b;
                    return (_jsx(Tab, __assign({ "aria-controls": "healvi-".concat(tab), isSelected: index === selectedIndex, onSelect: function () { return setSelectedIndex(index); } }, { children: _jsx(Pane, { children: _jsx(Paragraph, __assign({ fontWeight: 500 }, { children: (_a = tab.name) !== null && _a !== void 0 ? _a : (_b = locationsMap[tab.locationId]) === null || _b === void 0 ? void 0 : _b.name })) }) }), tab.locationId));
                }) })), _jsxs(Pane, { children: [config.map(function (tab, index) { return (_jsx(Pane, __assign({ "aria-labelledby": tab.locationId, "aria-hidden": index !== selectedIndex, display: index === selectedIndex ? "block" : "none", role: "tabpanel" }, { children: _jsx(HealviLocationConfig, { ref: function (el) { return el && (configElementRefs.current[index] = el); }, locationId: tab.locationId, locationsMap: locationsMap, name: tab.name, status: tab.status, template: tab.templateId, templates: templates, templatesMap: templatesMap, setConfig: setConfig }) }), tab.locationId)); }), _jsx(Button, __assign({ marginTop: 12, isLoading: submitting, onClick: submit }, { children: "Speichern & Aktivieren" }))] })] }));
};
