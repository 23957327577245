var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Dialog, Heading, Pane, Paragraph, toaster, } from "evergreen-ui";
import "firebase/auth";
import "firebase/firestore";
import { useCallback, useContext, useState } from "react";
import BlueBar from "../components/BlueBar";
import firebase from "../config";
import { TaskContext } from "../contexts/TaskContext";
export default function TaskDeleteDialog(props) {
    var _this = this;
    var _a = useState(false), isLoading = _a[0], setLoading = _a[1];
    var tours = useContext(TaskContext).tours;
    var areTours = tours[0], _ = tours[1];
    var removeFromTour = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var relevantTour;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    relevantTour = areTours.find(function (tour) { return tour.id === props.task.task_tour; });
                    if (!relevantTour)
                        return [2 /*return*/];
                    setLoading(true);
                    return [4 /*yield*/, firebase
                            .firestore()
                            .collection("tours")
                            .doc(relevantTour.id)
                            .update({
                            tasksToRemove: firebase.firestore.FieldValue.arrayUnion(props.task.task_id),
                        })];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, firebase
                            .firestore()
                            .collection("tasks")
                            .doc(props.task.task_id)
                            .update({
                            task_status: "attached",
                            task_tour: relevantTour.id,
                        })];
                case 2:
                    _a.sent();
                    props.setShown(false);
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); }, [areTours, props]);
    function deleteTask() {
        return __awaiter(this, void 0, void 0, function () {
            var groupedItems, updatePromises, parentTaskRef, deletePromise, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setLoading(true);
                        if (!(props.task.collectiveTask && props.task.task_status === "unassigned")) return [3 /*break*/, 5];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        groupedItems = props.task.taskIds;
                        updatePromises = groupedItems.map(function (itemId) {
                            var taskRef = firebase.firestore().collection("tasks").doc(itemId);
                            return taskRef.update({ parent_task: null, isMerged: false });
                        });
                        parentTaskRef = firebase
                            .firestore()
                            .collection("tasks")
                            .doc(props.task.task_id);
                        deletePromise = parentTaskRef.delete();
                        // Wait for all update and delete operations to complete
                        return [4 /*yield*/, Promise.all(__spreadArray(__spreadArray([], updatePromises, true), [deletePromise], false))];
                    case 2:
                        // Wait for all update and delete operations to complete
                        _a.sent();
                        setLoading(false);
                        props.setShown(false);
                        toaster.success("Auftrag erfolgreich gelöscht");
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        toaster.danger("Beim Löschen des Auftrags ist ein Fehler aufgetreten");
                        return [3 /*break*/, 4];
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        firebase
                            .firestore()
                            .collection("tasks")
                            .doc(props.task.task_id)
                            .delete()
                            .then(function () {
                            setLoading(false);
                            props.setShown(false);
                            toaster.success("Der Auftrag wurde erfolgreich gel\u00F6scht.");
                        })
                            .catch(function (err) {
                            setLoading(false);
                            console.log(err);
                            toaster.danger("Beim L\u00F6schen des Auftrage ".concat(props.task.task_id, " ist ein unterlaufen."));
                        });
                        _a.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        });
    }
    if (!props.task)
        return _jsx("span", {});
    return (_jsx(Dialog, __assign({ isShown: props.isShown, hasHeader: false, hasFooter: false, shouldCloseOnOverlayClick: false, shouldCloseOnEscapePress: false, height: "50vh" }, { children: _jsxs(Pane, __assign({ style: {
                height: "50vh",
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between",
            } }, { children: [_jsx(BlueBar, { color: "#F12121" }), _jsxs(Pane, __assign({ style: { width: "100%" } }, { children: [_jsx(Heading, __assign({ className: "headline", style: { color: "#F12121", textAlign: "center" } }, { children: props.isDeleteActiveAction
                                ? "AUFTRAG LÖSCHEN"
                                : "AUFTRAG SEINER TOUR ENTZIEHEN" })), props.isDeleteActiveAction ? (_jsxs(Paragraph, __assign({ className: "bodytext", style: { textAlign: "center", marginTop: 20 } }, { children: ["M\u00F6chtest du ", props.task.customer_name, " wirklich l\u00F6schen?"] }))) : (_jsxs(Paragraph, __assign({ className: "bodytext", style: { textAlign: "center", marginTop: 20 } }, { children: ["M\u00F6chtest du ", props.task.customer_name, " aus der Tour entfernen?"] })))] })), _jsxs(Pane, __assign({ style: {
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-around",
                        marginBottom: 20,
                    } }, { children: [_jsx(Button, __assign({ isDisable: isLoading, onClick: function () { return props.setShown(false); } }, { children: "Abbrechen" })), _jsx(Button, __assign({ isLoading: isLoading, onClick: function () {
                                return props.isDeleteActiveAction ? deleteTask() : removeFromTour();
                            } }, { children: "Ja, m\u00F6chte ich!" }))] }))] })) })));
}
