var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Pane, ThemeProvider, toaster } from "evergreen-ui";
import { useContext, useEffect, useState } from "react";
import AddTaskToTourDialog from "../components/AddTaskToTourDialog";
import BarcodeScannerInput from "../components/BarcodeScannerInput";
import Video from "../components/DeleteLater/Video";
import AssignmentPanel, { getTourByAttachedTaskId, } from "../components/Packing/AssignmentPanel";
import BarcodeHint from "../components/Packing/BarcodeHint";
import DataCardEntry from "../components/Packing/DataCardEntry";
import PackingPanel from "../components/Packing/PackingPanel";
import PriorityIndicator from "../components/Packing/PriorityIndicator";
import firebase from "../config";
import NavigatorBar from "../container/NavigatorBar";
import { TaskDialogsContext } from "../contexts/TaskDialogsContext";
import withPaywall from "../hocs/withPaywall";
import theme from "../theme";
import formatMoney from "../utility/formatMoney";
import { removeTaskFromTour } from "../utility/removeTaskFromTour";
import BarcodeScannerIcon from "./../assets/icons/barcodescanner.svg";
import PaywallInfo from "./PaywallInfo";
var sectionHeaderStyle = {
    fontSize: 13,
    color: "#A1A1AB",
    letterSpacing: 0.6,
    margin: "14px 0px",
    padding: 0,
};
export var getTaskById = function (taskId) { return __awaiter(void 0, void 0, Promise, function () {
    var currentUser, document, task;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                currentUser = firebase.auth().currentUser;
                if (!currentUser) {
                    toaster.danger("Sie sind nicht angemeldet.");
                    throw new Error("Unauthenticated Firebase access attempted");
                }
                return [4 /*yield*/, firebase
                        .firestore()
                        .collection("tasks")
                        .where("task_owner", "==", currentUser.uid)
                        .where("task_id", "==", taskId)
                        .get()];
            case 1:
                document = _a.sent();
                if (document.docs.length === 0)
                    return [2 /*return*/, null];
                task = document.docs[0].data();
                return [2 /*return*/, task];
        }
    });
}); };
var getTaskByJobNo = function (jobNo) { return __awaiter(void 0, void 0, Promise, function () {
    var currentUser, document, task;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                currentUser = firebase.auth().currentUser;
                if (!currentUser) {
                    toaster.danger("Sie sind nicht angemeldet.");
                    throw new Error("Unauthenticated Firebase access attempted");
                }
                return [4 /*yield*/, firebase
                        .firestore()
                        .collection("tasks")
                        .where("task_owner", "==", currentUser.uid)
                        .where("task_job_no", "==", jobNo)
                        .get()];
            case 1:
                document = _a.sent();
                if (document.docs.length === 0)
                    return [2 /*return*/, null];
                task = document.docs[0].data();
                return [2 /*return*/, task];
        }
    });
}); };
var getTourById = function (tourId) { return __awaiter(void 0, void 0, Promise, function () {
    var currentUser, document, tour;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                currentUser = firebase.auth().currentUser;
                if (!currentUser) {
                    toaster.danger("Sie sind nicht angemeldet.");
                    throw new Error("Unauthenticated Firebase access attempted");
                }
                return [4 /*yield*/, firebase
                        .firestore()
                        .collection("tours")
                        .where("owner", "==", currentUser.uid)
                        .where("id", "==", tourId)
                        .get()];
            case 1:
                document = _a.sent();
                if (document.docs.length === 0)
                    return [2 /*return*/, null];
                tour = document.docs[0].data();
                return [2 /*return*/, tour];
        }
    });
}); };
var getDriverById = function (driverId) { return __awaiter(void 0, void 0, Promise, function () {
    var currentUser, document, driver;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                currentUser = firebase.auth().currentUser;
                if (!currentUser) {
                    toaster.danger("Sie sind nicht angemeldet.");
                    throw new Error("Unauthenticated Firebase access attempted");
                }
                return [4 /*yield*/, firebase
                        .firestore()
                        .collection("drivers")
                        .where("employer", "==", currentUser.uid)
                        .where("id", "==", driverId)
                        .get()];
            case 1:
                document = _a.sent();
                if (document.docs.length === 0)
                    return [2 /*return*/, null];
                driver = document.docs[0].data();
                return [2 /*return*/, driver];
        }
    });
}); };
// ToDo: This is copied from AddTaskToTourDialog. It is very ugly and needs redoing; specifically validation
var addTaskToTour = function (taskId, tourId) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: 
            // Add the task to the tour
            return [4 /*yield*/, firebase
                    .firestore()
                    .collection("tours")
                    .doc(tourId)
                    .update({
                    tasksToAdd: firebase.firestore.FieldValue.arrayUnion(taskId),
                })];
            case 1:
                // Add the task to the tour
                _a.sent();
                // Reflect the attached status in the task
                return [4 /*yield*/, firebase.firestore().collection("tasks").doc(taskId).update({
                        task_status: "attached",
                    })];
            case 2:
                // Reflect the attached status in the task
                _a.sent();
                return [2 /*return*/];
        }
    });
}); };
export var getLocationById = function (locationId) { return __awaiter(void 0, void 0, Promise, function () {
    var currentUser, document, location;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                currentUser = firebase.auth().currentUser;
                if (!currentUser) {
                    toaster.danger("Sie sind nicht angemeldet.");
                    throw new Error("Unauthenticated Firebase access attempted");
                }
                return [4 /*yield*/, firebase
                        .firestore()
                        .collection("locations")
                        .where("owner", "==", currentUser.uid)
                        .where("id", "==", locationId)
                        .get()];
            case 1:
                document = _a.sent();
                if (document.docs.length === 0)
                    return [2 /*return*/, null];
                location = document.docs[0].data();
                return [2 /*return*/, location.name];
        }
    });
}); };
var Packing = function () {
    var _a = useState(true), scanEnabled = _a[0], setScanEnabled = _a[1];
    var _b = useState(undefined), currentTask = _b[0], _setCurrentTask = _b[1];
    var _c = useState(undefined), currentLocationName = _c[0], setCurrentLocationName = _c[1];
    var _d = useState(undefined), currentTour = _d[0], setCurrentTour = _d[1]; // null when loading, undefined when none avail
    var dialogSettings = useContext(TaskDialogsContext).dialogSettings;
    var shownDialog = dialogSettings[0], setShownDialog = dialogSettings[1];
    var getHelperData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var taskDoc, task, tourDoc, tour, driverDoc, driver;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, firebase
                        .firestore()
                        .collection("tasks")
                        .doc("d23ea4e3-90fb-a6f9-088a-045608390c56")
                        .get()];
                case 1:
                    taskDoc = _a.sent();
                    task = taskDoc.data();
                    if (!(task === null || task === void 0 ? void 0 : task.task_tour)) return [3 /*break*/, 4];
                    return [4 /*yield*/, firebase
                            .firestore()
                            .collection("tours")
                            .doc(task === null || task === void 0 ? void 0 : task.task_tour)
                            .get()];
                case 2:
                    tourDoc = _a.sent();
                    tour = tourDoc.data();
                    if (!tour) return [3 /*break*/, 4];
                    return [4 /*yield*/, firebase
                            .firestore()
                            .collection("drivers")
                            .doc(tour === null || tour === void 0 ? void 0 : tour.driver)
                            .get()];
                case 3:
                    driverDoc = _a.sent();
                    driver = driverDoc.data();
                    setCurrentTour(__assign(__assign({}, tour), { driverInfo: driver }));
                    _a.label = 4;
                case 4:
                    _setCurrentTask(task);
                    return [2 /*return*/];
            }
        });
    }); };
    //Helper
    useEffect(function () {
        getHelperData();
    }, []);
    var setCurrentTask = function (data) {
        if (data === undefined) {
            _setCurrentTask(undefined);
            setCurrentLocationName(undefined);
            setCurrentTour(undefined);
            return;
        }
        _setCurrentTask(data);
        setCurrentTour(null);
        getLocationById(data.task_origin)
            .then(function (n) {
            setCurrentLocationName(n !== null && n !== void 0 ? n : "Unbekannt");
        })
            .catch(function () {
            setCurrentLocationName("Unbekannt");
        });
        if (!data.task_tour) {
            setCurrentTour(undefined);
        }
        else {
            getTourById(data.task_tour).then(function (tour) { return __awaiter(void 0, void 0, void 0, function () {
                var driver;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!tour) {
                                // If no tour was found, there was an error we cannot recover
                                setCurrentTour(undefined);
                                return [2 /*return*/];
                            }
                            return [4 /*yield*/, getDriverById(tour.driver)];
                        case 1:
                            driver = _a.sent();
                            if (!driver) {
                                // If no driver was found, the tour is broken.
                                setCurrentTour(undefined);
                                return [2 /*return*/];
                            }
                            setCurrentTour(tour
                                ? __assign(__assign({}, tour), { driverInfo: driver }) : undefined);
                            return [2 /*return*/];
                    }
                });
            }); });
        }
    };
    var refreshTaskInfo = function () { return __awaiter(void 0, void 0, void 0, function () {
        var taskUpdate;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!currentTask) {
                        console.log("No curr task");
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, getTaskById(currentTask.task_id)];
                case 1:
                    taskUpdate = _a.sent();
                    if (!taskUpdate) {
                        console.log("No task with", currentTask.task_id);
                        return [2 /*return*/];
                    }
                    setCurrentTask(taskUpdate);
                    return [2 /*return*/];
            }
        });
    }); };
    var showAddTaskToTourPanel = function () {
        setShownDialog({
            type: "addTaskToTourDialog",
            task: currentTask,
            onTaskUpdate: function () {
                refreshTaskInfo();
            },
        });
    };
    var handleSubmission = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var tourId, tour, oldTour, taskId, task_1, task;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!data.startsWith("apomap-Tour__")) return [3 /*break*/, 9];
                    if (currentTask === undefined) {
                        toaster.warning("Tour-Codes können hier nicht verwendet werden. Bitte scanne zuerst einen Auftragscode.");
                        return [2 /*return*/];
                    }
                    if (!["unassigned", "prepared", "attached"].includes(currentTask.task_status)) {
                        toaster.warning("Tour-Codes können hier nicht verwendet werden.");
                        return [2 /*return*/];
                    }
                    tourId = data.substring("apomap-Tour__".length);
                    return [4 /*yield*/, getTourById(tourId)];
                case 1:
                    tour = _a.sent();
                    if (!(tour !== null)) return [3 /*break*/, 7];
                    if (!["empty", "prepared"].includes(tour.status)) return [3 /*break*/, 5];
                    if (!(currentTask.task_status === "attached")) return [3 /*break*/, 3];
                    return [4 /*yield*/, getTourByAttachedTaskId(currentTask.task_id)];
                case 2:
                    oldTour = _a.sent();
                    if (!oldTour) {
                        toaster.warning("Der Auftrag konnte nicht in eine andere Tour verschoben werden. Bitte versuchen Sie den Auftrag neu einzuscannen. Sollte dies das Problem nicht lösen, kontaktieren Sie bitte den Support.");
                        return [2 /*return*/];
                    }
                    removeTaskFromTour(oldTour, currentTask.task_id);
                    _a.label = 3;
                case 3: 
                // Add the task to the tour
                return [4 /*yield*/, addTaskToTour(currentTask.task_id, tourId)];
                case 4:
                    // Add the task to the tour
                    _a.sent();
                    // inform the user
                    toaster.success("Der Auftrag wurde der Tour hinzugefügt");
                    // ToDo: Maybe use central optimistic DB UI Update here?
                    // optimistically update the ui
                    setCurrentTask(__assign(__assign({}, currentTask), { task_status: "attached", task_tour: undefined }));
                    return [3 /*break*/, 6];
                case 5:
                    toaster.warning("Der Auftrag kann der gescannten Tour nicht hinzugefügt werden");
                    _a.label = 6;
                case 6: return [3 /*break*/, 8];
                case 7:
                    toaster.warning("Die gescannte Tour konnte nicht gefunden werden.");
                    _a.label = 8;
                case 8: return [2 /*return*/];
                case 9:
                    if (!data.startsWith("apomap-Task__")) return [3 /*break*/, 11];
                    taskId = data.substring("apomap-Task__".length);
                    return [4 /*yield*/, getTaskById(taskId)];
                case 10:
                    task_1 = _a.sent();
                    if (task_1 !== null) {
                        setCurrentTask(task_1);
                    }
                    else {
                        toaster.warning("Die gescannte Aufgabe konnte nicht gefunden werden.");
                    }
                    return [2 /*return*/];
                case 11:
                    if (data.length < 4) {
                        // This input is likely a mistake, so just ignore it silently
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, getTaskByJobNo(data)];
                case 12:
                    task = _a.sent();
                    if (task !== null) {
                        setCurrentTask(task);
                        return [2 /*return*/];
                    }
                    // If that attempt also failed, let the user know
                    toaster.warning("Der Scan konnte nicht verarbeitet werden. Bitte versuch' es erneut");
                    return [2 /*return*/];
            }
        });
    }); };
    if (currentTask === undefined) {
        return (_jsxs(ThemeProvider
        /* @ts-ignore */
        , __assign({ 
            /* @ts-ignore */
            value: theme }, { children: [_jsx(BarcodeScannerInput, { active: scanEnabled, onSubmission: handleSubmission }), _jsxs(Pane, __assign({ style: { display: "flex", flexDirection: "row", flex: 1 } }, { children: [_jsx(NavigatorBar, {}), _jsxs("div", __assign({ style: {
                                flex: 1,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                            } }, { children: [_jsx("img", { src: BarcodeScannerIcon, style: { width: 64, height: 64 } }), _jsxs("h1", __assign({ style: {
                                        textAlign: "center",
                                        color: "#3F3F46",
                                    } }, { children: ["Scanne einen Barcode,", _jsx("br", {}), "um zu starten"] }))] }))] }))] })));
    }
    return (_jsxs(ThemeProvider
    /* @ts-ignore */
    , __assign({ 
        /* @ts-ignore */
        value: theme }, { children: [_jsx(BarcodeScannerInput, { active: scanEnabled, onSubmission: handleSubmission }), _jsx(AddTaskToTourDialog, { task: shownDialog.task, isShown: shownDialog.type === "addTaskToTourDialog", setShown: function () { return setShownDialog({ type: "", task: {} }); }, onChoice: refreshTaskInfo }), _jsxs(Pane, __assign({ style: { display: "flex", flexDirection: "row", flex: 1 } }, { children: [_jsx(NavigatorBar, {}), _jsxs("div", __assign({ style: {
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                        } }, { children: [_jsxs("div", __assign({ style: {
                                    flex: 1,
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: 20,
                                    paddingLeft: 30,
                                    paddingRight: 30,
                                    alignItems: "center",
                                } }, { children: [_jsxs(PackingPanel, __assign({ style: { maxWidth: 700 } }, { children: [_jsxs("div", __assign({ style: {
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    marginBottom: 18,
                                                } }, { children: [_jsxs("div", __assign({ style: {
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            gap: 20,
                                                            alignItems: "center",
                                                        } }, { children: [_jsx("h2", __assign({ style: { margin: 0, padding: 0 } }, { children: currentTask.customer_name })), _jsx(PriorityIndicator, { priority: currentTask.task_priority ? "severe" : "none" })] })), _jsxs("p", __assign({ style: {
                                                            margin: 0,
                                                            padding: 0,
                                                            fontSize: 14,
                                                            color: "#52525C",
                                                        } }, { children: ["Jobnr. ", currentTask.task_job_no] }))] })), _jsxs("div", __assign({ style: { display: "flex", flexDirection: "row", gap: 50 } }, { children: [_jsxs("div", __assign({ style: { flex: 1 } }, { children: [_jsx("h3", __assign({ style: __assign({ textTransform: "uppercase" }, sectionHeaderStyle) }, { children: "Kundendaten" })), _jsx(DataCardEntry, { title: "Adresse", content: currentTask.task_address.replace(new RegExp(", ", "g"), "\n") }), _jsx(DataCardEntry, { title: "E-Mail", content: currentTask.customer_mail }), _jsx(DataCardEntry, { title: "Telefon", content: currentTask.customer_phone }), _jsx(DataCardEntry, { title: "Handy", content: currentTask.customer_mobile })] })), _jsxs("div", __assign({ style: { flex: 1 } }, { children: [_jsx("h3", __assign({ style: __assign({ textTransform: "uppercase" }, sectionHeaderStyle) }, { children: "Lieferungsinformationen" })), _jsx(DataCardEntry, { title: "Apotheke", content: currentLocationName !== null && currentLocationName !== void 0 ? currentLocationName : "-" }), _jsx(DataCardEntry, { title: "Zahlbetrag", content: formatMoney(currentTask.task_open_amount, " €") }), _jsx(DataCardEntry, { title: "Hinweis", content: currentTask.task_note })] }))] }))] })), _jsx(AssignmentPanel, { status: currentTask.task_status, currentTask: currentTask, currentTour: currentTour, addTaskToTour: showAddTaskToTourPanel, onUncontrolledUpdate: function () {
                                            console.log("UNCONTROLLED UPDATE!!!");
                                            refreshTaskInfo();
                                        }, onTaskUpdate: function (key, value) {
                                            var _a;
                                            try {
                                                var newTask = __assign({}, currentTask);
                                                firebase
                                                    .firestore()
                                                    .collection("tasks")
                                                    .doc(currentTask.task_id)
                                                    .update((_a = {},
                                                    _a[key] = value,
                                                    _a));
                                                // @ts-ignore ToDo: Is there a cleaner solution?
                                                newTask[key] = value;
                                                setCurrentTask(newTask);
                                            }
                                            catch (e) {
                                                toaster.warning("Diese Aktion konnte nicht ausgeführt werden");
                                            }
                                        }, setScanAllowed: function (isAllowed) { return setScanEnabled(isAllowed); } })] })), _jsxs("div", __assign({ style: { display: "flex", flexDirection: "row" } }, { children: [_jsx("div", { style: { flex: 1 } }), _jsx(BarcodeHint, __assign({ style: { margin: "6px 20px 28px" } }, { children: "Du kannst jederzeit einen weiteren Barcode scannen, um einen anderen Auftrag anzusehen" }))] }))] }))] }))] })));
};
export default withPaywall(Packing, "apomap-pro", PaywallInfo("Scan Modus", "Hier können Sie Ihre Aufgaben scannen", "apomap Enterprise", true, _jsx(Video, {}), true));
