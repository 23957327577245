var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
//The AuthProvider listens to the state of the user
//User can be logged in or not - Depending on this just the routes in OpenRoutes will be displayed or the in ProtectedRoutes
//Additionally to the information collected in the authentification provider the object gets populated by the data from the firestore
//Data from auth-Provider -> uid/displayName/etc -> https://rnfirebase.io/reference/auth/user#gatsby-focus-wrapper
//Data from firestore -> addititonal future information -> currently email/uid -> Duplicate of auth-Provider
import React, { useState } from "react";
import "firebase/auth";
import "firebase/firestore";
import { QuestionContext } from "../contexts/QuestionContext";
export default function QuestionProvider(props) {
    //Holds the user data
    var _a = useState(false), isShown = _a[0], setShown = _a[1];
    //Is the user data fetched and ready to use
    var _b = useState(), idInFocus = _b[0], setIdInFocus = _b[1];
    return (_jsx(QuestionContext.Provider, __assign({ value: {
            modalState: [isShown, setShown],
            question: [idInFocus, setIdInFocus],
        } }, { children: props.children })));
}
