var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CrossIcon, Dialog, Heading, Pane, Paragraph, Radio, Table, } from "evergreen-ui";
import "firebase/auth";
import "firebase/firestore";
import moment from "moment";
import { useContext, useMemo, useState } from "react";
import BlueBar from "../components/BlueBar";
import { Task } from "../components/Task";
import firebase from "../config";
import { DriverContext } from "../contexts/DriverContext";
import { PlanContext } from "../contexts/PlanContext";
import { TaskContext } from "../contexts/TaskContext";
import { UserContext } from "../contexts/UserContext";
var TourSelectRow = function (_a) {
    var areTasks = _a.areTasks, tour = _a.tour, drivers = _a.drivers, selectedTourId = _a.selectedTourId, setSelectedTourId = _a.setSelectedTourId;
    var driver = useMemo(function () {
        return (tour === null || tour === void 0 ? void 0 : tour.driver)
            ? drivers.find(function (driver) { return driver.id === (tour === null || tour === void 0 ? void 0 : tour.driver); })
            : undefined;
    }, [drivers, tour.driver]);
    var tasksInTour = useMemo(function () { return areTasks.filter(function (task) { return task.task_tour === tour.id; }); }, [areTasks, tour]);
    var doneTasks = useMemo(function () {
        return tasksInTour.filter(function (task) {
            return task.task_status === "successfull" || task.task_status === "declined";
        }).length;
    }, [tasksInTour]);
    return (_jsxs(Table.Row, { children: [_jsx(Table.TextCell, { children: _jsxs(Pane, __assign({ display: "flex", flexDirection: "row", alignItems: "center" }, { children: [_jsx(Radio, { marginLeft: 5, size: 16, checked: selectedTourId === tour.id, name: "group", onChange: function () { return setSelectedTourId(tour.id); } }), _jsx(Paragraph, __assign({ color: driver === null || driver === void 0 ? void 0 : driver.color, marginLeft: 5, fontWeight: 600 }, { children: (tour === null || tour === void 0 ? void 0 : tour.name)
                                ? tour === null || tour === void 0 ? void 0 : tour.name
                                : driver
                                    ? (driver === null || driver === void 0 ? void 0 : driver.firstName) + " " + (driver === null || driver === void 0 ? void 0 : driver.lastName)
                                    : tour.driver === "cure"
                                        ? "CURE"
                                        : "Nicht festgelegt" }))] })) }), _jsx(Table.TextCell, { children: _jsx(Paragraph, __assign({ fontWeight: 600 }, { children: moment(tour.date, "YY-MM-DD").format("DD.MM.YYYY") })) }), _jsx(Table.TextCell, { children: _jsx(Paragraph, __assign({ fontWeight: 600 }, { children: tour.startTime })) }), _jsx(Table.TextCell, { children: _jsxs(Paragraph, __assign({ fontWeight: 600 }, { children: [doneTasks, "/", tasksInTour.length] })) })] }, tour.id));
};
export default function AddTaskToTourDialog(props) {
    var _a;
    var _b = useState(), isSelectedTourId = _b[0], setSelectedTourId = _b[1];
    var plan = useContext(PlanContext).plan;
    var isPlan = plan[0], setPlan = plan[1];
    var _c = useContext(TaskContext), tours = _c.tours, tasks = _c.tasks;
    var drivers = useContext(DriverContext).drivers;
    var areDrivers = drivers[0], setDrivers = drivers[1];
    var _d = useContext(UserContext), user = _d.user, ready = _d.ready;
    var isUser = user[0];
    var areTours = tours[0], setTours = tours[1];
    var areTasks = tasks[0], setTasks = tasks[1];
    var _e = useState(false), isLoading = _e[0], setLoading = _e[1];
    // Hint Message Dialog
    var _f = useState(false), isShown = _f[0], setShown = _f[1];
    var _g = useState(false), showed = _g[0], setShowed = _g[1];
    function addTaskToTour() {
        return __awaiter(this, void 0, void 0, function () {
            var taskIds;
            var _a;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        setLoading(true);
                        if (!props.task) return [3 /*break*/, 3];
                        return [4 /*yield*/, firebase
                                .firestore()
                                .collection("tours")
                                .doc(isSelectedTourId)
                                .update({
                                tasksToAdd: firebase.firestore.FieldValue.arrayUnion(props.task.task_id),
                            })];
                    case 1:
                        _b.sent();
                        return [4 /*yield*/, firebase
                                .firestore()
                                .collection("tasks")
                                .doc(props.task.task_id)
                                .update({
                                task_status: "attached",
                                task_tour: isSelectedTourId,
                            })];
                    case 2:
                        _b.sent();
                        return [3 /*break*/, 6];
                    case 3:
                        taskIds = props.tasks.map(function (task) { return task.task_id; });
                        return [4 /*yield*/, firebase
                                .firestore()
                                .collection("tours")
                                .doc(isSelectedTourId)
                                .update({
                                tasksToAdd: (_a = firebase.firestore.FieldValue).arrayUnion.apply(_a, taskIds),
                            })];
                    case 4:
                        _b.sent();
                        return [4 /*yield*/, Promise.all(props.tasks.map(function (task) { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, firebase
                                                .firestore()
                                                .collection("tasks")
                                                .doc(task.task_id)
                                                .update({
                                                task_status: "attached",
                                                task_tour: isSelectedTourId,
                                            })];
                                        case 1: return [2 /*return*/, _a.sent()];
                                    }
                                });
                            }); }))];
                    case 5:
                        _b.sent();
                        _b.label = 6;
                    case 6:
                        setLoading(false);
                        setSelectedTourId();
                        props.setShown(false);
                        try {
                            props.onChoice();
                        }
                        catch (e) {
                            console.log("On Choice handler not callable, might be outdated");
                        }
                        return [2 /*return*/];
                }
            });
        });
    }
    var listableTours = useMemo(function () { return areTours.filter(function (tour) { return tour.status !== "finished"; }); }, [areTours]);
    return (_jsx(_Fragment, { children: _jsx(Dialog, __assign({ isShown: props.isShown, isConfirmDisabled: !isSelectedTourId, hasHeader: false, hasFooter: true, cancelLabel: "Abbrechen", confirmLabel: "Task hinzuf\u00FCgen", onCancel: function () {
                props.setShown(false);
                setShowed(false);
            }, onConfirm: function () {
                addTaskToTour();
            }, isConfirmLoading: isLoading, shouldCloseOnOverlayClick: false, shouldCloseOnEscapePress: false, width: "90vw" }, { children: _jsxs(Pane, __assign({ style: {
                    height: "100%",
                    minHeight: "60vh",
                    display: "flex",
                    flexDirection: "column",
                    overflow: "hidden",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "space-between",
                } }, { children: [_jsx(BlueBar, {}), _jsxs(Pane, __assign({ style: {
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: 10,
                            width: "90%",
                        } }, { children: [_jsx(Heading, __assign({ className: "headline", style: { color: "#49beef" } }, { children: "AUFTRAG EINER TOUR ZUORDNEN" })), _jsx(CrossIcon, { onClick: function () { return props.setShown(false); } })] })), _jsxs(Pane, __assign({ style: {
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            flex: 1,
                            overflowY: "auto",
                        } }, { children: [_jsxs(Pane, __assign({ style: { width: "40%" } }, { children: [(_a = props === null || props === void 0 ? void 0 : props.tasks) === null || _a === void 0 ? void 0 : _a.map(function (task) { return (_jsx(Task, { task: task, index: task.task_id })); }), props.task && (_jsx(Task, { task: props.task, index: props.task.task_id }))] })), _jsx(Pane, __assign({ style: { width: "60%", overflowY: "auto", maxHeight: "100%" } }, { children: _jsxs(Table, { children: [_jsxs(Table.Head, { children: [_jsx(Table.TextHeaderCell, { children: "FAHRER*IN" }), _jsx(Table.TextHeaderCell, { children: "DATUM" }), _jsx(Table.TextHeaderCell, { children: "STARTZEIT" }), _jsx(Table.TextHeaderCell, { children: "AUFTR\u00C4GE" })] }), _jsx(Table.Body, { children: listableTours.map(function (tour) { return (_jsx(TourSelectRow, { areTasks: areTasks, selectedTourId: isSelectedTourId, setSelectedTourId: setSelectedTourId, tour: tour, drivers: areDrivers })); }) })] }) }))] }))] })) })) }));
}
