var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Avatar, Heading, MapMarkerIcon, Pane, Paragraph, SideSheet, Tab, Tablist, } from "evergreen-ui";
import FeatureLabel from "./FeatureLabel";
import Chat from "./Chat";
import firebase from "../config";
import Call from "./Call";
import React, { useEffect, useState } from "react";
import { usePlanInfo } from "../utility/use-plan-info";
import { getNameFromCoords } from "../utility/utility";
function DriverChat(props) {
    var _this = this;
    var _a, _b, _c, _d, _e, _f, _g;
    var _h = useState("Addresse wird geladen..."), locationName = _h[0], setLocationName = _h[1];
    var _j = useState({ lat: null, lon: null }), locationCache = _j[0], setLocationCache = _j[1];
    var _k = useState(0), selectedIndex = _k[0], setSelectedIndex = _k[1];
    var currentPlan = usePlanInfo().currentPlan;
    var tranlateLocation = function () { return __awaiter(_this, void 0, void 0, function () {
        var coords, name, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!props.driver.lastPosition) {
                        setLocationName("Unbekannter Standort");
                        return [2 /*return*/];
                    }
                    coords = {
                        lat: props.driver.lastPosition[0],
                        lon: props.driver.lastPosition[1],
                    };
                    if (coords.lat === locationCache.lat && coords.lon === locationCache.lon) {
                        // Cache is still valid, don't re-request
                        return [2 /*return*/];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, getNameFromCoords(coords.lat, coords.lon)];
                case 2:
                    name = _a.sent();
                    // Set cache
                    setLocationCache(coords);
                    setLocationName(name);
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (props.shown) {
            tranlateLocation();
        }
    }, [props.shown]);
    var currentUUID = (_a = firebase.auth().currentUser) === null || _a === void 0 ? void 0 : _a.uid;
    if (!currentUUID)
        return null;
    return (_jsxs(SideSheet, __assign({ isShown: props.shown, onCloseComplete: props.onClose, containerProps: {
            display: "flex",
            flex: "1",
            flexDirection: "column",
        } }, { children: [_jsx(Pane, __assign({ zIndex: 1, flexShrink: 0, elevation: 0, backgroundColor: "white" }, { children: _jsxs(Pane, __assign({ display: "flex", flexDirection: "row", alignItems: "center", padding: 29 }, { children: [_jsx(Avatar, { name: ((_c = (_b = props.driver) === null || _b === void 0 ? void 0 : _b.firstName) === null || _c === void 0 ? void 0 : _c.toUpperCase()) +
                                " " +
                                ((_e = (_d = props.driver) === null || _d === void 0 ? void 0 : _d.lastName) === null || _e === void 0 ? void 0 : _e.toUpperCase()), src: props.driver.image, size: 66 }), _jsxs(Pane, __assign({ paddingLeft: 21, display: "flex", flexDirection: "column" }, { children: [_jsxs(Heading, __assign({ size: 600, fontWeight: 700 }, { children: [(_f = props.driver.firstName) === null || _f === void 0 ? void 0 : _f.toUpperCase(), " ", (_g = props.driver.lastName) === null || _g === void 0 ? void 0 : _g.toUpperCase()] })), _jsxs(Pane, __assign({ display: "flex", flexDirection: "row", alignItems: "center" }, { children: [_jsx(MapMarkerIcon, { color: "#8F95B2" }), _jsx(Paragraph, __assign({ marginLeft: 2, size: 400, color: "muted" }, { children: locationName }))] }))] }))] })) })), _jsx(Pane, __assign({ zIndex: 1, flexShrink: 0, elevation: 0, backgroundColor: "white" }, { children: _jsxs(Pane, __assign({ padding: 8, paddingLeft: 29, flexDirection: "column", display: "flex" }, { children: [_jsx(FeatureLabel, { type: "Pro-Feature", content: "Dank der kostenlosen Testphase können Sie die Chat- und Video-Call Funktionen testen." }), _jsx(Tablist, __assign({ marginTop: 5, marginLeft: 5 }, { children: ["Chat", "Anruf"].map(function (tab, index) { return (_jsx(Tab, __assign({ isSelected: selectedIndex === index, onSelect: function () { return setSelectedIndex(index); } }, { children: tab }), tab)); }) }))] })) })), selectedIndex === 0 &&
                props.shown &&
                currentPlan !== "apomap-lite" &&
                currentPlan !== "apomap-plus" ? (_jsx(Chat, { chatNode: "chat_" + props.driver.id + "_" + currentUUID, id: props.driver.id })) : (selectedIndex === 0 && (_jsx(FeatureLabel, { type: "Pro-Feature", content: "Der Chat ist direkt in die Fahrer-App eingebunden. So erreichen sie ohne Drittprogramm dirket ihren Boten*in." }))), selectedIndex === 1 &&
                props.shown &&
                currentPlan !== "apomap-lite" &&
                currentPlan !== "apomap-plus" ? (_jsx(Call, { callNode: props.driver.id + "_" + currentUUID })) : (selectedIndex === 1 && (_jsx(FeatureLabel, { type: "Pro-Feature", content: "Mit der Video-Call Funktion kann ihr*e Botenfahrer*in sie mit ihrem Kunden verbinden. Ob an der Haustür oder unterwegs." })))] })));
}
export default DriverChat;
