var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useForm } from "@mantine/form";
import cronTime from "cron-time-generator";
import { AddIcon, Button, Checkbox, Dialog, Group, Heading, InfoSignIcon, InlineAlert, Pane, Paragraph, SelectMenu, TextInputField, ThemeProvider, toaster, Tooltip, } from "evergreen-ui";
import "firebase/firestore";
import { useCallback, useContext, useEffect, useMemo, useState, } from "react";
import ApoLocationInput from "../components/ApoLocationInput";
import ApoTextInput from "../components/ApoTextInput";
import { AutomatedTourCard } from "../components/Automation";
import firebase from "../config";
import NavigatorBar from "../container/NavigatorBar";
import { SchedulesContext } from "../contexts/SchedulesContext";
import withPaywall from "../hocs/withPaywall";
import { useDrivers } from "../hooks/useDrivers";
import { useLocation } from "../hooks/useLocations";
import theme from "../theme";
import { createId } from "../utility/utility";
import PaywallInfo from "./PaywallInfo";
var DayFilterBar = function (_a) {
    var selectedDays = _a.selectedDays, setSelectedDays = _a.setSelectedDays;
    var select = function (day) {
        setSelectedDays(function (state) { return __spreadArray(__spreadArray([], (day === "all" ? [] : state.filter(function (d) { return d !== "all"; })), true), [
            day,
        ], false); });
    };
    var deselect = function (day) {
        setSelectedDays(function (state) { return __spreadArray([], (day !== "all"
            ? state.filter(function (d) { return d !== day || d === "all"; })
            : state.filter(function (d) { return d !== day; })), true); });
    };
    return (_jsxs(Pane, __assign({ marginTop: 6 }, { children: [_jsx(Button, __assign({ appearance: selectedDays.includes("all") ? "primary" : undefined, onClick: function () {
                    return selectedDays.includes("all") ? deselect("all") : select("all");
                }, style: { borderRadius: 6 }, marginRight: 12, marginTop: 6 }, { children: "Alle Tage" })), _jsx(Button, __assign({ appearance: selectedDays.includes("monday") ? "primary" : undefined, onClick: function () {
                    return selectedDays.includes("monday")
                        ? deselect("monday")
                        : select("monday");
                }, style: { borderRadius: 6 }, marginRight: 12, marginTop: 6 }, { children: "Montag" })), _jsx(Button, __assign({ appearance: selectedDays.includes("tuesday") ? "primary" : undefined, onClick: function () {
                    return selectedDays.includes("tuesday")
                        ? deselect("tuesday")
                        : select("tuesday");
                }, style: { borderRadius: 6 }, marginRight: 12, marginTop: 6 }, { children: "Dienstag" })), _jsx(Button, __assign({ appearance: selectedDays.includes("wednesday") ? "primary" : undefined, onClick: function () {
                    return selectedDays.includes("wednesday")
                        ? deselect("wednesday")
                        : select("wednesday");
                }, style: { borderRadius: 6 }, marginRight: 12, marginTop: 6 }, { children: "Mittwoch" })), _jsx(Button, __assign({ appearance: selectedDays.includes("thursday") ? "primary" : undefined, onClick: function () {
                    return selectedDays.includes("thursday")
                        ? deselect("thursday")
                        : select("thursday");
                }, style: { borderRadius: 6 }, marginRight: 12, marginTop: 6 }, { children: "Donnerstag" })), _jsx(Button, __assign({ appearance: selectedDays.includes("friday") ? "primary" : undefined, onClick: function () {
                    return selectedDays.includes("friday")
                        ? deselect("friday")
                        : select("friday");
                }, style: { borderRadius: 6 }, marginRight: 12, marginTop: 6 }, { children: "Freitag" })), _jsx(Button, __assign({ appearance: selectedDays.includes("saturday") ? "primary" : undefined, onClick: function () {
                    return selectedDays.includes("saturday")
                        ? deselect("saturday")
                        : select("saturday");
                }, style: { borderRadius: 6 }, marginRight: 12, marginTop: 6 }, { children: "Samstag" })), _jsx(Button, __assign({ appearance: selectedDays.includes("sunday") ? "primary" : undefined, onClick: function () {
                    return selectedDays.includes("sunday")
                        ? deselect("sunday")
                        : select("sunday");
                }, style: { borderRadius: 6 }, marginRight: 12, marginTop: 6 }, { children: "Sonntag" }))] })));
};
var FormValidationAlert = function (_a) {
    var form = _a.form, type = _a.type;
    return form.errors[type] ? (_jsx(InlineAlert, __assign({ intent: "danger" }, { children: form.errors[type] }))) : null;
};
var WeekDayButton = function (_a) {
    var weekday = _a.weekday, form = _a.form, title = _a.title;
    var selected = useMemo(function () { return form.getInputProps("weekdays.".concat(weekday)).value; }, [form, weekday]);
    return (_jsx(Button, __assign({ type: "button", appearance: selected ? "primary" : "default", onClick: function () {
            form.setFieldValue("weekdays.".concat(weekday), selected ? false : true);
        } }, { children: title })));
};
export var TourAutomationDialog = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15;
    var isShown = _a.isShown, setShown = _a.setShown, locationsMap = _a.locationsMap, driversMap = _a.driversMap;
    var drivers = useDrivers();
    var availableDrivers = useMemo(function () { return drivers.filter(function (a) { return !a.archived; }); }, [drivers]);
    var locations = useLocation();
    var _16 = useState([]), favoriteGroups = _16[0], setFavoriteGroups = _16[1];
    var getFavoritesGroup = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a, _b;
        return __generator(this, function (_c) {
            firebase
                .firestore()
                .collection("favoritesGroup")
                .where("owner", "==", (_b = (_a = firebase === null || firebase === void 0 ? void 0 : firebase.auth()) === null || _a === void 0 ? void 0 : _a.currentUser) === null || _b === void 0 ? void 0 : _b.uid)
                .get()
                .then(function (snap) {
                var favs = [];
                snap.forEach(function (doc) {
                    var data = doc.data();
                    favs.push(data);
                });
                setFavoriteGroups(favs);
            })
                .catch(function (err) { return console.log(err); });
            return [2 /*return*/];
        });
    }); };
    useEffect(function () {
        getFavoritesGroup();
    }, []);
    //User can also select individual start/endpoints
    var _17 = useState(false), useIndividualStartPoint = _17[0], setUseIndividualStartPoint = _17[1];
    var _18 = useState(null), selectedIndividualStartPoint = _18[0], setSelectedIndividualStartPoint = _18[1];
    var _19 = useState(false), useIndividualEndPoint = _19[0], setUseIndividualEndPoint = _19[1];
    var _20 = useState(null), selectedIndividualEndPoint = _20[0], setSelectedIndividualEndPoint = _20[1];
    var form = useForm({
        initialValues: {
            tourName: (_c = (_b = isShown === null || isShown === void 0 ? void 0 : isShown.workflow) === null || _b === void 0 ? void 0 : _b.tourName) !== null && _c !== void 0 ? _c : "",
            weekdays: (_e = (_d = isShown === null || isShown === void 0 ? void 0 : isShown.workflow) === null || _d === void 0 ? void 0 : _d.weekdays) !== null && _e !== void 0 ? _e : {},
            startTime: (_g = (_f = isShown === null || isShown === void 0 ? void 0 : isShown.workflow) === null || _f === void 0 ? void 0 : _f.startTime) !== null && _g !== void 0 ? _g : "",
            startLocation: (_j = (_h = isShown === null || isShown === void 0 ? void 0 : isShown.workflow) === null || _h === void 0 ? void 0 : _h.startLocation) !== null && _j !== void 0 ? _j : "",
            endLocation: (_l = (_k = isShown === null || isShown === void 0 ? void 0 : isShown.workflow) === null || _k === void 0 ? void 0 : _k.endLocation) !== null && _l !== void 0 ? _l : "",
            individualStartLocation: (_o = (_m = isShown.workflow) === null || _m === void 0 ? void 0 : _m.individualStartLocation) !== null && _o !== void 0 ? _o : null,
            individualEndLocation: (_q = (_p = isShown.workflow) === null || _p === void 0 ? void 0 : _p.individualEndLocation) !== null && _q !== void 0 ? _q : null,
            driver: (_s = (_r = isShown === null || isShown === void 0 ? void 0 : isShown.workflow) === null || _r === void 0 ? void 0 : _r.driver) !== null && _s !== void 0 ? _s : null,
            vehicle: (_u = (_t = isShown === null || isShown === void 0 ? void 0 : isShown.workflow) === null || _t === void 0 ? void 0 : _t.vehicle) !== null && _u !== void 0 ? _u : "",
            centralDeliveryPoint: (_x = (_w = (_v = isShown.workflow) === null || _v === void 0 ? void 0 : _v.centralDeliveryPoint) === null || _w === void 0 ? void 0 : _w.id) !== null && _x !== void 0 ? _x : null,
            favoriteGroups: (_z = (_y = isShown.workflow) === null || _y === void 0 ? void 0 : _y.favoritesGroup) !== null && _z !== void 0 ? _z : null,
        },
        validate: {
            tourName: function (value) {
                return value.length === 0 ? "Tourname muss vergeben werden" : null;
            },
            weekdays: function (value) {
                return Object.keys(value).filter(function (key) { return value[key]; }).length === 0
                    ? "Es muss mindestens 1 Wochentag gewählt sein"
                    : null;
            },
            startTime: function (value) {
                if (value === "") {
                    return "Startzeit muss vergeben werden";
                }
                return null;
            },
            vehicle: function (value) {
                return value === "" ? "Es muss ein Fahrzeug gewählt sein" : null;
            },
            startLocation: function (value) {
                return value === "" && !useIndividualStartPoint
                    ? "Es muss ein Startstandpunkt gewählt sein"
                    : null;
            },
            endLocation: function (value) {
                return value === "" && !useIndividualEndPoint
                    ? "Es muss ein Endstandpunkt gewählt sein"
                    : null;
            },
            individualStartLocation: function (value) {
                return useIndividualStartPoint && value === null
                    ? "Es muss eine individuelle Startadresse angegeben werden"
                    : null;
            },
            individualEndLocation: function (value) {
                return useIndividualEndPoint && value === null
                    ? "Es muss eine individuelle Endddresse angegeben werden"
                    : null;
            },
        },
    });
    //Init when workflow will be edited
    useEffect(function () {
        var _a, _b, _c, _d, _e, _f;
        if (isShown.workflow) {
            var _g = isShown.workflow, driver = _g.driver, startLocation = _g.startLocation, endLocation = _g.endLocation, vehicle = _g.vehicle, startTime = _g.startTime, tourName = _g.tourName, weekdays = _g.weekdays, centralDeliveryPoint = _g.centralDeliveryPoint, individualStartLocation = _g.individualStartLocation, individualEndLocation = _g.individualEndLocation, favoritesGroup = _g.favoritesGroup;
            form.setFieldValue("driver", driver);
            setSelectedDriver(driver
                ? driversMap[driver].firstName + " " + driversMap[driver].lastName
                : "Kein Fahrer festgelegt");
            form.setFieldValue("startLocation", startLocation);
            setStartLocation((_b = (_a = locationsMap[startLocation]) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : "");
            form.setFieldValue("endLocation", endLocation);
            setEndLocation((_d = (_c = locationsMap[endLocation]) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : "");
            form.setFieldValue("vehicle", vehicle);
            setSelectedVehicle(vehicle === "car" ? "Auto" : "Fahrrad");
            form.setFieldValue("startTime", startTime);
            form.setFieldValue("tourName", tourName);
            form.setFieldValue("weekdays", weekdays);
            form.setFieldValue("centralDeliveryPoint", (_e = centralDeliveryPoint === null || centralDeliveryPoint === void 0 ? void 0 : centralDeliveryPoint.id) !== null && _e !== void 0 ? _e : null);
            form.setFieldValue("favoriteGroups", favoritesGroup !== null && favoritesGroup !== void 0 ? favoritesGroup : null);
            setSelectedFavoritesGroup(favoritesGroup !== null && favoritesGroup !== void 0 ? favoritesGroup : []);
            setCentralDeliveryPoint((_f = centralDeliveryPoint === null || centralDeliveryPoint === void 0 ? void 0 : centralDeliveryPoint.id) !== null && _f !== void 0 ? _f : "");
            //For Individual Start/endpoints
            if (individualStartLocation) {
                setUseIndividualStartPoint(true);
                setSelectedIndividualStartPoint(individualStartLocation);
                form.setFieldValue("individualStartLocation", individualStartLocation);
            }
            if (individualEndLocation) {
                setUseIndividualEndPoint(true);
                setSelectedIndividualEndPoint(individualEndLocation);
                form.setFieldValue("individualEndLocation", individualEndLocation);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [driversMap, isShown.workflow, locationsMap]);
    var _21 = useState(((_0 = isShown.workflow) === null || _0 === void 0 ? void 0 : _0.driver) ? (_2 = (_1 = isShown.workflow) === null || _1 === void 0 ? void 0 : _1.driver) !== null && _2 !== void 0 ? _2 : "" : ""), selectedDriver = _21[0], setSelectedDriver = _21[1];
    var _22 = useState(((_3 = isShown.workflow) === null || _3 === void 0 ? void 0 : _3.vehicle) ? (_5 = (_4 = isShown.workflow) === null || _4 === void 0 ? void 0 : _4.vehicle) !== null && _5 !== void 0 ? _5 : "" : ""), selectedVehicle = _22[0], setSelectedVehicle = _22[1];
    var _23 = useState(((_6 = isShown.workflow) === null || _6 === void 0 ? void 0 : _6.startLocation) ? (_8 = (_7 = isShown.workflow) === null || _7 === void 0 ? void 0 : _7.startLocation) !== null && _8 !== void 0 ? _8 : "" : ""), selectedStartLocation = _23[0], setStartLocation = _23[1];
    var _24 = useState(((_9 = isShown.workflow) === null || _9 === void 0 ? void 0 : _9.endLocation) ? (_11 = (_10 = isShown.workflow) === null || _10 === void 0 ? void 0 : _10.endLocation) !== null && _11 !== void 0 ? _11 : "" : ""), selectedEndLocation = _24[0], setEndLocation = _24[1];
    var _25 = useState(((_12 = isShown.workflow) === null || _12 === void 0 ? void 0 : _12.favoritesGroup) ? isShown.workflow.favoritesGroup : []), selectedFavoritesGroup = _25[0], setSelectedFavoritesGroup = _25[1];
    var _26 = useState(((_13 = isShown.workflow) === null || _13 === void 0 ? void 0 : _13.centralDeliveryPoint)
        ? (_15 = (_14 = isShown.workflow) === null || _14 === void 0 ? void 0 : _14.centralDeliveryPoint.id) !== null && _15 !== void 0 ? _15 : ""
        : ""), selectedCentralDeliveryPoint = _26[0], setCentralDeliveryPoint = _26[1];
    var addWorkflow = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var validDays, cron;
        return __generator(this, function (_a) {
            validDays = Object.entries(values.weekdays).flatMap(function (_a) {
                var key = _a[0], value = _a[1];
                return value ? key : [];
            });
            cron = cronTime.onSpecificDaysAt(validDays, 0, 0);
            firebase
                .firestore()
                .collection("schedules")
                .doc(values.workflowId)
                .set(__assign(__assign({}, values), { cron: cron }))
                .then(function () {
                toaster.success("Erfolgreich angelegt");
                closeModal();
            })
                .catch(function (err) {
                console.log(err);
                toaster.danger("Ein Fehler ist aufgetreten");
            });
            return [2 /*return*/];
        });
    }); };
    var updateWorkflow = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var validDays, cron;
        return __generator(this, function (_a) {
            validDays = Object.entries(values.weekdays).flatMap(function (_a) {
                var key = _a[0], value = _a[1];
                return value ? key : [];
            });
            cron = cronTime.onSpecificDaysAt(validDays, 0, 0);
            firebase
                .firestore()
                .collection("schedules")
                .doc(values.workflowId)
                .update(__assign(__assign({}, values), { cron: cron }))
                .then(function () {
                toaster.success("Erfolgreich gespeichert");
                closeModal();
            })
                .catch(function (err) {
                console.log(err);
                toaster.danger("Ein Fehler ist aufgetreten");
            });
            return [2 /*return*/];
        });
    }); };
    var resetSelected = function () {
        setSelectedDriver("");
        setSelectedVehicle("");
        setStartLocation("");
        setEndLocation("");
        setSelectedFavoritesGroup([]);
    };
    var closeModal = function () {
        form.reset();
        resetSelected();
        setShown({ shown: false, workflow: undefined });
    };
    return (_jsx(Dialog, __assign({ isShown: isShown.shown, title: isShown.workflow
            ? "Wiederkehrende Tour bearbeiten"
            : "Wiederkehrende Tour erstellen", onCloseComplete: closeModal, hasFooter: false }, { children: _jsxs("form", __assign({ onSubmit: function (e) {
                var _a, _b, _c;
                e.preventDefault();
                form.validate();
                if (Object.keys(form.errors).length > 0) {
                    return;
                }
                if (form.isValid()) {
                    var workflowId = isShown.workflow
                        ? isShown.workflow.workflowId
                        : createId();
                    if (isShown.workflow) {
                        updateWorkflow(__assign(__assign({}, form.values), { centralDeliveryPoint: form.values.centralDeliveryPoint
                                ? locationsMap[form.values.centralDeliveryPoint]
                                : null, workflowId: isShown.workflow.workflowId, owner: isShown.workflow.owner }));
                    }
                    else {
                        addWorkflow(__assign(__assign({}, form.values), { workflowId: workflowId, centralDeliveryPoint: form.values.centralDeliveryPoint
                                ? locationsMap[form.values.centralDeliveryPoint]
                                : null, owner: (_c = (_b = (_a = firebase === null || firebase === void 0 ? void 0 : firebase.auth()) === null || _a === void 0 ? void 0 : _a.currentUser) === null || _b === void 0 ? void 0 : _b.uid) !== null && _c !== void 0 ? _c : "", favoritesGroup: selectedFavoritesGroup }));
                    }
                }
                else {
                    toaster.warning("Nicht alle verpflichtenden Felder ausgewählt!");
                }
            } }, { children: [_jsxs(Pane, __assign({ style: {
                        display: "flex",
                        flexDirection: "row",
                    } }, { children: [_jsx(TextInputField, __assign({ style: { marginTop: 6 }, label: "Tourname", placeholder: "Name der Tour" }, form.getInputProps("tourName"))), _jsxs(Pane, __assign({ style: { flexDirection: "column", marginLeft: 6 } }, { children: [_jsx(Paragraph, __assign({ fontWeight: 500, marginBottom: "8px" }, { children: "Zentraler Botendienst" })), _jsx(SelectMenu, __assign({ title: "Zentraler Botendienst", options: __spreadArray([
                                        { label: "Nicht aktiv", value: "" }
                                    ], locations === null || locations === void 0 ? void 0 : locations.map(function (_a) {
                                        var name = _a.name, id = _a.id;
                                        return ({
                                            label: name,
                                            value: id,
                                        });
                                    }), true), selected: selectedStartLocation, onSelect: function (item) {
                                        setCentralDeliveryPoint(item.value);
                                        form.setFieldValue("centralDeliveryPoint", item.value);
                                        setStartLocation(item.label === "Nicht aktiv" ? "" : item.label);
                                        form.setFieldValue("startLocation", item.value);
                                        setEndLocation(item.label === "Nicht aktiv" ? "" : item.label);
                                        form.setFieldValue("endLocation", item.value);
                                    }, onDeselect: function (item) {
                                        setCentralDeliveryPoint("");
                                        form.setFieldValue("centralDeliveryPoint", "");
                                        setEndLocation(function () { return ""; });
                                        form.setFieldValue("endLocation", "");
                                        setStartLocation(function () { return ""; });
                                        form.setFieldValue("startLocation", "");
                                    } }, { children: _jsx(Button, __assign({ type: "button" }, { children: selectedCentralDeliveryPoint !== ""
                                            ? "Von " +
                                                locationsMap[selectedCentralDeliveryPoint].name +
                                                " aus"
                                            : "Nicht aktiv" })) }))] }))] })), _jsx(Paragraph, __assign({ fontWeight: 500, marginBottom: "8px" }, { children: "Wochentag" })), _jsx(Pane, __assign({ marginBottom: "8px", display: "flex", flexDirection: "row" }, { children: _jsxs(Group, __assign({ size: "large" }, { children: [_jsx(WeekDayButton, { form: form, title: "MO", weekday: "monday" }), _jsx(WeekDayButton, { form: form, title: "DI", weekday: "tuesday" }), _jsx(WeekDayButton, { form: form, title: "MI", weekday: "wednesday" }), _jsx(WeekDayButton, { form: form, title: "DO", weekday: "thursday" }), _jsx(WeekDayButton, { form: form, title: "FR", weekday: "friday" }), _jsx(WeekDayButton, { form: form, title: "SA", weekday: "saturday" }), _jsx(WeekDayButton, { form: form, title: "SO", weekday: "sunday" })] })) })), _jsx(Paragraph, __assign({ fontWeight: 500, marginBottom: "8px" }, { children: "Startzeit" })), _jsx(Pane, __assign({ display: "flex", flexDirection: "column", justifyContent: "flex-start" }, { children: _jsx(ApoTextInput, __assign({}, form.getInputProps("startTime"), { type: "time", onChange: function (e) {
                            return form.setFieldValue("startTime", e.target.value);
                        } })) })), selectedCentralDeliveryPoint === "" && (_jsxs(Pane, __assign({ display: "flex", flexDirection: "row", justifyContent: "space-between" }, { children: [_jsxs(Pane, __assign({ display: "flex", flexDirection: "column" }, { children: [_jsxs(Pane, __assign({ display: "flex", flexDirection: "row", alignItems: "center" }, { children: [_jsx(Paragraph, __assign({ cursor: "pointer", fontWeight: 500 }, { children: "Startpunkt" })), _jsx(Tooltip, __assign({ content: "An welchen Standort soll die Tour beginnen?" }, { children: _jsx(InfoSignIcon, { marginLeft: 6 }) })), _jsx(Checkbox, { checked: useIndividualStartPoint, onChange: function () {
                                                if (useIndividualStartPoint) {
                                                    setSelectedIndividualStartPoint(null);
                                                    form.setFieldValue("individualStartLocation", null);
                                                }
                                                setUseIndividualStartPoint(function (s) { return !s; });
                                            }, marginLeft: 4 }), _jsx(Paragraph, __assign({ marginLeft: 4, fontWeight: 300 }, { children: "Individuell" }))] })), useIndividualStartPoint ? (_jsxs(_Fragment, { children: [_jsx(Paragraph, __assign({ fontWeight: "bold" }, { children: "Gew\u00E4hlt:" })), _jsx(Paragraph, { children: selectedIndividualStartPoint === null || selectedIndividualStartPoint === void 0 ? void 0 : selectedIndividualStartPoint.id }), _jsx(ApoLocationInput, { label: "", required: true, defaultValue: selectedIndividualStartPoint, onChange: function (e) {
                                                setStartLocation("");
                                                form.setFieldValue("startLocation", "");
                                                setSelectedIndividualStartPoint({
                                                    id: e.address,
                                                    coords: e.coords,
                                                });
                                                form.setFieldValue("individualStartLocation", {
                                                    id: e.address,
                                                    coords: e.coords,
                                                });
                                            }, style: { width: "100%" }, placeholder: "Hier Adresse suchen", disabled: undefined, resetNumber: undefined })] })) : (_jsx(SelectMenu, __assign({ title: "Verf\u00FCgbare Standorte", options: locations === null || locations === void 0 ? void 0 : locations.map(function (_a) {
                                        var name = _a.name, id = _a.id;
                                        return ({
                                            label: name,
                                            value: id,
                                        });
                                    }), selected: selectedStartLocation, onSelect: function (item) {
                                        setStartLocation(item.label);
                                        form.setFieldValue("startLocation", item.value);
                                    }, onDeselect: function (item) {
                                        setStartLocation("");
                                        form.setFieldValue("startLocation", "");
                                    } }, { children: _jsx(Button, __assign({ type: "button" }, { children: selectedStartLocation !== ""
                                            ? selectedStartLocation + " gewählt"
                                            : "Standort wählen..." })) })))] })), _jsxs(Pane, __assign({ display: "flex", flexDirection: "column" }, { children: [_jsxs(Pane, __assign({ marginBottom: 8, display: "flex", flexDirection: "row", alignItems: "center" }, { children: [_jsx(Paragraph, __assign({ cursor: "pointer", fontWeight: 500 }, { children: "Endpunkt" })), _jsx(Tooltip, __assign({ content: "An welchen Standorten sollen die Tour enden?" }, { children: _jsx(InfoSignIcon, { marginLeft: 6 }) })), _jsx(Checkbox, { checked: useIndividualEndPoint, onChange: function () {
                                                if (useIndividualEndPoint) {
                                                    setSelectedIndividualEndPoint(null);
                                                    form.setFieldValue("individualEndLocation", null);
                                                }
                                                setUseIndividualEndPoint(function (s) { return !s; });
                                            }, marginLeft: 4 }), _jsx(Paragraph, __assign({ marginLeft: 4, fontWeight: 300 }, { children: "Individuell" }))] })), useIndividualEndPoint ? (_jsxs(_Fragment, { children: [_jsx(Paragraph, __assign({ fontWeight: "bold" }, { children: "Gew\u00E4hlt:" })), _jsx(Paragraph, { children: selectedIndividualEndPoint === null || selectedIndividualEndPoint === void 0 ? void 0 : selectedIndividualEndPoint.id }), _jsx(ApoLocationInput, { label: "", required: true, defaultValue: selectedIndividualEndPoint, onChange: function (e) {
                                                setEndLocation(function () { return ""; });
                                                form.setFieldValue("endLocation", "");
                                                setSelectedIndividualEndPoint({
                                                    id: e.address,
                                                    coords: e.coords,
                                                });
                                                form.setFieldValue("individualEndLocation", {
                                                    id: e.address,
                                                    coords: e.coords,
                                                });
                                            }, style: { width: "100%" }, placeholder: "Hier Adresse suchen", disabled: undefined, resetNumber: undefined })] })) : (_jsx(SelectMenu, __assign({ title: "Verf\u00FCgbare Standorte", options: locations === null || locations === void 0 ? void 0 : locations.map(function (_a) {
                                        var name = _a.name, id = _a.id;
                                        return ({
                                            label: name,
                                            value: id,
                                        });
                                    }), selected: selectedEndLocation, onSelect: function (item) {
                                        setEndLocation(function () { return item.label; });
                                        form.setFieldValue("endLocation", item.value);
                                    }, onDeselect: function (item) {
                                        setEndLocation(function () { return ""; });
                                        form.setFieldValue("endLocation", "");
                                    } }, { children: _jsx(Button, __assign({ type: "button" }, { children: selectedEndLocation !== ""
                                            ? selectedEndLocation + " gewählt"
                                            : "Standort wählen..." })) })))] }))] }))), _jsxs(Pane, __assign({ display: "flex", flexDirection: "row", justifyContent: "space-between" }, { children: [_jsxs(Pane, __assign({ display: "flex", flexDirection: "column" }, { children: [_jsxs(Pane, __assign({ marginY: 8, display: "flex", flexDirection: "row", alignItems: "center" }, { children: [_jsx(Paragraph, __assign({ cursor: "pointer", fontWeight: 500 }, { children: "Fahrer" })), _jsx(Tooltip, __assign({ content: "Welche Fahrer sollen diese Tour fahren d\u00FCrfen (Kein leer sein!)" }, { children: _jsx(InfoSignIcon, { marginLeft: 6 }) }))] })), _jsx(SelectMenu, __assign({ title: "Verf\u00FCgbare Fahrer", options: __spreadArray([
                                        { label: "Nicht festlegen", value: "" }
                                    ], availableDrivers === null || availableDrivers === void 0 ? void 0 : availableDrivers.map(function (_a) {
                                        var firstName = _a.firstName, lastName = _a.lastName, id = _a.id;
                                        return ({
                                            label: firstName + " " + lastName,
                                            value: id,
                                        });
                                    }), true), selected: selectedDriver, onSelect: function (item) {
                                        setSelectedDriver(function () { return item.label; });
                                        if (item.value === "") {
                                            form.setFieldValue("driver", null);
                                        }
                                        else {
                                            form.setFieldValue("driver", item.value);
                                        }
                                    }, onDeselect: function (item) {
                                        setSelectedDriver(function () { return ""; });
                                        form.setFieldValue("driver", "");
                                    } }, { children: _jsx(Button, __assign({ type: "button", marginBottom: "8px" }, { children: selectedDriver !== ""
                                            ? selectedDriver + " gewählt"
                                            : "Fahrer wählen..." })) }))] })), _jsxs(Pane, __assign({ display: "flex", flexDirection: "column" }, { children: [_jsxs(Pane, __assign({ marginY: 8, display: "flex", flexDirection: "row", alignItems: "center" }, { children: [_jsx(Paragraph, __assign({ cursor: "pointer", fontWeight: 500 }, { children: "Fahrzeug" })), _jsx(Tooltip, __assign({ content: "Welches Fahrzeug sollen diese Tour fahren" }, { children: _jsx(InfoSignIcon, { marginLeft: 6 }) }))] })), _jsx(SelectMenu, __assign({ title: "Verf\u00FCgbare Fahrer", options: [
                                        { label: "Auto", value: "car" },
                                        { label: "Fahrrad", value: "bike" },
                                    ], selected: selectedVehicle, onSelect: function (item) {
                                        setSelectedVehicle(function () { return item.label; });
                                        form.setFieldValue("vehicle", item.value);
                                    }, onDeselect: function (item) {
                                        setSelectedVehicle(function () { return ""; });
                                        form.setFieldValue("vehicle", "");
                                    } }, { children: _jsx(Button, __assign({ type: "button", marginBottom: "8px" }, { children: selectedVehicle !== ""
                                            ? selectedVehicle === "Auto"
                                                ? "Auto gewählt"
                                                : "Fahrrad gewählt"
                                            : "Fahrzeug wählen..." })) }))] }))] })), _jsxs(Pane, __assign({ display: "flex", flexDirection: "column" }, { children: [_jsxs(Pane, __assign({ marginY: 8, display: "flex", flexDirection: "row", alignItems: "center" }, { children: [_jsx(Paragraph, __assign({ cursor: "pointer", fontWeight: 500 }, { children: "Favoritengruppen" })), _jsx(Tooltip, __assign({ content: "W\u00E4hle aus, welche Favoriten direkt an die Leertour gehangen werden sollen" }, { children: _jsx(InfoSignIcon, { marginLeft: 6 }) }))] })), _jsx(Pane, { children: _jsx(SelectMenu, __assign({ title: "Favoritengruppen w\u00E4hlen", isMultiSelect: true, options: favoriteGroups.map(function (v) { return ({
                                    label: v.name,
                                    value: v.uid,
                                }); }), selected: selectedFavoritesGroup, onSelect: function (item) {
                                    setSelectedFavoritesGroup(function (s) { return __spreadArray(__spreadArray([], s, true), [item.value], false); });
                                }, onDeselect: function (item) {
                                    setSelectedFavoritesGroup(function (s) {
                                        return s.filter(function (i) { return i !== item.value; });
                                    });
                                } }, { children: _jsx(Button, __assign({ type: "button", marginBottom: "8px" }, { children: selectedFavoritesGroup.length !== 0
                                        ? "".concat(selectedFavoritesGroup.length, " Favoritengruppen gew\u00E4hlt")
                                        : "Favoritengruppe wählen" })) })) })] })), _jsx(FormValidationAlert, { form: form, type: "tourName" }), _jsx(FormValidationAlert, { form: form, type: "weekdays" }), _jsx(FormValidationAlert, { form: form, type: "startTime" }), _jsx(FormValidationAlert, { form: form, type: "endLocation" }), _jsx(FormValidationAlert, { form: form, type: "startLocation" }), _jsx(FormValidationAlert, { form: form, type: "vehicle" }), _jsx(FormValidationAlert, { form: form, type: "driver" }), _jsx(FormValidationAlert, { form: form, type: "individualStartLocation" }), _jsx(FormValidationAlert, { form: form, type: "individualEndLocation" }), _jsx(Pane, __assign({ display: "flex", flexDirection: "row", justifyContent: "flex-end" }, { children: isShown.workflow ? (_jsx(Button, { children: "Speichern" })) : (_jsx(Button, __assign({ intent: "none", appearance: "primary" }, { children: "Erstellen" }))) }))] })) })));
};
var Automate = function () {
    var _a = useState({ shown: false }), dialogState = _a[0], setDialogState = _a[1];
    var _b = useState(["all"]), selectedDays = _b[0], setSelectedDays = _b[1];
    var schedules = useContext(SchedulesContext).schedules;
    var drivers = useDrivers();
    var locations = useLocation();
    var locationsMap = useMemo(function () {
        return Object.fromEntries(locations.map(function (entry) { return [entry.id, entry]; }));
    }, [locations]);
    var driversMap = useMemo(function () {
        return Object.fromEntries(drivers
            .filter(function (a) { return !a.archived; })
            .map(function (entry) { return [entry.id, entry]; }));
    }, [drivers]);
    var isInRange = useCallback(function (schedule) {
        var daysString = Object.entries(schedule.weekdays)
            .filter(function (_a) {
            var key = _a[0], value = _a[1];
            return value;
        })
            .map(function (_a) {
            var key = _a[0];
            return key;
        })
            .join("");
        var included = false;
        selectedDays.map(function (day) {
            if (daysString.includes(day)) {
                included = true;
            }
        });
        return included;
    }, [selectedDays]);
    var filteredSchedules = useMemo(function () {
        return schedules.filter(function (schedule) {
            return selectedDays.includes("all") ? true : isInRange(schedule);
        });
    }, [isInRange, schedules, selectedDays]);
    return (_jsxs(ThemeProvider
    /* @ts-ignore */
    , __assign({ 
        /* @ts-ignore */
        value: theme }, { children: [_jsx(NavigatorBar, {}), _jsxs(Pane, __assign({ elevation: 2, style: {
                    padding: 25,
                    margin: 25,
                    width: "90vw",
                    overflow: "hidden",
                    height: "100%",
                    overflowY: "scroll",
                } }, { children: [_jsx(TourAutomationDialog, { locationsMap: locationsMap, driversMap: driversMap, isShown: dialogState, setShown: setDialogState }), _jsxs(Pane, __assign({ display: "flex", flexDirection: "column", style: {
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                            gap: "0.5rem",
                            marginBottom: 18,
                        } }, { children: [_jsx(Heading, __assign({ size: 900 }, { children: "Wiederkehrende Leer-Touren" })), _jsx(Heading, __assign({ size: 700, style: { color: "#000000" } }, { children: "Hier k\u00F6nnen wiederkehrende Leer-Touren erstellt werden, die automatisiert in den vordefinierten Zeitslots der Tourplanung erscheinen." }))] })), _jsx(Button, __assign({ appearance: "primary", style: { borderRadius: 6 }, onClick: function () { return setDialogState({ shown: true }); }, marginY: 8, marginRight: 12, iconAfter: AddIcon }, { children: "Tour erstellen" })), _jsx(DayFilterBar, { selectedDays: selectedDays, setSelectedDays: setSelectedDays }), filteredSchedules.map(function (tour) { return (_jsx(AutomatedTourCard, { driversMap: driversMap, 
                        // @ts-ignore - TODO: Fix this
                        locationsMap: locationsMap, tour: tour, setDialogState: setDialogState })); })] }))] })));
};
export default withPaywall(Automate, "apomap-pro", PaywallInfo("Automate", "Automatisiere deine Abläufe", "apomap Enterprise", true, "", true));
