var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Badge, Button, ChevronLeftIcon, ChevronRightIcon, Group, Heading, Pane, Paragraph, Spinner, Text, TickCircleIcon, toaster, } from "evergreen-ui";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import { useContext, useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import firebase from "../config";
import { PaymentContext } from "../contexts/PaymentContext";
import { PlanContext } from "../contexts/PlanContext";
import { UserContext } from "../contexts/UserContext";
var FeaturePoint = function (_a) {
    var text = _a.text;
    return (_jsxs(Pane, __assign({ margin: 2, display: "flex", flexDirection: "row", alignItems: "center" }, { children: [_jsx(TickCircleIcon, {}), _jsx(Paragraph, __assign({ fontFamily: "bold", marginLeft: 10, size: 300 }, { children: text }))] })));
};
var PlanCard = function (_a) {
    var color = _a.color, features = _a.features, price = _a.price, onClick = _a.onClick, isSelected = _a.isSelected, isLoading = _a.isLoading, planName = _a.planName, planDescription = _a.planDescription, selectedSubscriptionPlan = _a.selectedSubscriptionPlan, hasTestBadge = _a.hasTestBadge;
    return (_jsx(_Fragment, { children: _jsxs(Pane, __assign({ border: isSelected ? "3px #FFB758 dashed" : "3px #FFB75800 dashed", height: "100%", borderRadius: "15px", width: "35%", backgroundColor: "white", display: "flex", flexDirection: "column", justifyContent: "space-between", overflow: "hidden", alignItems: "center" }, { children: [_jsxs(Pane, __assign({ maxHeight: "20%", backgroundColor: color, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", padding: 20 }, { children: [_jsx(Heading, __assign({ size: "large", color: "white", textAlign: "center" }, { children: planName })), _jsx(Paragraph, __assign({ color: "white", textAlign: "center" }, { children: planDescription })), hasTestBadge && (_jsx(Badge, __assign({ marginTop: 4 }, { children: "30 Tage kostenlos testen!" })))] })), _jsx(Pane, __assign({ display: "flex", marginTop: 20, justifyContent: "flex-start", flexDirection: "column", width: "95%", height: "60%", overflow: "hidden", paddingLeft: 8, paddingRight: 8 }, { children: features.map(function (feature) { return (_jsx(FeaturePoint, { text: feature })); }) })), _jsxs(Pane, __assign({ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "flex-start", width: "100%", maxHeight: "25%", marginTop: 15, padding: 14 }, { children: [_jsx(Pane, { height: "1px", width: "100%", backgroundColor: "#ECF0F9" }), _jsx(Heading, __assign({ color: "#2D3B51", marginTop: 4 }, { children: planName === "apomap starter"
                                ? "".concat(price, "\u20AC* / ").concat(selectedSubscriptionPlan === "annual" ? "Jahr" : "Monat")
                                : "ab ".concat(price, "\u20AC* / ").concat(selectedSubscriptionPlan === "annual" ? "Jahr" : "Monat") })), _jsxs(Pane, __assign({ position: "relative", alignItems: "center", display: "flex", flexDirection: "row" }, { children: [isLoading && (_jsx(Spinner, { position: "absolute", left: -50, size: "small" })), _jsx(Button, __assign({ marginTop: 10, size: "large", appearance: "primary", backgroundColor: isSelected ? "#FFB758" : "#2D3B51", onClick: onClick }, { children: isSelected ? "Aktueller Plan" : "Plan wählen" }))] }))] }))] })) }));
};
export default function AddDataCard(_a) {
    var controls = _a.controls;
    var user = useContext(UserContext).user;
    var isUser = user[0];
    // const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] =
    // useState("monthly");
    var _b = useState(false), isLoading = _b[0], setLoading = _b[1];
    var plan = useContext(PlanContext).plan;
    var isPlanData = plan[0];
    var _c = useContext(PaymentContext), payMethodeManagementState = _c.payMethodeManagementState, payMethodeManagementLayout = _c.payMethodeManagementLayout, selectedSubscriptionState = _c.selectedSubscriptionState;
    var setPayMethodeManagementShown = payMethodeManagementState[1];
    var setPayMethodeManagementVersion = payMethodeManagementLayout[1];
    var selectedSubscriptionPlan = selectedSubscriptionState[0], setSelectedSubscriptionPlan = selectedSubscriptionState[1];
    var subscriptionModel = useMemo(function () { return [
        { label: "Monatlich", value: "monthly" },
        { label: "Jährlich -10%", value: "annual" },
    ]; }, []);
    var history = useHistory();
    function makeSubscription(id, label, type) {
        return __awaiter(this, void 0, void 0, function () {
            var startSubscription;
            return __generator(this, function (_a) {
                setLoading(true);
                if (!id) {
                    toaster.warning("Bitte wählen Sie einen Plan aus!");
                    return [2 /*return*/];
                }
                startSubscription = firebase
                    .app()
                    .functions("europe-west3")
                    .httpsCallable("startSubscription");
                startSubscription({ price_id: id, price_label: label, price_type: type })
                    .then(function (result) {
                    setLoading(false);
                    history.push("/onboarding/3");
                })
                    .catch(function (err) {
                    console.log(err);
                });
                return [2 /*return*/];
            });
        });
    }
    var showPayment = function (version) {
        setPayMethodeManagementShown(true);
        setPayMethodeManagementVersion(version);
    };
    var upgradePlan = function (plan) {
        setLoading(true);
        var changeSubscription = firebase
            .app()
            .functions("europe-west3")
            .httpsCallable("changeSubscription");
        changeSubscription({
            price_id: plan.id,
            price_label: plan.label,
            price_type: plan.type,
        })
            .then(function (result) {
            toaster.success("Dein Plan wurde erfolgreich geändert");
            setLoading(false);
        })
            .catch(function (err) {
            toaster.warning("Dein Plan konnte nicht geändert werden!");
            setLoading(false);
        });
    };
    return (_jsxs(Pane, __assign({ style: {
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "100%",
            padding: "2rem",
            alignItems: "center",
            justifyContent: "space-around",
            background: "linear-gradient(-3deg, rgba(73,190,239,1) 0%, rgba(73,190,239,1) 30%, #E4EBF5 30%, rgba(255,255,255,1) 100%)",
        } }, { children: [_jsxs(Pane, __assign({ style: {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "0.5rem",
                    marginTop: "2rem",
                } }, { children: [_jsx(Heading, __assign({ size: 800 }, { children: "W\u00E4hle deinen Plan" })), _jsx(Text, { children: "apomap bietet allen Apotheken passende Pl\u00E4ne an. Schau dich um und lege direkt los" }), _jsx(Group, __assign({ size: "medium", style: {
                            border: "1px solid gray",
                            borderRadius: "14px",
                            padding: "0.2rem",
                            backgroundColor: "#2D3B5180",
                        } }, { children: subscriptionModel.map(function (_a) {
                            var label = _a.label, value = _a.value;
                            return (_jsx(Button, __assign({ isActive: selectedSubscriptionPlan === value, onClick: function () { return setSelectedSubscriptionPlan(value); }, appearance: "minimal", border: "none", borderRadius: "12px" }, { children: label }), label));
                        }) }))] })), _jsxs(Pane, __assign({ style: {
                    display: "flex",
                    height: "95%",
                    alignItems: "center",
                    justifyContent: "space-around",
                    gap: "1.5rem",
                    marginTop: "1rem",
                } }, { children: [controls && (_jsxs(_Fragment, { children: [_jsx(Link, __assign({ style: {
                                    position: "absolute",
                                    left: "35px",
                                    top: "35px",
                                    textDecoration: "none",
                                }, to: "/onboarding/1" }, { children: _jsxs(Pane, __assign({ display: "flex", flexDirection: "row", alignItems: "center" }, { children: [_jsx(ChevronLeftIcon, {}), _jsx(Paragraph, { children: "Zur\u00FCck" })] })) })), isUser.subscriptionId && (_jsx(Link, __assign({ style: {
                                    position: "absolute",
                                    right: "35px",
                                    top: "35px",
                                    textDecoration: "none",
                                }, to: "/onboarding/3" }, { children: _jsxs(Pane, __assign({ display: "flex", flexDirection: "row", alignItems: "center" }, { children: [_jsx(Paragraph, { children: "Weiter" }), _jsx(ChevronRightIcon, {})] })) })))] })), _jsx(PlanCard, { planName: "apomap Starter", hasTestBadge: selectedSubscriptionPlan === "monthly", planDescription: "F\u00FCr Apotheken, die ihre Organisation verbessern wollen inkl. apomap Grundfunktionen.", isSelected: (isPlanData === null || isPlanData === void 0 ? void 0 : isPlanData.subscriptionLabel) === "apomap-starter" &&
                            (isPlanData === null || isPlanData === void 0 ? void 0 : isPlanData.subscriptionPeriod) === selectedSubscriptionPlan, isLoading: isLoading, selectedSubscriptionPlan: selectedSubscriptionPlan, price: selectedSubscriptionPlan === "annual" ? 468 : 39, color: "#2D3B5180", features: __spreadArray([
                            "max. 3 Fahrer*innen",
                            "250 optimierte Aufträge pro Monat inklusive*",
                            "Dokumentation aller Touren 7 Tage rückwirkend",
                            "CSV-Import",
                            "Schnittstellen-Funktion (1 Schnittstelle inklusive)",
                            "Fahrer-App",
                            "Live-Chat Support"
                        ], (selectedSubscriptionPlan !== "annual" && [
                            "Monatlich kündbar​",
                        ]), true), onClick: function () { return showPayment(0); } }), _jsx(PlanCard, { hasTestBadge: selectedSubscriptionPlan === "monthly", planName: "apomap Plus", planDescription: "F\u00FCr Apotheken, die ihre Lieferauftr\u00E4ge besser managen wollen und dabei mehr Funktionen ben\u00F6tigen.", isSelected: (isPlanData === null || isPlanData === void 0 ? void 0 : isPlanData.subscriptionLabel) === "apomap-plus" &&
                            (isPlanData === null || isPlanData === void 0 ? void 0 : isPlanData.subscriptionPeriod) === selectedSubscriptionPlan, selectedSubscriptionPlan: selectedSubscriptionPlan, price: selectedSubscriptionPlan === "annual" ? 1069 : 99, color: "#49BEEF", features: __spreadArray([
                            "max. 8 Fahrer*innen",
                            "1.000 optimierte Aufträge pro Monat inklusive*",
                            "Intelligente Dokumentation + Suchfunktion",
                            "CSV-Import",
                            "Schnittstellen-Funktion",
                            "Fahrer-App",
                            "Favoritenliste",
                            "Statistiken",
                            "manuelles Bearbeiten des Auftragsstatus",
                            "Live-Chat Support",
                            "Telefon Support"
                        ], (selectedSubscriptionPlan !== "annual" && [
                            "Monatlich kündbar​",
                        ]), true), onClick: function () { return showPayment(1); } }), _jsx(PlanCard, { hasTestBadge: selectedSubscriptionPlan === "monthly", planName: "apomap Enterprise", planDescription: "F\u00FCr Apotheken, die alle Features nutzen wollen und mehrere Standorte vernetzen wollen.", isSelected: (isPlanData === null || isPlanData === void 0 ? void 0 : isPlanData.subscriptionLabel) === "apomap-pro" &&
                            (isPlanData === null || isPlanData === void 0 ? void 0 : isPlanData.subscriptionPeriod) === selectedSubscriptionPlan, selectedSubscriptionPlan: selectedSubscriptionPlan, price: selectedSubscriptionPlan === "annual" ? 1609 : 149, color: "#2D3B51", features: __spreadArray([
                            "unlimitierte Fahrer*innen",
                            "10.000 optimierte Aufträge pro Monat inklusive*",
                            "Intelligente Dokumentation + Suchfunktion",
                            "Scan-Modus",
                            "CSV-Import",
                            "Schnittstellen-Funktion",
                            "erweiterte Statistiken",
                            "Connect Funktion (Nebenstandorte für 49€ /monatlich zubuchbar)",
                            "Favoritenliste",
                            "Fahrer-Chat",
                            "Live-Chat Support",
                            "Telefon Support"
                        ], (selectedSubscriptionPlan !== "annual" && [
                            "Monatlich kündbar​",
                        ]), true), onClick: function () { return showPayment(2); } })] })), _jsx(Paragraph, __assign({ size: 400, marginTop: 20, marginBottom: 20, paddingLeft: 60, paddingRight: 60 }, { children: "*Preise zzgl. MwSt. | Jeder weitere optimierte Auftrag \u00FCber dem mtl. Kontingent wird mit 0,20 \u20AC berechnet Alle Abonnements werden in Euro berechnet. Die Umrechnungskurse k\u00F6nnen variieren." }))] })));
}
