var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, CrossIcon, Dialog, Heading, Pane, Paragraph, ThemeProvider, toaster, } from "evergreen-ui";
import { useMemo } from "react";
import { usePlan } from "../../hooks/usePlan";
import theme from "../../theme";
import BlueBar from "../BlueBar";
import { useAddons } from "./utils/use-addons";
export var AddonCancelModal = function (_a) {
    var plan = usePlan();
    var _b = useAddons(), selectedAddon = _b.selectedAddon, setAddon = _b.setAddon, loading = _b.loading, cancel = _b.cancel, isBooked = _b.isBooked, shouldManage = _b.shouldManage;
    var bookedAddon = useMemo(function () {
        return plan && (selectedAddon === null || selectedAddon === void 0 ? void 0 : selectedAddon.default_price) && plan.addons
            ? plan === null || plan === void 0 ? void 0 : plan.addons[selectedAddon === null || selectedAddon === void 0 ? void 0 : selectedAddon.default_price]
            : null;
    }, [plan, selectedAddon === null || selectedAddon === void 0 ? void 0 : selectedAddon.default_price]);
    var open = useMemo(function () { return selectedAddon !== null && isBooked && !shouldManage; }, [isBooked, selectedAddon, shouldManage]);
    if (!selectedAddon || !bookedAddon)
        return null;
    return (
    /* @ts-ignore */
    _jsx(ThemeProvider, __assign({ value: theme }, { children: _jsxs(Dialog, __assign({ isShown: open, cancelLabel: "Abbrechen", confirmLabel: "Hinzuf\u00FCgen", shouldCloseOnOverlayClick: false, shouldCloseOnEscapePress: false, hasHeader: false, hasFooter: false, contentContainerProps: {
                className: "dialog-content-container",
                padding: 0,
            }, containerProps: { className: "square-corners" }, width: "80vw" }, { children: [_jsx(BlueBar, {}), _jsx(CrossIcon, { onClick: function () { return setAddon(null); }, style: {
                        position: "absolute",
                        right: 20,
                        top: 20,
                        cursor: "pointer",
                    } }), _jsxs(Pane, __assign({ overflow: "hidden", height: "65vh", display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "flex-start" }, { children: [_jsxs(Pane, __assign({ width: "50%", padding: 20 }, { children: [_jsxs(Heading, __assign({ size: 800, marginBottom: 20 }, { children: ["K\u00DCNDIGUNG DEINES MODULES", _jsx("br", {}), " ", selectedAddon.name.toUpperCase()] })), _jsxs(Pane, __assign({ display: "flex", flexDirection: "column", justifyContent: "center", width: "100%" }, { children: [_jsx(Heading, __assign({ size: 600, marginBottom: 5, color: "#2D3B51" }, { children: "Beschreibung" })), _jsxs(Paragraph, { children: ["Schade, dass du deinen Temperatur Tracker k\u00FCndigst. Dein Abonnementzeitraum f\u00FCr diesen Tracker l\u00E4uft noch bis zum Datum einf\u00FCgen bis zum Ende des Abos vom Trackers* ", _jsx("br", {}), "Bitte sende den Tracker nach Ablauf deine Abos an folgende Adresse:"] })] }))] })), _jsx(Pane, __assign({ display: "flex", height: "90%", flexDirection: "column", justifyContent: "flex-end", width: "50%", marginTop: 48, padding: 20 }, { children: _jsx(Button, __assign({ width: "100%", size: "large", appearance: "primary", intent: "success", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0: return [4 /*yield*/, cancel({
                                                    subscriptionId: bookedAddon === null || bookedAddon === void 0 ? void 0 : bookedAddon.subscriptionId,
                                                })
                                                    .then(function () {
                                                    toaster.success("Erfolgreich gekündigt");
                                                    setAddon(null);
                                                })
                                                    .catch(function (e) { return console.log(e); })];
                                            case 1: return [2 /*return*/, _a.sent()];
                                        }
                                    });
                                }); }, isLoading: loading }, { children: "JETZT K\u00DCNDIGEN" })) }))] }))] })) })));
};
