var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useContext, useEffect } from "react";
import { SelectMenu, Button } from "evergreen-ui";
import { LocationContext } from "../contexts/LocationContext";
export default function LocationSelectMenu(props) {
    var _a;
    //For AddressPicker
    var _b = useState([]), areLocations = _b[0], setLocations = _b[1];
    var locations = useContext(LocationContext).locations;
    var areBusinessLocations = locations[0], setBusinessLocations = locations[1];
    useEffect(function () {
        var all = [];
        areBusinessLocations
            .filter(function (_a) {
            var archived = _a.archived;
            return !archived;
        })
            .map(function (location) {
            var obj = { label: location.name, value: location.id };
            all.push(obj);
        });
        setLocations(all);
    }, [areBusinessLocations]);
    return (_jsx(_Fragment, { children: _jsx(SelectMenu, __assign({ title: "Standortliste", options: areLocations, selected: props.isSelected, onSelect: function (item) { return props.setSelected(item); } }, { children: _jsx(Button, __assign({ style: { overflow: "hidden" } }, { children: ((_a = props.isSelected) === null || _a === void 0 ? void 0 : _a.label)
                    ? props.isSelected.label
                    : "Standort wählen.." })) })) }));
}
