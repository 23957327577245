var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext, useState } from "react";
import { Pane, Button, toaster, Heading, TextInputField, Paragraph, } from "evergreen-ui";
import firebase from "../config";
import "firebase/auth";
import logo from "../assets/images/logo.png";
import { useHistory } from "react-router-dom";
import { sendNewCode } from "../utility/sendNewCode";
import { UserContext } from "../contexts/UserContext";
import endpoint from "../utility/endpoint";
export function VerificationWindow(_a) {
    var _b, _c;
    var setShowWelcomeVideo = _a.setShowWelcomeVideo;
    var _d = useState(), isCode = _d[0], setCode = _d[1];
    var _e = useState(false), isLoading = _e[0], setLoading = _e[1];
    var _f = useContext(UserContext), user = _f.user, ready = _f.ready;
    var isUser = user[0];
    var history = useHistory();
    function verifyEmail(email, token, uid) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (res, rej) {
                        var myHeaders = new Headers();
                        myHeaders.append("Content-Type", "application/json");
                        var raw = JSON.stringify({
                            email_address: email,
                            token: parseInt(token),
                            uid: uid,
                        });
                        var requestOptions = {
                            method: "POST",
                            headers: myHeaders,
                            body: raw,
                            redirect: "follow",
                        };
                        fetch("https://".concat(endpoint(), ".cloudfunctions.net/api/verifyEmail"), requestOptions)
                            .then(function (result) {
                            res();
                            return;
                        })
                            .catch(function (err) { return rej(err); });
                    })];
            });
        });
    }
    function verifyAccount() {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                setLoading(true);
                verifyEmail(firebase.auth().currentUser.email, isCode, firebase.auth().currentUser.uid)
                    .then(function () {
                    toaster.success("Deine E-Mail Adresse ist bestätigt worden!");
                    setLoading(false);
                    setShowWelcomeVideo(true);
                    history.push("/dashboard");
                    // window.location.reload();
                })
                    .catch(function (err) {
                    console.log(err);
                    toaster.danger("Der angegebenen Token ist falsch!");
                    setLoading(false);
                });
                return [2 /*return*/];
            });
        });
    }
    function setCodeInField(value) {
        if (value.length > 4) {
            toaster.notify("Der Code ist 4 Ziffern lang!");
        }
        else {
            setCode(value);
        }
    }
    var sendTokenMail = function () {
        var _a;
        sendNewCode(firebase.auth().currentUser.email, (_a = isUser.firstName) !== null && _a !== void 0 ? _a : "apomap Kunde")
            .then(function () { return toaster.success("E-Mail wurde erneute gesendet!"); })
            .catch(function (err) { return toaster.warning("Ein Fehler ist unterlaufen"); });
    };
    return (_jsxs(Pane, __assign({ elevation: 2, style: {
            height: 400,
            padding: 20,
            width: 300,
            borderRadius: 15,
            boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
        }, backgroundColor: "white" }, { children: [_jsx("img", { src: logo, style: { height: 24, marginBottom: 25 }, alt: "apomap-logo" }), _jsx(Heading, __assign({ className: "headline", marginBottom: 10 }, { children: "Willkommen bei apomap" })), _jsx(Paragraph, __assign({ className: "bodytext", marginBottom: 10 }, { children: "Bitte geben Sie den 4-stelligen Code ein, den sie auf ihrer Email-Adresse erhalten haben" })), _jsx(TextInputField, { label: "Aktivierungscode", hint: "4 Ziffern", spellCheck: false, value: isCode, onChange: function (e) { return setCodeInField(e.target.value); } }), _jsx(Button, __assign({ isLoading: isLoading, isDisabled: (isCode === null || isCode === void 0 ? void 0 : isCode.length) !== 4, onClick: function () { return verifyAccount(); } }, { children: "E-Mail best\u00E4tigen" })), _jsxs(Button, __assign({ marginTop: 8, isLoading: isLoading, isDisabled: (isCode === null || isCode === void 0 ? void 0 : isCode.length) !== 4, onClick: function () { return sendTokenMail(); } }, { children: ["An ", (_c = (_b = firebase === null || firebase === void 0 ? void 0 : firebase.auth()) === null || _b === void 0 ? void 0 : _b.currentUser) === null || _c === void 0 ? void 0 : _c.email, " senden"] }))] })));
}
export default function Verification() {
    return (_jsx(Pane, __assign({ style: {
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            background: "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(73,190,239,0.2) 100%)",
        } }, { children: _jsx(VerificationWindow, {}) })));
}
