var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, ChevronLeftIcon, Heading, Pane, Paragraph, ThemeProvider, } from "evergreen-ui";
import { useHistory } from "react-router-dom";
import NavigatorBar from "../../container/NavigatorBar";
import theme from "../../theme";
import { HealviConfig } from "./Healvi/HealviConfig";
import { SuperChatConfig } from "./SuperChat/SuperChatConfig";
export var ChatIntegrations = function (_a) {
    // @ts-ignore
    var history = useHistory();
    return (
    // @ts-ignore
    _jsx(ThemeProvider, __assign({ value: theme }, { children: _jsxs(Pane, __assign({ style: {
                display: "flex",
                flexDirection: "row",
                width: "100%",
                background: "linear-gradient(-3deg,#E4EBF5 0%, rgba(255,255,255,1) 100%)",
            } }, { children: [_jsx(NavigatorBar, {}), _jsx(Pane, __assign({ style: {
                        padding: 25,
                        margin: 25,
                        width: "100vw",
                        overflow: "scroll",
                    } }, { children: _jsxs(Pane, __assign({ maxWidth: "50vw" }, { children: [_jsx(Button, __assign({ onClick: function () { return history.push("/routing"); }, marginY: 8, marginRight: 12, iconBefore: ChevronLeftIcon }, { children: "Zur\u00FCck" })), _jsx(Heading, __assign({ size: 900 }, { children: "Chat Integrations" })), _jsx(Pane, __assign({ display: "flex", flexDirection: "row", alignItems: "center" }, { children: _jsx(Heading, __assign({ marginY: 12 }, { children: "Superchat" })) })), _jsx(Paragraph, __assign({ marginBottom: 12 }, { children: "Mit der Superchat Integration, kannst du den Sendungsnachverfolgungslink per Whatsapp an deine Kunden versenden. F\u00FCge deinen API-Key hinzu, um die Integration zu starten." })), _jsx(SuperChatConfig, {}), _jsx(Pane, { width: "100%", height: 1, backgroundColor: "grey", borderRadius: 2, marginY: 12 }), _jsx(Pane, __assign({ display: "flex", flexDirection: "row", alignItems: "center" }, { children: _jsx(Heading, __assign({ marginY: 12 }, { children: "Healvi" })) })), _jsx(Paragraph, __assign({ marginBottom: 12 }, { children: "Im Rahmen der eigens Apothekengruppe k\u00F6nnen Sie ihrer Whatsapp Integration mit apomap verbinden." })), _jsx(HealviConfig, {})] })) }))] })) })));
};
