var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AnnotationIcon, Badge, BookmarkIcon, Checkbox, ChevronDownIcon, Combobox, CommentIcon, DuplicateIcon, EyeOpenIcon, Icon, InfoSignIcon, Pane, Paragraph, RefreshIcon, ShopIcon, SnowflakeIcon, Table, Text, toaster, Tooltip, } from "evergreen-ui";
import "firebase/auth";
import "firebase/firestore";
import React, { memo, useContext, useEffect, useImperativeHandle, useMemo, useState, } from "react";
import LocationLabel from "../components/LocationLabel";
import TaskEditButton from "../components/TaskEditButton";
import firebase from "../config";
import { DriverContext } from "../contexts/DriverContext";
import { LayoutContext } from "../contexts/LayoutContext";
import { MapContext } from "../contexts/MapContext";
import { TaskBarAlertContext } from "../contexts/TaskBarAlertContext";
import { TaskContext } from "../contexts/TaskContext";
import { TaskEditContext } from "../contexts/TaskEditContext";
import { UserContext } from "../contexts/UserContext";
import useStore from "../store/store";
import "../theme/styles.css";
import addMarker from "../utility/addMarker";
import { centerMapToCoords } from "../utility/centerMapToCoords";
import { toDDMMYYYY } from "../utility/formatDate";
import { getDriverForTask } from "../utility/getDriverForTask";
import getExclamationFromPrio from "../utility/getExclamationFromPrio";
import { normaliseMoneyString } from "../utility/normaliseMoneyString";
import searchLocationsOnGoogle from "../utility/searchLocationsOnGoogle";
import { translation } from "../utility/translateMotionToolsStatus";
import { createId } from "../utility/utility";
import { CornerLabel } from "./Interface/Common/CornerLabel";
import Pin from "./Pin";
import StatusBadge from "./StatusBadge";
import TagSelectMenu from "./TagSelectMenu";
function TaskListElement(_a) {
    var title = _a.title, type = _a.type;
    return (_jsx(Paragraph, __assign({ className: type, style: { marginTop: 2 } }, { children: title })));
}
function TaskColumn(_a) {
    var header = _a.header, body = _a.body;
    return (_jsxs(Pane, __assign({ style: {
            display: "flex",
            flexDirection: "row",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            paddingLeft: 10,
        } }, { children: [_jsx(Pane, __assign({ style: { height: "100%", width: "50%" } }, { children: _jsx(TaskListElement, { title: header, type: "subheadline" }) })), _jsx(Pane, __assign({ style: { height: "100%", width: "50%" } }, { children: _jsx(TaskListElement, { title: body, type: "bodytext" }) }))] })));
}
function Seperator() {
    return (_jsx(Pane, { style: {
            width: "95%",
            height: 2,
            backgroundColor: "#D8DAE4",
            borderRadius: 2,
            marginLeft: "2.5%",
            margin: 2,
        } }));
}
export var TagElement = function (_a) {
    var tags = _a.tags;
    var user = useContext(UserContext).user;
    var isUser = user[0];
    useEffect(function () {
        var _a;
        try {
            if ((_a = isUser === null || isUser === void 0 ? void 0 : isUser.tags) === null || _a === void 0 ? void 0 : _a.length) {
                var missingTags_1 = [];
                tags.map(function (tag) {
                    if (!isUser.tags.includes(tag)) {
                        missingTags_1.push(tag);
                    }
                });
                if (missingTags_1.length > 0) {
                    firebase
                        .firestore()
                        .collection("users")
                        .doc(firebase.auth().currentUser.uid)
                        .update({ tags: __spreadArray(__spreadArray([], isUser.tags, true), missingTags_1, true) });
                }
            }
        }
        catch (error) {
            console.log(error);
        }
    }, [isUser, tags]);
    return (_jsxs(_Fragment, { children: [tags.includes("Kühlware") || tags.includes("2-8°C") ? (_jsx(Tooltip, __assign({ content: "Kühlware" }, { children: _jsx(Icon, { cursor: "pointer", icon: SnowflakeIcon }) }))) : null, _jsx(Tooltip, __assign({ content: tags.join(", ") }, { children: _jsx(BookmarkIcon, { marginLeft: 5, color: "#FA825F", cursor: "pointer" }) }))] }));
};
export var Task = React.forwardRef(function (props, ref) {
    var map = useContext(MapContext).map;
    var mapRef = map[0], setMapRef = map[1];
    var _a = useState(props.open ? true : false), isExpanded = _a[0], setExpanded = _a[1];
    var _b = useState(false), googleLoading = _b[0], setGoogleLoading = _b[1];
    var _c = useState([]), googleAddresses = _c[0], setGoogleAddresses = _c[1];
    var state = useContext(LayoutContext).state;
    var isEditMode = state[0], setEditMode = state[1];
    var _d = useState(false), selected = _d[0], setSelected = _d[1];
    var drivers = useContext(DriverContext).drivers;
    var areDrivers = drivers[0], setDrivers = drivers[1];
    var _e = useState(false), isHighlighted = _e[0], setHighlighted = _e[1];
    var _f = useState(false), isUnknownHighlight = _f[0], setUnknownHighlight = _f[1];
    var _g = useState(false), error = _g[0], setError = _g[1];
    var _h = useContext(TaskEditContext), editTasks = _h.tasks, setEditTasks = _h.setTasks;
    var taskBarAlertContext = useContext(TaskBarAlertContext);
    var taskBarAlertBox = taskBarAlertContext[0], setTaskBarAlertBox = taskBarAlertContext[1];
    var _j = useContext(TaskContext), tours = _j.tours, tasks = _j.tasks;
    var areTours = tours[0], setTours = tours[1];
    useEffect(function () {
        setSelected(editTasks.includes(props.task.task_id));
    }, [editTasks, props.task.task_id]);
    useEffect(function () {
        if (!isEditMode) {
            setEditMode(props.extended ? true : false);
        }
        if (props.task.unclear_location) {
            setTaskBarAlertBox({
                intent: "warning",
                title: "Auftrag mit zu prüfender Adresse eingegangen!",
            });
            showUnknownHighlight();
        }
        else {
            setUnknownHighlight(false);
        }
    }, [
        isEditMode,
        props.extended,
        props.task.unclear_location,
        setEditMode,
        setTaskBarAlertBox,
    ]);
    function shortHighlightOfTask() {
        setHighlighted(true);
        setTimeout(function () {
            setHighlighted(false);
        }, 10000);
    }
    var changeTaskTags = function (value) {
        firebase
            .firestore()
            .collection("tasks")
            .doc(props.task.task_id)
            .update({ task_tags: value });
    };
    function showUnknownHighlight() {
        setUnknownHighlight(true);
    }
    var driverNote = useMemo(function () { var _a, _b; return (_b = (_a = props.task.task_history) === null || _a === void 0 ? void 0 : _a.filter(function (_a) {
        var type = _a.type;
        return type === "note";
    })) !== null && _b !== void 0 ? _b : ""; }, [props.task.task_history]);
    function errorHighlightOfTask() {
        setError(true);
        setTimeout(function () {
            setError(false);
        }, 8000);
    }
    function showTask(task) {
        var marker = addMarker(mapRef, task.task_coords, null, task.task_type, 2, _jsx(Pin, { task: task, activity: { type: task.task_type } }));
        centerMapToCoords(mapRef, task.task_coords);
        shortHighlightOfTask();
        setTimeout(function () {
            marker.remove();
        }, 8000);
    }
    var changeStatusOfTask = function (status, id) {
        var historyObj = { timestamp: new Date() };
        if (status === "prepared") {
            historyObj.type = status;
            historyObj.msg =
                "Der Auftrag wurde von der Apotheke als vorbereitet markiert";
            historyObj.id = createId();
        }
        else {
            historyObj.type = status;
            historyObj.msg =
                "Der Auftrag wurde von der Apotheke manuell von vorbereitet auf nicht zugeteilt gestellt";
            historyObj.id = createId();
        }
        firebase
            .firestore()
            .collection("tasks")
            .doc(id)
            .update(__assign(__assign(__assign({ task_status: status }, ((status === "successfull" || status === "declined") && {
            processed_date: new Date(),
        })), { task_history: firebase.firestore.FieldValue.arrayUnion(historyObj), task_prepared: status === "prepared" }), (status === "attached" && { task_tour: null })))
            .then(function () {
            try {
                firebase
                    .firestore()
                    .collection("tours")
                    .where("tasksToAdd", "array-contains", id)
                    .get()
                    .then(function (snap) { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, Promise.all(snap.forEach(function (doc) {
                                    var data = doc.data();
                                    firebase
                                        .firestore()
                                        .collection("tours")
                                        .doc(data.id)
                                        .update({
                                        tasksToAdd: firebase.firestore.FieldValue.arrayRemove(id),
                                    });
                                }))];
                            case 1:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                }); });
            }
            catch (error) {
                console.error("error", error);
            }
        });
    };
    useImperativeHandle(ref, function () {
        return {
            showId: function () {
                console.log(props.task.task_id);
            },
            highlight: function () {
                errorHighlightOfTask();
            },
            getId: function () {
                return props.task.task_id;
            },
            selectTask: function () {
                setSelected(true);
            },
            deselectTask: function () {
                setSelected(false);
            },
        };
    });
    var changeAddressOfTask = function (newAddress, id, task) { return __awaiter(void 0, void 0, void 0, function () {
        var locations, tranformedLocations;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(newAddress.value === "correct")) return [3 /*break*/, 2];
                    return [4 /*yield*/, firebase.firestore().collection("tasks").doc(id).update({
                            unclear_location: false,
                        })];
                case 1:
                    _a.sent();
                    setUnknownHighlight(false);
                    return [3 /*break*/, 6];
                case 2:
                    if (!(newAddress.value === "google")) return [3 /*break*/, 4];
                    setGoogleLoading(true);
                    return [4 /*yield*/, searchLocationsOnGoogle(task.task_original_address_query)];
                case 3:
                    locations = _a.sent();
                    tranformedLocations = locations.data.map(function (location) { return ({
                        label: location.address,
                        value: location.coords,
                    }); });
                    setGoogleAddresses(tranformedLocations);
                    setGoogleLoading(false);
                    toaster.notify("Prüfen sie bitte nun die vorgeschlagenen Adressen von Google");
                    return [3 /*break*/, 6];
                case 4: return [4 /*yield*/, firebase.firestore().collection("tasks").doc(id).update({
                        task_address: newAddress.label,
                        task_coords: newAddress.value,
                        unclear_location: false,
                    })];
                case 5:
                    _a.sent();
                    toaster.success("Adresse wurde geupdated!");
                    setUnknownHighlight(false);
                    _a.label = 6;
                case 6: return [2 /*return*/];
            }
        });
    }); };
    return (_jsx(TaskRenderer, { task: props.task, isHighlighted: isHighlighted, forceHighlighted: props.forceHighlighted, isExpanded: isExpanded, isUnknownHighlight: isUnknownHighlight, googleLoading: googleLoading, googleAddresses: googleAddresses, changeAddressOfTask: changeAddressOfTask, changeTaskTags: changeTaskTags, areTours: areTours, areDrivers: areDrivers, error: error, isEditMode: isEditMode, editMode: props.editMode, selected: selected, setEditTasks: setEditTasks, driverNote: driverNote, changeStatusOfTask: changeStatusOfTask, setExpanded: setExpanded, light: props.light, select: props.select, onCheck: props.onCheck, showTask: showTask, checked: props.checked, parentHandelsCheckState: props.parentHandelsCheckState, selectedDate: props.selectedDate, plan: props.plan, locations: props.locations, isExpandedView: props.isExpandedView, setSelectedTasks: props.setSelectedTasks }));
});
var TaskRenderer = memo(function (_a) {
    var _b;
    var task = _a.task, isHighlighted = _a.isHighlighted, forceHighlighted = _a.forceHighlighted, isExpanded = _a.isExpanded, isUnknownHighlight = _a.isUnknownHighlight, error = _a.error, changeAddressOfTask = _a.changeAddressOfTask, areTours = _a.areTours, googleLoading = _a.googleLoading, googleAddresses = _a.googleAddresses, areDrivers = _a.areDrivers, isEditMode = _a.isEditMode, editMode = _a.editMode, selected = _a.selected, setEditTasks = _a.setEditTasks, driverNote = _a.driverNote, changeStatusOfTask = _a.changeStatusOfTask, setExpanded = _a.setExpanded, light = _a.light, select = _a.select, onCheck = _a.onCheck, showTask = _a.showTask, checked = _a.checked, parentHandelsCheckState = _a.parentHandelsCheckState, changeTaskTags = _a.changeTaskTags, selectedDate = _a.selectedDate, plan = _a.plan, locations = _a.locations, isExpandedView = _a.isExpandedView, setSelectedTasks = _a.setSelectedTasks;
    var _c = useContext(TaskContext), tours = _c.tours, tasks = _c.tasks;
    var areTasks = tasks[0], setTasks = tasks[1];
    // * Global state handling Drag and Drop
    var _d = useStore(), draggedTask = _d.draggedTask, setDraggedTask = _d.setDraggedTask, isItemDragged = _d.isItemDragged, setIsItemDragged = _d.setIsItemDragged;
    // * EVENT HANDLERS
    var handleTask = function (taskId) {
        setEditTasks(function (state) {
            var newState = __spreadArray([], state, true);
            if (newState.includes(taskId)) {
                var index = newState.indexOf(taskId);
                newState.splice(index, 1);
            }
            else {
                newState.push(taskId);
            }
            return newState;
        });
    };
    var areGroupedTasks = useMemo(function () {
        if (task.collectiveTask) {
            var groupedTasksId_1 = task.taskIds;
            var groupedTasks = areTasks.filter(function (t) {
                return groupedTasksId_1.some(function (id) { return t.task_id === id; });
            });
            return groupedTasks;
        }
    }, [areTasks, task.collectiveTask, task.taskIds]);
    var toursWithAttachedTasks = useMemo(function () {
        return areTours.filter(function (tour) { return tour.tasksToAdd && tour.tasksToAdd.length > 0; });
    }, [areTours]);
    var getDriverForAttachedTask = function (taskId) {
        var attachedTour = toursWithAttachedTasks === null || toursWithAttachedTasks === void 0 ? void 0 : toursWithAttachedTasks.find(function (tour) {
            return tour.tasksToAdd.includes(taskId);
        });
        var assignedDriver = attachedTour === null || attachedTour === void 0 ? void 0 : attachedTour.driver;
        if (assignedDriver === "" || assignedDriver === null) {
            return "".concat(attachedTour.name, ", ").concat(attachedTour.startTime);
        }
        else {
            var relevantDriver = areDrivers === null || areDrivers === void 0 ? void 0 : areDrivers.find(function (driver) { return driver.id === assignedDriver; });
            return "".concat(relevantDriver === null || relevantDriver === void 0 ? void 0 : relevantDriver.firstName, " ").concat(relevantDriver === null || relevantDriver === void 0 ? void 0 : relevantDriver.lastName, ", ").concat(attachedTour === null || attachedTour === void 0 ? void 0 : attachedTour.startTime);
        }
    };
    var isDraggable = function (task) {
        var canBeDragged = false;
        if (task.task_status === "unassigned" ||
            task.task_status === "prepared") {
            canBeDragged = true;
        }
        if (isEditMode || isExpanded) {
            canBeDragged = false;
        }
        return canBeDragged;
    };
    // #######################
    var glsTaskStatus = useMemo(function () {
        if (task.gls && task.task_status === "unassigned") {
            return "an GLS übertragen";
        }
        if (task.gls && task.task_status === "successfull") {
            return "Ausgeliefert durch GLS";
        }
        if (task.gls && task.task_status === "ontheway") {
            return "auf dem Weg zum Kunden";
        }
        else {
            return null;
        }
    }, [task.gls, task.task_status]);
    var binaryString = useMemo(function () {
        if (task.gls) {
            return task.gls.label;
        }
        else {
            return null;
        }
    }, [task.gls]);
    var downloadPdf = function () {
        var decodedString = Buffer.from(binaryString, "base64");
        var blob = new Blob([decodedString], { type: "application/pdf" });
        var downloadUrl = URL.createObjectURL(blob);
        var link = document.createElement("a");
        link.href = downloadUrl;
        link.download = "label.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    // #######################
    var driverName = useMemo(function () {
        var _a, _b;
        return (task === null || task === void 0 ? void 0 : task.task_status) === "attached"
            ? getDriverForAttachedTask(task.task_id)
            : ((_a = getDriverForTask(areTours, areDrivers, task)) === null || _a === void 0 ? void 0 : _a.lastName) +
                ", " +
                ((_b = getDriverForTask(areTours, areDrivers, task)) === null || _b === void 0 ? void 0 : _b.firstName);
    }, [areDrivers, areTours, getDriverForAttachedTask, task]);
    function CompressedView() {
        var _a, _b, _c, _d, _e, _f;
        var _g = useState(null), driver = _g[0], setDriver = _g[1];
        useEffect(function () {
            setDriver(getDriverForTask(areTours, areDrivers, task));
        }, []);
        var retryCount = (task === null || task === void 0 ? void 0 : task.task_history) &&
            (task === null || task === void 0 ? void 0 : task.task_history.reduce(function (accu, curr) {
                if (curr.type === "declined") {
                    return accu + 1;
                }
                return accu;
            }, 0));
        if (!isEditMode && !isExpandedView) {
            return (_jsxs(_Fragment, { children: [_jsx(Table.TextCell, { children: _jsxs(Pane, __assign({ style: {
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            } }, { children: [_jsx(CornerLabel, { locationId: task.task_origin, plan: plan, locations: locations }), editMode && (_jsx(Checkbox, { checked: selected, marginLeft: 5, marginRight: 5, onClick: function () {
                                        if (task.unclear_location && !selected) {
                                            toaster.warning("Bitte lösen Sie das Addressenproblem, um Zustellungsfehler zu vermeiden!");
                                        }
                                        handleTask(task.task_id);
                                    } })), _jsxs("div", __assign({ style: {
                                        display: "inline-flex",
                                        justifyItems: "between",
                                        alignItems: "center",
                                        width: "100%",
                                        position: "relative",
                                    } }, { children: [_jsx(Paragraph, __assign({ style: {
                                                fontFamily: task.customer_uid ? "bold" : "regular",
                                                flex: 1,
                                                //The following is needed to give icons priority, so the text does not push them to the right
                                                minWidth: 0,
                                                textOverflow: "ellipsis",
                                                paddingRight: 5,
                                                overflow: "hidden",
                                            }, className: "bodytext" }, { children: task.customer_name })), _jsxs("div", __assign({ style: { display: "flex" } }, { children: [((_a = task.task_tags) === null || _a === void 0 ? void 0 : _a.length) > 0 && (_jsx(TagElement, { tags: task.task_tags, cursor: "pointer" })), retryCount > 0 && (_jsx(Tooltip, __assign({ content: retryCount + "x nicht angetroffen" }, { children: _jsx(RefreshIcon, { marginLeft: 10, cursor: "pointer" }) }))), task.collectiveTask && (_jsx(Tooltip, __assign({ content: "Sammelauftrag: Klappe den Sammelauftrag auf, um die untergeordneten Auftr\u00E4ge zu sehen" }, { children: _jsx(DuplicateIcon, { marginLeft: 10, cursor: "pointer" }) }))), task.shopApo_id && (_jsx(Tooltip, __assign({ content: "Auftrag von Shop Apotheke" }, { children: _jsx(ShopIcon, { marginLeft: 10, cursor: "pointer" }) }))), task.task_note !== "" &&
                                                    task.task_note !== null &&
                                                    task.task_note !== " " ? (_jsx(Tooltip, __assign({ content: task.task_note }, { children: _jsx(CommentIcon, { marginLeft: 10, cursor: "pointer" }) }))) : null, driverNote.length > 0 && (_jsx(Tooltip, __assign({ content: (_b = driverNote[0]) === null || _b === void 0 ? void 0 : _b.msg }, { children: _jsx(AnnotationIcon, { marginLeft: 10, cursor: "pointer" }) })))] }))] }))] })) }), _jsx(Table.TextCell, { children: _jsxs(Pane, __assign({ style: {
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                            } }, { children: [_jsx(StatusBadge, { status: task.gls ? glsTaskStatus : task.task_status, onChange: task.gls ? null : changeStatusOfTask, id: task.task_id, driver: task.task_status === "attached"
                                        ? getDriverForAttachedTask(task.task_id)
                                        : driver }), _jsxs(Pane, __assign({ style: {
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    } }, { children: [_jsx(ChevronDownIcon, { style: {
                                                transform: isExpanded ? "rotate(180deg)" : "",
                                                transition: "0.3s ease",
                                                cursor: "pointer",
                                            }, marginLeft: 5, onClick: function () { return setExpanded(!isExpanded); } }), _jsx(EyeOpenIcon, { style: {
                                                cursor: "pointer",
                                            }, onClick: function () { return showTask(task); }, marginLeft: 5 }), !light && (_jsx(TaskEditButton, { disabled: task.task_status === "optimizing", task: task }))] }))] })) })] }));
        }
        else {
            return (_jsxs(_Fragment, { children: [_jsx(Table.TextCell, { children: _jsxs(Pane, __assign({ style: {
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            } }, { children: [_jsx(CornerLabel, { locationId: task.task_origin, plan: plan, locations: locations }), select && (_jsx(Checkbox, { checked: (parentHandelsCheckState !== null && parentHandelsCheckState !== void 0 ? parentHandelsCheckState : false) ? checked : selected, marginLeft: 5, marginRight: 5, onClick: function () {
                                        if (task.unclear_location && !selected) {
                                            toaster.warning("Bitte lösen Sie das Addressenproblem, um Zustellungsfehler zu vermeiden!");
                                        }
                                        handleTask(task.task_id);
                                    } })), editMode &&
                                    (task.task_status !== "attached" ? (_jsx(Checkbox, { checked: selected, marginLeft: 5, marginRight: 5, onClick: function () {
                                            if (task.unclear_location && !selected) {
                                                toaster.warning("Bitte lösen Sie das Addressenproblem, um Zustellungsfehler zu vermeiden!");
                                            }
                                            handleTask(task.task_id);
                                        } })) : (_jsx(Tooltip, __assign({ content: "Der Auftrag den du versuchst zu l\u00F6schen ist bereits einer Tour angehangen. Angehangene Auftr\u00E4ge k\u00F6nnen nicht gel\u00F6scht werden. Entziehen ihn zuerst der Tour." }, { children: _jsx(Checkbox, { disabled: true, marginLeft: 5, marginRight: 5 }) })))), _jsxs("div", __assign({ style: {
                                        display: "inline-flex",
                                        justifyItems: "between",
                                        alignItems: "center",
                                        width: "100%",
                                    } }, { children: [_jsx(Paragraph, __assign({ style: {
                                                fontFamily: task.customer_uid ? "bold" : "regular",
                                                flex: 1,
                                                //The following is needed to give icons priority, so the text does not push them to the right
                                                minWidth: 0,
                                                textOverflow: "ellipsis",
                                                paddingRight: 5,
                                                overflow: "hidden",
                                            }, className: "bodytext" }, { children: task.customer_name })), task.collectiveTask && (_jsx(Tooltip, __assign({ content: "Sammelauftrag: Klappe den Sammelauftrag auf, um die untergeordneten Auftr\u00E4ge zu sehen" }, { children: _jsx(DuplicateIcon, { marginLeft: 10, cursor: "pointer" }) }))), _jsxs("div", __assign({ style: { display: "flex" } }, { children: [((_c = task.task_tags) === null || _c === void 0 ? void 0 : _c.length) > 0 && (_jsx(TagElement, { tags: task.task_tags, cursor: "pointer" })), retryCount > 0 && (_jsx(Tooltip, __assign({ content: retryCount + "x nicht angetroffen" }, { children: _jsx(RefreshIcon, { marginLeft: 10 }) }))), task.task_note !== "" &&
                                                    task.task_note !== null &&
                                                    task.task_note !== " " ? (_jsx(Tooltip, __assign({ content: task.task_note }, { children: _jsx(CommentIcon, { marginLeft: 10 }) }))) : null, driverNote.length > 0 && (_jsx(Tooltip, __assign({ content: (_d = driverNote[0]) === null || _d === void 0 ? void 0 : _d.msg }, { children: _jsx(AnnotationIcon, { marginLeft: 10 }) })))] }))] }))] })) }), _jsx(Table.TextCell, { children: _jsx(Pane, __assign({ style: {
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            } }, { children: _jsxs(Paragraph, __assign({ className: "bodytext" }, { children: [(task === null || task === void 0 ? void 0 : task.mtStatus) ? (_jsx(Tooltip, __assign({ content: "Auftrag wurde Cure \u00FCbertragen" }, { children: _jsx(Badge, { children: translation(task.mtStatus) }) }))) : null, (task === null || task === void 0 ? void 0 : task.mtBookingId) && !(task === null || task === void 0 ? void 0 : task.mtStatus) ? (_jsx(Tooltip, __assign({ content: "Auftrag wurde Cure \u00FCbertragen" }, { children: _jsx(Badge, { children: "Bei Cure eingegangen" }) }))) : null, !(task === null || task === void 0 ? void 0 : task.mtStatus) && !task.mtBookingId ? (_jsx(StatusBadge, { status: task.gls ? glsTaskStatus : task.task_status, onChange: changeStatusOfTask, id: task.task_id, driver: getDriverForTask(areTours, areDrivers, task) })) : null] })) })) }), _jsx(Table.TextCell, { children: _jsx(Pane, __assign({ style: {
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            } }, { children: _jsx(Tooltip, __assign({ content: task.task_address }, { children: _jsx(Paragraph, __assign({ style: { cursor: "pointer" }, className: "bodytext" }, { children: task.task_address })) })) })) }), _jsx(Table.TextCell, { children: _jsx(Pane, __assign({ style: {
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            } }, { children: _jsx(Paragraph, __assign({ className: "bodytext" }, { children: _jsx(LocationLabel, { id: task.task_origin }) })) })) }), _jsx(Table.TextCell, { children: _jsxs(Pane, __assign({ style: {
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                            } }, { children: [_jsxs(Paragraph, __assign({ className: "bodytext" }, { children: [(_e = task.task_earliest) !== null && _e !== void 0 ? _e : "__:__", " /", " ", (_f = task.task_latest) !== null && _f !== void 0 ? _f : "__:__"] })), _jsxs(Pane, __assign({ style: {
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    } }, { children: [_jsx(ChevronDownIcon, { style: {
                                                transform: isExpanded ? "rotate(180deg)" : "",
                                                transition: "0.3s ease",
                                            }, marginLeft: 5, onClick: function () { return setExpanded(!isExpanded); } }), !light && (_jsx(TaskEditButton, { disabled: task.task_status === "optimizing", task: task })), !select && (_jsx(EyeOpenIcon, { onClick: function () { return showTask(task); }, marginLeft: 5 }))] }))] })) })] }));
        }
    }
    return (_jsxs(Pane, __assign({ style: {
            display: "flex",
            flexDirection: "column",
            backgroundColor: (forceHighlighted !== null && forceHighlighted !== void 0 ? forceHighlighted : false) || isHighlighted ? "#49beef" : "white",
            transition: "300ms ease",
            // cursor: "move",
        }, onDragStart: function () { return setDraggedTask(task.task_id); } }, { children: [_jsxs("div", __assign({ style: {
                    display: "flex",
                    borderBottom: !isExpanded && "1px solid #E0E1E9",
                    flexDirection: "row",
                    height: 50,
                    boxShadow: isExpanded ? "0px 5px 10px rgba(0,0,0,0.1)" : "",
                    zIndex: 2,
                    transition: "0.3s ease",
                    cursor: isDraggable(task) ? "grab" : "default",
                    backgroundColor: draggedTask === task.task_id ? "lightgrey" : "",
                }, draggable: isDraggable(task), onDragStart: function () {
                    setDraggedTask(task.task_id);
                }, onDragEnd: function () { return setDraggedTask(null); } }, { children: [error && (_jsx("div", { className: "errorBar", style: { width: 5, height: "100%", backgroundColor: "#F66277" } })), isUnknownHighlight && (_jsx(Tooltip, __assign({ content: "Adresse pr\u00FCfen" }, { children: _jsx("div", { className: "unknownLocationBar", style: {
                                width: 5,
                                height: "100%",
                                backgroundColor: "rgb(255, 168, 51)",
                            } }) }))), _jsx(CompressedView, {})] })), isExpanded && (_jsx(Pane, __assign({ style: {
                    width: "100%",
                    maxHeight: isExpanded ? 600 : 0,
                    transition: "0.3s ease-out",
                    overflow: "hidden",
                    backgroundColor: "#FAFBFF",
                } }, { children: _jsxs(Pane, __assign({ style: {
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        height: "100%",
                        padding: 10,
                    } }, { children: [task.collectiveTask && (_jsxs(Pane, __assign({ style: {
                                width: "100%",
                                padding: "0.5rem",
                            } }, { children: [_jsx(Text, __assign({ style: { fontFamily: "bold" } }, { children: "untergeordnete Auftr\u00E4ge" })), _jsx(Seperator, {}), areGroupedTasks.map(function (task) {
                                    return (_jsx(Table.TextCell, { children: _jsxs(Pane, __assign({ style: {
                                                display: "inline-flex",
                                                justifyItems: "between",
                                                alignItems: "center",
                                                width: "100%",
                                                position: "relative",
                                                margin: "0.2rem 0",
                                            } }, { children: [_jsx(Paragraph, __assign({ style: {
                                                        flex: 1,
                                                        minWidth: 0,
                                                        textOverflow: "ellipsis",
                                                        paddingRight: 5,
                                                        overflow: "hidden",
                                                    }, className: "bodytext" }, { children: task.customer_name })), _jsx(Tooltip, __assign({ content: task.task_address }, { children: _jsx(Paragraph, __assign({ style: {
                                                            fontFamily: "regular",
                                                            flex: 1,
                                                            minWidth: 0,
                                                            textOverflow: "ellipsis",
                                                            paddingRight: 5,
                                                            overflow: "hidden",
                                                        }, className: "bodytext" }, { children: task.task_address })) }))] })) }));
                                })] }))), task.collectiveTask && _jsx(Seperator, { marginBottom: 12 }), _jsx(TaskColumn, { header: "Tags", body: _jsx(TagSelectMenu, { value: (_b = task.task_tags) !== null && _b !== void 0 ? _b : [], onChange: changeTaskTags }) }), _jsx(TaskColumn, { header: "Priorit\u00E4t", body: getExclamationFromPrio(task.task_priority) }), _jsx(Tooltip, __assign({ content: task.task_address }, { children: _jsx(TaskColumn, { header: "Adresse", body: task.task_address }) })), isUnknownHighlight && task.task_status === "unassigned" && (_jsxs("div", __assign({ style: {
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "flex-end",
                                width: "100%",
                            } }, { children: [_jsx(Combobox, { openOnFocus: true, style: { marginTop: 5, marginBottom: 5 }, isLoading: googleLoading, onChange: function (selected) {
                                        return changeAddressOfTask(selected, task.task_id, task);
                                    }, width: "100%", items: googleAddresses.length === 0
                                        ? [
                                            {
                                                label: "Adresse ist korrekt ✅",
                                                value: "correct",
                                            },
                                            {
                                                label: "Adresse nicht gefunden? 🔎",
                                                value: "google",
                                            },
                                        ].concat(task.task_alternative_addresses)
                                        : googleAddresses, itemToString: function (item) { return (item ? item.label : ""); }, placeholder: "Alternative Adressen" }), _jsx(Tooltip, __assign({ content: "Übertragene Adresse: " + task.task_original_address_query }, { children: _jsx(InfoSignIcon, { color: "rgb(255, 168, 51)", marginLeft: 10 }) }))] }))), _jsx(TaskColumn, { header: "Zahlbetrag", body: normaliseMoneyString(task.task_open_amount) }), task.customer_phone && (_jsx(TaskColumn, { header: "Telefonnummer", body: task.customer_phone })), task.customer_mobile && (_jsx(TaskColumn, { header: "Mobil", body: task.customer_mobile })), task.no_of_items && (_jsx(TaskColumn, { header: "Anzahl der Produkte", body: task.no_of_items })), _jsx(Seperator, {}), _jsx(TaskColumn, { header: "Apotheke", body: _jsx(LocationLabel, { id: task.task_origin }) }), _jsx(TaskColumn, { header: "Art", body: task.task_type === "delivery" ? "Lieferung" : "Abholung" }), task.task_status !== "unassigned" &&
                            driverName !== "undefined, undefined" && (_jsx(TaskColumn, { header: "Fahrer*in", body: driverName })), _jsx(TaskColumn, { header: "Hinweis", body: task.task_note }), _jsx(Seperator, {}), task.gls && (_jsxs(_Fragment, { children: [_jsx(TaskColumn, { header: "GLS Parcel Nummer", body: task.gls.ParcelNumber }), _jsx(TaskColumn, { header: "Parcel Label", body: _jsx(Paragraph, __assign({ onClick: downloadPdf, style: {
                                            textDecoration: "underline",
                                            color: "#49BEEF",
                                            cursor: "pointer",
                                        } }, { children: "Download" })) }), _jsx(Seperator, {})] })), _jsx(TaskColumn, { header: "ID", body: task.task_id }), _jsx(TaskColumn, { header: "Job-Nr.", body: task.task_job_no ? task.task_job_no : "/" }), task.shopApo_id && (_jsx(TaskColumn, { header: "Shop-Apotheke Link", body: _jsx("a", __assign({ target: "_blank", rel: "noreferrer", href: "https://now.shop-apotheke.com/backoffice/api/merchant/orders/track/".concat(task.task_job_no) }, { children: "Zur Bestellung" })) })), _jsx(TaskColumn, { header: "Erstellt am", body: task.task_created
                                ? toDDMMYYYY(task.task_created.toDate())
                                : "/" }), _jsx(TaskColumn, { header: "Quelle", body: _jsx(Badge, __assign({ color: "blue" }, { children: task.task_source ? task.task_source : "Unbekannt" })) }), _jsx(Seperator, {})] })) })))] }), task.task_id));
}, function (prevProps, nextProps) {
    var _a, _b;
    return (JSON.stringify(prevProps.task) === JSON.stringify(nextProps.task) &&
        prevProps.isHighlighted === nextProps.isHighlighted &&
        prevProps.forceHighlighted === nextProps.forceHighlighted &&
        prevProps.isExpanded === nextProps.isExpanded &&
        prevProps.isUnknownHighlight === nextProps.isUnknownHighlight &&
        prevProps.error === nextProps.error &&
        prevProps.isEditMode === nextProps.isEditMode &&
        prevProps.editMode === nextProps.editMode &&
        prevProps.selected === nextProps.selected &&
        ((_a = prevProps.driverNote) === null || _a === void 0 ? void 0 : _a.length) === ((_b = nextProps.driverNote) === null || _b === void 0 ? void 0 : _b.length) &&
        prevProps.light === nextProps.light &&
        prevProps.select === nextProps.select &&
        prevProps.checked === nextProps.checked &&
        prevProps.areTours === nextProps.areTours &&
        prevProps.googleLoading === nextProps.googleLoading &&
        prevProps.locations === nextProps.locations &&
        prevProps.plan === nextProps.plan &&
        prevProps.isExpandedView === nextProps.isExpandedView);
});
TaskRenderer.displayName = "TaskRenderer";
