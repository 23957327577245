var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import useTaskStats from "../../hooks/useTaskStats";
import React from "react";
import DashboardCard from "./DashboardCard";
var HSEP_STYLE = {
    width: "2px",
    height: "100%",
    backgroundColor: "#ECF0F9"
};
var CountOverviewCard = function () {
    var _a = useTaskStats(), open = _a.open, assigned = _a.assigned, onTheWay = _a.onTheWay, done = _a.done;
    var CountNumber = function (_a) {
        var color = _a.color, number = _a.number, descriptor = _a.descriptor;
        return (_jsxs("div", __assign({ style: {
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center"
            } }, { children: [_jsx("p", __assign({ style: { color: "#" + color, fontWeight: "bold", fontSize: "2.5rem", margin: 0 } }, { children: number !== null && number !== void 0 ? number : "-" })), _jsx("p", __assign({ style: { color: "#2D3B51", margin: 0, marginTop: 7 } }, { children: descriptor }))] })));
    };
    return (_jsx(DashboardCard, __assign({ title: (open + assigned + onTheWay + done) + " Aufträge für Heute", size: 3, onClick: function () { window.location.href = "/tourplanning"; } }, { children: _jsxs("div", __assign({ style: {
                display: "flex",
                flexDirection: "row",
                height: "calc(100% - 50px)",
                paddingTop: 20,
                paddingBottom: 30
            } }, { children: [_jsx(CountNumber, { color: "707070", descriptor: "Offen", number: open }), _jsx("div", { style: HSEP_STYLE }), _jsx(CountNumber, { color: "49BEEF", descriptor: "Zugeteilt", number: assigned }), _jsx("div", { style: HSEP_STYLE }), _jsx(CountNumber, { color: "FFB020", descriptor: "Unterwegs", number: onTheWay }), _jsx("div", { style: HSEP_STYLE }), _jsx(CountNumber, { color: "48B77B", descriptor: "Erledigt", number: done })] })) })));
};
export default CountOverviewCard;
