var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useContext, useEffect } from "react";
import { Pane, Paragraph, Heading, AddIcon, Button, Dialog, EditIcon, IconButton, ChevronLeftIcon, Badge, toaster, TrashIcon, TextInput, ClipboardIcon, Tooltip, InfoSignIcon, CaretUpIcon, CaretDownIcon, CogIcon, } from "evergreen-ui";
import generateApiKey from "generate-api-key";
import NavigatorBar from "../container/NavigatorBar";
import { useHistory } from "react-router-dom";
import firebase from "../config";
import "firebase/auth";
import "firebase/functions";
import "firebase/firestore";
import LocationSelectMenu from "../components/LocationSelectMenu";
import { UserContext } from "../contexts/UserContext";
import { usePlanInfo } from "../utility/use-plan-info";
import { LocationContext } from "../contexts/LocationContext";
import { PermissionSelectBox } from "../components/Tokens/PermissionSelectBox";
import ApoSelect from "../components/ApoSelect";
import ApoFieldLabel from "../components/ApoFieldLabel";
var DefaultPathForSystem = {
    "ADG S3000": "D:\\Database\\Applications\\Botendienst\\ApoTrack",
    adg_s3000: "D:\\Database\\Applications\\Botendienst\\ApoTrack",
    "ADG A3000": "C:\\A3000\\Database\\Botendienst\\CSV",
    adg_a3000: "C:\\A3000\\Database\\Botendienst\\CSV",
    "Awinta Prokas": "S:\\APOMAP",
    awinta_prokas: "S:\\APOMAP",
    "CGM Lauer": "",
    cgm_lauer: "",
    adv_gravis: "",
    anderes: "",
    aposoft: "",
    awinta_infopharm: "",
    awinta_jump: "",
    awinta_one: "",
    cida_nova_plus: "",
    deos_secret: "",
    other: "",
    others: "",
    pharmatechnik: "",
};
var AddDialog = function (_a) {
    var _b, _c, _d, _e, _f;
    var setShown = _a.setShown, _g = _a.isShown, isShown = _g.isShown, token = _g.token;
    var _h = useState((_b = token === null || token === void 0 ? void 0 : token.name) !== null && _b !== void 0 ? _b : null), isApiTokenName = _h[0], setApiTokenName = _h[1];
    var _j = useState((_c = token === null || token === void 0 ? void 0 : token.prefix) !== null && _c !== void 0 ? _c : ""), isApiPrefix = _j[0], setApiPrefix = _j[1];
    var _k = useState((_d = token === null || token === void 0 ? void 0 : token.deletePrefix) !== null && _d !== void 0 ? _d : 0), deletePrefix = _k[0], setDeletePrefix = _k[1];
    var _l = useState((_e = token === null || token === void 0 ? void 0 : token.permissions) !== null && _e !== void 0 ? _e : []), selectedPermissions = _l[0], setSelectedPermissions = _l[1];
    var _m = useState((_f = token === null || token === void 0 ? void 0 : token.location) !== null && _f !== void 0 ? _f : null), isLocation = _m[0], setLocation = _m[1];
    var _o = useState(false), isLoading = _o[0], setLoading = _o[1];
    var _p = useContext(UserContext), user = _p.user, ready = _p.ready;
    var isUser = user[0];
    useEffect(function () { }, [selectedPermissions]);
    function createApiToken() {
        if (!isApiTokenName) {
            toaster.warning("Bitte geben Sie einen Namen an!");
            return;
        }
        if (!isLocation) {
            toaster.warning("Bitte geben Sie den entsprechenden Standort an!");
            return;
        }
        setLoading(true);
        var apiKey = generateApiKey({
            method: "bytes",
            prefix: "apomap",
            length: 24,
        });
        firebase
            .firestore()
            .collection("tokens")
            .doc(apiKey)
            .set(__assign(__assign({ key: apiKey, location: isLocation.value, name: isApiTokenName, owner: isUser.uid, permissions: selectedPermissions }, (isApiPrefix !== "" && { prefix: isApiPrefix })), (deletePrefix > 0 && { deletePrefix: deletePrefix })))
            .then(function () {
            setShown(false);
            setLoading(false);
            toaster.success("API-Token ".concat(isApiTokenName, " wurde erfolgreich erstellt"));
        })
            .catch(function (err) {
            setLoading(false);
            console.log(err);
        });
    }
    var saveChanges = function () {
        if (isApiTokenName.length === 0) {
            toaster.notify("Der Name muss mindestens 1 Zeichen lang sein!");
            return;
        }
        firebase
            .firestore()
            .collection("tokens")
            .doc(token.key)
            .update(__assign(__assign(__assign({ name: isApiTokenName }, (isApiPrefix !== ""
            ? { prefix: isApiPrefix }
            : { prefix: firebase.firestore.FieldValue.delete() })), (deletePrefix > 0
            ? { deletePrefix: deletePrefix }
            : { deletePrefix: firebase.firestore.FieldValue.delete() })), (selectedPermissions.length > 0
            ? { permissions: selectedPermissions }
            : { permissions: [] })))
            .then(function () {
            setShown(false);
            setLoading(false);
            toaster.success("API-Token ".concat(isApiTokenName, " wurde erfolgreich gespeichert"));
        })
            .catch(function (err) {
            setLoading(false);
            console.log(err);
        });
    };
    return (_jsx(Pane, { children: _jsxs(Dialog, __assign({ isShown: isShown, title: "Neuen API Token erstellen", onCloseComplete: function () {
                return setShown(function (curr) { return ({ isShown: false, token: null }); });
            }, onConfirm: function () { return (token ? saveChanges() : createApiToken()); }, confirmLabel: token ? "Speichern" : "Erstellen", isConfirmLoading: isLoading, isCancelDisabled: isLoading, cancelLabel: "Abbrechen", shouldCloseOnOverlayClick: false, shouldCloseOnEscapePress: false }, { children: [_jsx(Paragraph, __assign({ className: "subheadline" }, { children: "Bitte vergeben Sie diesem API-Token einen eindeutigen Namen" })), _jsx(TextInput, { margin: 10, placeholder: "Merkname", onChange: function (e) { return setApiTokenName(e.target.value); }, value: isApiTokenName }), _jsxs(Pane, { children: [_jsx(TextInput, { margin: 10, placeholder: "Prefix", onChange: function (e) { return setApiPrefix(e.target.value); }, value: isApiPrefix }), _jsx(Tooltip, __assign({ content: "Dieser Prefix wird vor jede Jobnummer gestellt, welcher \u00FCber diesen Token ankommt" }, { children: _jsx(InfoSignIcon, {}) }))] }), _jsxs(Pane, { children: [_jsx(TextInput, { margin: 10, disabled: true, placeholder: "0", value: deletePrefix }), _jsx(IconButton, { onClick: function () { return setDeletePrefix(deletePrefix + 1); }, marginRight: 2, icon: CaretUpIcon }), _jsx(IconButton, { onClick: function () {
                                return deletePrefix > 0 && setDeletePrefix(deletePrefix - 1);
                            }, marginRight: 5, icon: CaretDownIcon }), _jsx(Tooltip, __assign({ content: "Die hier angegebene Anzahl, werden der Jobnummer von vorne an Zeichen abgezogen" }, { children: _jsx(InfoSignIcon, {}) }))] }), _jsx(Pane, __assign({ marginLeft: 10, marginBottom: 10 }, { children: _jsx(PermissionSelectBox, { selectedItemsState: selectedPermissions, setSelectedItems: setSelectedPermissions }) })), !token && (_jsx(Pane, __assign({ style: { marginLeft: 10 } }, { children: _jsx(LocationSelectMenu, { isSelected: isLocation, setSelected: setLocation }) })))] })) }));
};
var RemoveDialog = function (_a) {
    var setShown = _a.setShown, _b = _a.isShown, isShown = _b.isShown, token = _b.token;
    var _c = useState(false), isLoading = _c[0], setLoading = _c[1];
    function deleteApiToken() {
        setLoading(true);
        //Could be done safer
        firebase
            .firestore()
            .collection("tokens")
            .doc(token.key)
            .delete()
            .then(function () {
            setShown(false);
            setLoading(false);
            toaster.success("API-Token ".concat(token === null || token === void 0 ? void 0 : token.name, " wurde gel\u00F6scht"));
        })
            .catch(function (err) {
            setLoading(false);
            console.log(err);
        });
    }
    return (_jsx(Pane, { children: _jsx(Dialog, __assign({ isShown: isShown, title: "API Token l\u00F6schen", onCloseComplete: function () { return setShown(false); }, onConfirm: function () { return deleteApiToken(); }, confirmLabel: "Ja, l\u00F6schen!", intent: "danger", isConfirmLoading: isLoading, isCancelDisabled: isLoading, cancelLabel: "Nein", shouldCloseOnOverlayClick: false, shouldCloseOnEscapePress: false }, { children: _jsxs(Paragraph, __assign({ className: "subheadline" }, { children: ["M\u00F6chtest du den API Token \"", token === null || token === void 0 ? void 0 : token.name, "\" wirklich l\u00F6schen?"] })) })) }));
};
var ApiTokenCard = function (_a) {
    var token = _a.token, setAddDialogShown = _a.setAddDialogShown, setDeleteDialogShown = _a.setDeleteDialogShown, edgeNodeAdoptionCode = _a.edgeNodeAdoptionCode, onEdgeConfigEdit = _a.onEdgeConfigEdit;
    return (_jsxs(Pane, __assign({ elevation: 1, style: {
            margin: 10,
            padding: 15,
            justifyContent: "space-between",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "white",
        } }, { children: [_jsxs(Pane, __assign({ className: "horizontal", style: { gap: 5 } }, { children: [_jsx(Paragraph, __assign({ marginRight: 10, className: "subheadline" }, { children: token.name })), edgeNodeAdoptionCode ? (_jsxs(_Fragment, { children: [_jsx(Badge, __assign({ color: "blue" }, { children: "Edge" })), _jsx(Badge, __assign({ color: "green" }, { children: "Aktiv" }))] })) : (_jsx(Badge, __assign({ color: "neutral" }, { children: "Manuell" })))] })), (token === null || token === void 0 ? void 0 : token.prefix) && (_jsxs(Paragraph, __assign({ marginX: 5, className: "bodytext" }, { children: ["Prefix: ", token === null || token === void 0 ? void 0 : token.prefix] }))), _jsxs(Paragraph, __assign({ marginTop: 5, marginBottom: 15, className: "bodytext", style: { display: "flex", flexDirection: "row", alignItems: "center" } }, { children: ["Key:\u00A0", _jsx("code", __assign({ style: { fontFamily: "monospace" } }, { children: token.key })), _jsx(Tooltip, __assign({ content: "Kopieren" }, { children: _jsx(IconButton, { onClick: function () {
                                navigator.clipboard.writeText(token.key);
                                toaster.notify("Token kopiert!");
                            }, icon: ClipboardIcon, marginLeft: 2, appearance: "minimal" }) }))] })), (token === null || token === void 0 ? void 0 : token.deletePrefix) && (_jsxs(Paragraph, __assign({ margin: 5, className: "bodytext" }, { children: ["Gel\u00F6schte Jobnummern von vorne: ", token === null || token === void 0 ? void 0 : token.deletePrefix] }))), _jsxs(Pane, __assign({ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }, { children: [_jsxs("span", { children: [_jsx(Button, __assign({ marginRight: 5, iconBefore: EditIcon, onClick: function () {
                                    return setAddDialogShown({
                                        isShown: true,
                                        token: token,
                                    });
                                } }, { children: "Bearbeiten" })), edgeNodeAdoptionCode && (_jsx(Button, __assign({ marginRight: 5, iconBefore: CogIcon, onClick: function () { return onEdgeConfigEdit(edgeNodeAdoptionCode); } }, { children: "Einstellungen" })))] }), _jsx(Button, __assign({ onClick: function () { return setDeleteDialogShown({ isShown: true, token: token }); }, intent: "danger", iconBefore: TrashIcon }, { children: "L\u00F6schen" }))] }))] })));
};
var EdgeDialog = function (_a) {
    var _b;
    var setData = _a.setData, data = _a.data;
    var _c = useState(""), name = _c[0], setName = _c[1];
    var _d = useState(""), syncDirectory = _d[0], setSyncDirectory = _d[1];
    var _e = useState(""), selectedLocation = _e[0], setSelectedLocation = _e[1];
    var _f = useState("default"), speed = _f[0], setSpeed = _f[1];
    var locations = useContext(LocationContext).locations;
    var areLocations = locations[0];
    var _g = useState(true), loading = _g[0], setLoading = _g[1];
    var _h = useState(true), speedSelectionShown = _h[0], setSpeedSelectionShown = _h[1];
    var _j = useState(0), maxDoneFileAge = _j[0], setMaxDoneFileAge = _j[1];
    var _k = useState(0), maxFailedFileAge = _k[0], setMaxFailedFileAge = _k[1];
    var resetEdgeModal = function () {
        setSyncDirectory("");
        setSelectedLocation({});
    };
    function registerEdgeNode() {
        return __awaiter(this, void 0, void 0, function () {
            var targetLocation_1, apiKey, timeBetweenRuns, timeBetweenConfigRefreshes, syncRetries, edgeNodeInfo, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        if (selectedLocation === "") {
                            toaster.warning("Bitte wähle zuerst einen Standort aus");
                            return [2 /*return*/];
                        }
                        targetLocation_1 = areLocations.find(function (location) { return location.id === selectedLocation; });
                        apiKey = generateApiKey({
                            method: "bytes",
                            prefix: "apomap",
                            length: 24,
                        });
                        timeBetweenRuns = 30;
                        timeBetweenConfigRefreshes = 30;
                        syncRetries = 6;
                        switch (speed) {
                            case "slow":
                                timeBetweenRuns = 5 * 60;
                                timeBetweenConfigRefreshes = 60;
                                syncRetries = 3;
                                break;
                            case "fast":
                                timeBetweenRuns = 10;
                                timeBetweenConfigRefreshes = 15;
                                syncRetries = 9;
                                break;
                        }
                        edgeNodeInfo = {
                            id: data.adoptionCode,
                            displayName: name,
                            owner: data.user.uid,
                            timeBetweenRuns: timeBetweenRuns,
                            timeBetweenConfigRefreshes: timeBetweenConfigRefreshes,
                            syncEnabled: syncDirectory.trim() !== "",
                            syncSystemIdentifier: targetLocation_1 === null || targetLocation_1 === void 0 ? void 0 : targetLocation_1.system,
                            syncUploadTarget: "https://europe-west3-apomapdreinull.cloudfunctions.net/api/getCsv",
                            syncDirectory: syncDirectory.trim(),
                            syncRetries: syncRetries,
                            locationId: targetLocation_1.id,
                            maxDoneFileAge: maxDoneFileAge,
                            maxFailedFileAge: maxFailedFileAge,
                        };
                        console.log("EdgeNodes Set");
                        return [4 /*yield*/, firebase
                                .firestore()
                                .collection("edgeNodes")
                                .doc(data.adoptionCode)
                                .set(edgeNodeInfo)];
                    case 1:
                        _a.sent();
                        console.log("EdgeNodeAdoption Update");
                        return [4 /*yield*/, firebase
                                .firestore()
                                .collection("edgeNodeAdoption")
                                .doc(data.adoptionCode)
                                .update({
                                id: data.adoptionCode,
                                wasAdopted: true,
                                token: apiKey,
                            })];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, firebase
                                .firestore()
                                .collection("tokens")
                                .doc(apiKey)
                                .set({
                                key: apiKey,
                                location: targetLocation_1.id,
                                name: name,
                                owner: data.user.uid,
                                permissions: ["write:tasks"],
                                edgeNodeAdoptionCode: data.adoptionCode,
                            })];
                    case 3:
                        _a.sent();
                        console.log("done");
                        setData({ isShown: false });
                        toaster.success("Schnittstelle ".concat(name, " wurde erfolgreich adoptiert"));
                        resetEdgeModal();
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _a.sent();
                        console.error(e_1);
                        toaster.danger("Bei dem Versuch die Schnittstelle zu adoptieren ist ein kritischer Fehler aufgetreten. Bitte versuch' es erneut oder wende dich an uns, wenn das Problem weiter besteht.");
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    }
    function updateEdgeNode() {
        return __awaiter(this, void 0, void 0, function () {
            var targetLocation_2, edgeNodeConfig, e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        if (selectedLocation === "") {
                            toaster.warning("Bitte wähle zuerst einen Standort aus");
                            return [2 /*return*/];
                        }
                        targetLocation_2 = areLocations.find(function (location) { return location.id === selectedLocation; });
                        edgeNodeConfig = {
                            displayName: name,
                            syncEnabled: syncDirectory.trim() !== "",
                            syncSystemIdentifier: targetLocation_2 === null || targetLocation_2 === void 0 ? void 0 : targetLocation_2.system,
                            syncDirectory: syncDirectory.trim(),
                            locationId: targetLocation_2.id,
                            maxDoneFileAge: maxDoneFileAge,
                            maxFailedFileAge: maxFailedFileAge,
                        };
                        switch (speed) {
                            case "slow":
                                edgeNodeConfig.timeBetweenRuns = 5 * 60;
                                edgeNodeConfig.timeBetweenConfigRefreshes = 60;
                                edgeNodeConfig.syncRetries = 3;
                                break;
                            case "default":
                                edgeNodeConfig.timeBetweenRuns = 30; // secs
                                edgeNodeConfig.timeBetweenConfigRefreshes = 30; // mins
                                edgeNodeConfig.syncRetries = 6;
                                break;
                            case "fast":
                                edgeNodeConfig.timeBetweenRuns = 10;
                                edgeNodeConfig.timeBetweenConfigRefreshes = 15;
                                edgeNodeConfig.syncRetries = 9;
                                break;
                        }
                        return [4 /*yield*/, firebase
                                .firestore()
                                .collection("edgeNodes")
                                .doc(data.adoptionCode)
                                .update(edgeNodeConfig)];
                    case 1:
                        _a.sent();
                        setData({ isShown: false });
                        toaster.success("Einstellungen erfolgreich \u00FCbernommen. Es kann bis zu einer Stunde dauern, bis die \u00C4nderungen von apomap Edge angewendet werden.");
                        resetEdgeModal();
                        return [3 /*break*/, 3];
                    case 2:
                        e_2 = _a.sent();
                        console.log("Edge Node Dialog Update:", e_2);
                        toaster.warning("Ihre Änderungen konnten nicht gespeichert werden. Bitte versuchen Sie es erneut.");
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    }
    useEffect(function () {
        (function (data) { return __awaiter(void 0, void 0, void 0, function () {
            var nodeConfig, e_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!data.create) return [3 /*break*/, 5];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        console.log(data);
                        return [4 /*yield*/, firebase
                                .firestore()
                                .collection("edgeNodes")
                                .doc(data.adoptionCode)
                                .get()];
                    case 2:
                        nodeConfig = (_a.sent()).data();
                        console.log(nodeConfig, areLocations);
                        // Name
                        setName(nodeConfig.displayName);
                        // Location
                        setSelectedLocation(nodeConfig.locationId);
                        // Speed
                        if (nodeConfig.timeBetweenRuns === 30 &&
                            nodeConfig.timeBetweenConfigRefreshes === 30 &&
                            nodeConfig.syncRetries === 6) {
                            // Default
                            setSpeed("default");
                        }
                        else if (nodeConfig.timeBetweenRuns === 5 * 60 &&
                            nodeConfig.timeBetweenConfigRefreshes === 60 &&
                            nodeConfig.syncRetries === 3) {
                            // Slow
                            setSpeed("slow");
                        }
                        else if (nodeConfig.timeBetweenRuns === 10 &&
                            nodeConfig.timeBetweenConfigRefreshes === 15 &&
                            nodeConfig.syncRetries === 9) {
                            // Fast
                            setSpeed("fast");
                        }
                        else {
                            // Custom config; do not allow override
                            setSpeed(null);
                            setSpeedSelectionShown(false);
                        }
                        // Path
                        setSyncDirectory(nodeConfig.syncDirectory);
                        setLoading(false);
                        return [3 /*break*/, 4];
                    case 3:
                        e_3 = _a.sent();
                        console.log("Edge node dialog load", e_3);
                        toaster.danger("Diese Edge-Node kann nicht bearbeitet werden. Bitte laden Sie die Seite neu und versuchen Sie es dann erneut.");
                        setData(function (curr) { return ({ isShown: false, adoptionCode: "" }); });
                        return [3 /*break*/, 4];
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        setLoading(false);
                        _a.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        }); })(data);
    }, []);
    if (loading) {
        return null;
    }
    var targetLocation = (_b = areLocations.find(function (location) { return location.id === selectedLocation; })) === null || _b === void 0 ? void 0 : _b.system;
    return (_jsx(Pane, { children: _jsx(Dialog, __assign({ isShown: data.isShown, title: data.create
                ? "Neue apomap Schnittstelle adoptieren"
                : "apomap Schnittstelle Einstellungen", onCloseComplete: function () {
                return setData(function (curr) { return ({ isShown: false, adoptionCode: "" }); });
            }, onConfirm: function () { return (data.create ? registerEdgeNode() : updateEdgeNode()); }, cancelLabel: "Abbrechen", confirmLabel: data.create ? "Adoptieren" : "Speichern", shouldCloseOnOverlayClick: false, shouldCloseOnEscapePress: false }, { children: _jsxs(Pane, __assign({ style: {
                    display: "flex",
                    gap: 12,
                    flexDirection: "column",
                } }, { children: [_jsx(ApoFieldLabel, { value: "Name" }), _jsx(TextInput, { placeholder: "Zentraler Server", value: name, onChange: function (event) { var _a; return setName((_a = event.target.value) !== null && _a !== void 0 ? _a : ""); } }), _jsxs("div", __assign({ style: {
                            display: "flex",
                            flexDirection: "row",
                            gap: 12,
                        } }, { children: [_jsx(ApoSelect, { value: selectedLocation, label: "Standort", options: __spreadArray([
                                    { label: "Bitte auswählen", value: "" }
                                ], areLocations.map(function (loc) { return ({
                                    label: loc.name,
                                    value: loc.id,
                                }); }), true), style: { flex: 1 }, onChange: function (event) {
                                    var _a;
                                    if (event.target.value === "-") {
                                        setSelectedLocation(null);
                                        return;
                                    }
                                    setSelectedLocation(event.target.value);
                                    console.log(syncDirectory);
                                    // Autofill sync path if none entered yet
                                    if ((syncDirectory !== null && syncDirectory !== void 0 ? syncDirectory : "").trim() === "") {
                                        var system = (_a = areLocations.find(function (location) { return location.id === selectedLocation; })) === null || _a === void 0 ? void 0 : _a.system;
                                        console.log("System", system);
                                        try {
                                            setSyncDirectory(DefaultPathForSystem[system]);
                                        }
                                        catch (e) {
                                            console.log("Do not have a path for", system);
                                        }
                                    }
                                    else {
                                        console.warn("syncDir not empty: ", JSON.stringify(syncDirectory));
                                    }
                                } }), speedSelectionShown && (_jsx(ApoSelect, { label: "Geschwindigkeit", options: [
                                    { label: "Langsam", value: "slow" },
                                    { label: "Standard", value: "default" },
                                    { label: "Schnell", value: "fast" },
                                ], style: { flex: 1 }, value: speed, onChange: function (event) { return setSpeed(event.target.value); } }))] })), _jsx(ApoFieldLabel, { value: "Zu synchronisierender Pfad" }), _jsx(TextInput, { placeholder: "C:\\...", value: syncDirectory, onChange: function (event) { var _a; return setSyncDirectory((_a = event.target.value) !== null && _a !== void 0 ? _a : ""); } }), _jsx("span", __assign({ style: { fontSize: 14, opacity: 0.5 } }, { children: (targetLocation === null || targetLocation === void 0 ? void 0 : targetLocation.system) &&
                            DefaultPathForSystem[targetLocation === null || targetLocation === void 0 ? void 0 : targetLocation.system]
                            ? "Wenn du nicht sicher bist, was du hier eingeben sollst, verwende den Standard-Pfad f\u00FCr ".concat(targetLocation === null || targetLocation === void 0 ? void 0 : targetLocation.system, ": ").concat(DefaultPathForSystem[targetLocation === null || targetLocation === void 0 ? void 0 : targetLocation.system])
                            : "Bitte wähle zuerst einen Standort. Der Standard-Pfad für das Kassensystem wird dann automatisch ausgefüllt, sofern uns dieser bekannt ist." })), _jsx("hr", { style: { border: ".5px solid #888888", width: "100%" } }), _jsxs("div", __assign({ style: {
                            display: "flex",
                            flexDirection: "row",
                            gap: 12,
                        } }, { children: [_jsx(ApoSelect, { label: "Speicherdauer erfolgreiche Imports", options: [
                                    { label: "Unendlich", value: "0" },
                                    { label: "1 Tag", value: "1" },
                                    { label: "3 Tage", value: "3" },
                                    { label: "1 Woche", value: "7" },
                                ], style: { flex: 1 }, value: maxDoneFileAge + "", onChange: function (event) {
                                    return setMaxDoneFileAge(parseInt(event.target.value));
                                } }), _jsx(ApoSelect, { label: "Speicherdauer fehlgeschlagene Imports", options: [
                                    { label: "Unendlich", value: "0" },
                                    { label: "1 Tag", value: "1" },
                                    { label: "3 Tage", value: "3" },
                                    { label: "1 Woche", value: "7" },
                                ], style: { flex: 1 }, value: maxFailedFileAge + "", onChange: function (event) {
                                    return setMaxFailedFileAge(parseInt(event.target.value));
                                } })] })), _jsx("span", __assign({ style: { fontSize: 14, opacity: 0.5, marginBottom: 6 } }, { children: "Aus datenschutzrechtlichen Gr\u00FCnden ist es m\u00F6glich, dass zu importierende Daten nicht unendlich auf dem Server aufbewahrt werden d\u00FCrfen." }))] })) })) }));
};
export default function Tokens() {
    var user = useContext(UserContext).user;
    var isUser = user[0];
    var _a = useState(""), adoptionCode = _a[0], setAdoptionCode = _a[1];
    var _b = useState([]), areTokens = _b[0], setTokens = _b[1];
    var _c = useState({
        isShown: false,
        token: null,
    }), isAddDialogShown = _c[0], setAddDialogShown = _c[1];
    var _d = useState({
        isShown: false,
        adoptionCode: "",
        create: true,
        user: {},
    }), edgeDialog = _d[0], setEdgeDialog = _d[1];
    var currentPlan = usePlanInfo().currentPlan;
    var _e = useState({
        isShown: false,
        token: null,
    }), isDeleteDialogShown = _e[0], setDeleteDialogShown = _e[1];
    function setupApiTokenListener() {
        firebase
            .firestore()
            .collection("tokens")
            .where("owner", "==", firebase.auth().currentUser.uid)
            .onSnapshot(function (snap) {
            var tokens = [];
            snap.forEach(function (doc) {
                var data = doc.data();
                tokens.push(data);
            });
            setTokens(tokens);
        });
    }
    useEffect(function () {
        setupApiTokenListener();
    }, []);
    // * Handles Edge Node Info
    var handleEdgeInfo = function () {
        setEdgeDialog(function () { return ({
            isShown: true,
            adoptionCode: adoptionCode,
            user: isUser,
            create: true,
        }); });
    };
    // * Reset user input
    var handleInputReset = function () {
        setAdoptionCode("");
    };
    // // * Gets and handles user adoption info
    // const [adoptionCode, setAdoptionCode] = useState("");
    // const { user } = useContext(UserContext);
    // const [isUser] = user;
    // * Adds user edge note into firebase
    function createEdgeNode() {
        // console.log("User Info", isUser);
        // firebase.firestore().collection("edgeNodes").doc(adoptionCode).set({
        //   id: adoptionCode,
        //   displayName: isUser.firstName,
        //   owner: isUser.uid, // id of the user that adopted it
        //   timeBetweenRuns: 1, // time to wait between task syncs / print jobs in secs
        //   timeBetweenConfigRefreshes: 2, // interval in which to re-request the config
        //   syncEnabled: true, // whether the edge should sync tasks (not exposed)
        //   syncSystemIdentifier: "", // an identifier of the POS system being synced (Kasse Syst)
        //   syncUploadTarget: "", // URL of cloud function for CSV upload
        //   syncDirectory: "", // Path of local directory to monitor
        //   syncRetries: 2, // how often a re-upload of a task should be attempted before giving up
        // });
    }
    var history = useHistory();
    return (_jsxs(Pane, __assign({ style: {
            display: "flex",
            flexDirection: "row",
            width: "100%",
            background: "linear-gradient(-3deg,#E4EBF5 0%, rgba(255,255,255,1) 100%)",
        } }, { children: [_jsx(NavigatorBar, {}), _jsx(RemoveDialog, { isShown: isDeleteDialogShown, setShown: setDeleteDialogShown }), isAddDialogShown.isShown && (_jsx(AddDialog, { isShown: isAddDialogShown, setShown: setAddDialogShown })), edgeDialog.isShown && (_jsx(EdgeDialog, { data: edgeDialog, setData: setEdgeDialog })), _jsxs(Pane, __assign({ style: { padding: 25, margin: 25, width: "100%", overflow: "scroll" } }, { children: [_jsx(Button, __assign({ onClick: function () { return history.push("/settings"); }, marginY: 8, marginRight: 12, iconBefore: ChevronLeftIcon }, { children: "Zur\u00FCck" })), _jsx(Heading, __assign({ size: 900 }, { children: "Schnittstellen" })), _jsx(Paragraph, __assign({ size: 400 }, { children: "Hier kannst du Schnittstellen-Tokens anlegen." })), _jsx("div", { style: {
                            width: "100%",
                            height: 2,
                            backgroundColor: "#EFEFEF",
                            borderRadius: 2,
                            marginTop: 10,
                            marginBottom: 10,
                        } }), ((currentPlan === "apomap-lite" && areTokens.length < 1) ||
                        currentPlan !== "apomap-lite") && (_jsxs(Pane, __assign({ style: {
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: 12,
                            width: "100%",
                        } }, { children: [_jsx(Button, __assign({ onClick: function () {
                                    return setAddDialogShown(function () { return ({
                                        isShown: true,
                                        token: null,
                                    }); });
                                }, iconAfter: AddIcon }, { children: "Neuen Token erstellen" })), _jsxs(Pane, __assign({ style: { display: "flex", gap: 6 } }, { children: [_jsx(TextInput, { name: "text-input-name", placeholder: "--------", value: adoptionCode, onChange: function (e) { return setAdoptionCode(e.target.value.trim()); } }), _jsx(Button, __assign({ onClick: function () {
                                            handleEdgeInfo();
                                            handleInputReset();
                                        } }, { children: "Schnittstelle mit Code adoptieren" }))] }))] }))), areTokens.map(function (token) {
                        return (_jsx(ApiTokenCard, { isAddDialogShown: isAddDialogShown, setAddDialogShown: setAddDialogShown, setDeleteDialogShown: setDeleteDialogShown, token: token, edgeNodeAdoptionCode: token.edgeNodeAdoptionCode, onEdgeConfigEdit: function (ac) {
                                setEdgeDialog(function () { return ({
                                    isShown: true,
                                    adoptionCode: ac,
                                    user: isUser,
                                    create: false,
                                }); });
                            } }));
                    })] }))] })));
}
