var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Autocomplete, Button, Pane, Paragraph } from 'evergreen-ui';
import { useCallback, useContext, useEffect, useMemo, useRef, useState, } from 'react';
import { UserContext } from '../contexts/UserContext';
import searchLocationsOnGoogle from '../utility/searchLocationsOnGoogle';
import ApoTextInput from './ApoTextInput';
var apiToken = 'pk.eyJ1IjoiYXBvbWFwc29mdHdhcmVkZXYiLCJhIjoiY2s4dzJlYnM4MDU5cjNlbnc5bjlhbTN1NSJ9.2MVbscPKHtJSJVcLmThq2Q';
var searchRequestHeaders = new Headers();
searchRequestHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
var requestOptions = {
    method: 'GET',
    headers: searchRequestHeaders,
    redirect: 'follow',
};
var itemToString = function (item) { return (item ? item.address : 'null'); };
var resolveWaiting;
var searchLocations = function (query, locale) { return __awaiter(void 0, void 0, void 0, function () {
    var waitingPromise, result, locations_1, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                // Debouncing
                resolveWaiting && resolveWaiting(false);
                waitingPromise = new Promise(function (resolve) {
                    resolveWaiting = resolve;
                    setTimeout(function () { return resolve(true); }, 600);
                });
                return [4 /*yield*/, waitingPromise];
            case 1:
                if (!(_a.sent()))
                    return [2 /*return*/, undefined];
                _a.label = 2;
            case 2:
                _a.trys.push([2, 5, , 6]);
                return [4 /*yield*/, fetch("https://api.mapbox.com/geocoding/v5/mapbox.places/".concat(query, ".json?country=").concat(locale !== null && locale !== void 0 ? locale : 'de', "&language=de&types=locality%2Cplace%2Caddress%2Cpostcode&access_token=").concat(apiToken), requestOptions)];
            case 3:
                result = _a.sent();
                return [4 /*yield*/, result.json()];
            case 4:
                result = _a.sent();
                locations_1 = [];
                result.features.map(function (loc) {
                    var _a, _b, _c;
                    var locality = (_b = (_a = loc.context.filter(function (_a) {
                        var id = _a.id;
                        return id.split('.')[0] === 'locality';
                    })[0]) === null || _a === void 0 ? void 0 : _a.text) !== null && _b !== void 0 ? _b : '';
                    var obj = {
                        address: (_c = loc.place_name + ', ' + locality) !== null && _c !== void 0 ? _c : '',
                        coords: loc.center,
                        id: 'individual-' + loc.place_name,
                    };
                    locations_1.push(obj);
                });
                console.log(locations_1);
                return [2 /*return*/, locations_1];
            case 5:
                error_1 = _a.sent();
                console.log('error', error_1);
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/, []];
        }
    });
}); };
var ApoLocationInput = function (_a) {
    var label = _a.label, required = _a.required, defaultValue = _a.defaultValue, onChange = _a.onChange, placeholder = _a.placeholder, style = _a.style, disabled = _a.disabled, resetNumber = _a.resetNumber;
    var _b = useState(defaultValue ? defaultValue.address : ''), value = _b[0], setValue = _b[1];
    var _c = useState(''), prevValue = _c[0], setPrevValue = _c[1];
    var _d = useState(false), loading = _d[0], setLoading = _d[1];
    var _e = useState(false), googleLoading = _e[0], setGoogleLoading = _e[1];
    var _f = useState(defaultValue), selectedLocation = _f[0], setSelectedLocation = _f[1];
    var _g = useState([]), suggestedLocations = _g[0], setSuggestedLocations = _g[1];
    var user = useContext(UserContext).user;
    var isUser = user[0];
    var locale = useMemo(function () {
        return isUser.address
            ? isUser.address
                .split(' ')[isUser.address.split(' ').length - 1].toLowerCase()
            : 'de';
    }, [isUser]);
    useEffect(function () {
        setValue(defaultValue === null || defaultValue === void 0 ? void 0 : defaultValue.address);
    }, [defaultValue]);
    useEffect(function () {
        if (resetNumber !== null && typeof resetNumber === 'number') {
            setValue('');
        }
    }, [resetNumber]);
    var inputRef = useRef(null);
    var handleChange = useCallback(function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var query, locations;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoading(true);
                    query = e.target.value;
                    setValue(query);
                    setPrevValue(query);
                    if (!query) {
                        setSuggestedLocations([]);
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, searchLocations(query, locale)];
                case 1:
                    locations = _a.sent();
                    if (!(document.activeElement === inputRef.current) || !locations)
                        return [2 /*return*/];
                    setSuggestedLocations(locations);
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); }, []);
    var searchAlternativeOnGoogle = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var locations;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setValue('Ergebnisse von Google');
                    setGoogleLoading(true);
                    return [4 /*yield*/, searchLocationsOnGoogle(prevValue, locale)];
                case 1:
                    locations = _a.sent();
                    setSuggestedLocations(locations.data);
                    setGoogleLoading(false);
                    return [2 /*return*/];
            }
        });
    }); }, [prevValue]);
    var handleFocus = useCallback(function (e) {
        if (selectedLocation) {
            setSuggestedLocations([selectedLocation]);
        }
    }, [selectedLocation]);
    var handleBlur = useCallback(function () {
        if (!suggestedLocations[0]) {
            setValue('');
        }
        setSelectedLocation(suggestedLocations[0]);
        setSuggestedLocations([]);
    }, [suggestedLocations]);
    var handleAutocompleteSelect = useCallback(function (obj) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            inputRef.current && inputRef.current.blur();
            setSuggestedLocations([]);
            setSelectedLocation(obj);
            onChange && onChange(obj);
            return [2 /*return*/];
        });
    }); }, [onChange]);
    useEffect(function () {
        setValue(selectedLocation ? selectedLocation.address : '');
    }, [selectedLocation]);
    return (_jsxs(Pane, __assign({ pointerEvents: disabled ? 'none' : null, style: style }, { children: [_jsx(Paragraph, { children: label }), value !== '' && !loading ? (_jsx(Button, __assign({ isLoading: googleLoading, onClick: function () { return searchAlternativeOnGoogle(); } }, { children: "Adresse nicht gefunden?" }))) : (_jsx(Pane, { style: { height: 30 } })), _jsx(ApoTextInput, { disabled: disabled, placeholder: placeholder, inputRef: inputRef, required: required, value: value, onChange: handleChange, onFocus: handleFocus, onBlur: handleBlur }), _jsx(Autocomplete, __assign({ isOpen: !!suggestedLocations.length, items: suggestedLocations, onSelect: handleAutocompleteSelect, itemToString: itemToString, position: 'bottom-left' }, { children: function (_a) {
                    var getRef = _a.getRef;
                    return (_jsx("div", { ref: getRef, style: { marginTop: -10 } }));
                } }), suggestedLocations.map(function (l) { return l.address; }).join() || undefined)] })));
};
export default ApoLocationInput;
