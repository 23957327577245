var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext, useEffect, useMemo } from "react";
import { Pane, Button, Heading, Paragraph, ChevronLeftIcon, Alert, } from "evergreen-ui";
import { Link, useHistory } from "react-router-dom";
import Firework from "../assets/images/fireworks.svg";
import { PlanContext } from "../contexts/PlanContext";
import { UserContext } from "../contexts/UserContext";
import { LocationContext } from "../contexts/LocationContext";
import { getSystemLabel } from "../utility/getSystemLabel";
import { DriverContext } from "../contexts/DriverContext";
export default function FinishOnboardingCard(props) {
    var history = useHistory();
    var plan = useContext(PlanContext).plan;
    var isPlan = plan[0], setPlan = plan[1];
    var _a = useContext(UserContext), user = _a.user, ready = _a.ready;
    var isUser = user[0];
    var locations = useContext(LocationContext).locations;
    var areBusinessLocations = locations[0], setBusinessLocations = locations[1];
    var drivers = useContext(DriverContext).drivers;
    var areDrivers = drivers[0], setDrivers = drivers[1];
    var allSystemsInLocations = useMemo(function () {
        return areBusinessLocations.map(function (location) { return getSystemLabel(location.system); });
    }, [areBusinessLocations]);
    useEffect(function () {
        setTimeout(function () {
            history.push("/dashboard");
        }, 10000);
    }, [history]);
    return (_jsxs(Pane, __assign({ width: "100%", height: "100%", display: "flex", flexDirection: "row" }, { children: [_jsx(Link, __assign({ to: "/onboarding/4" }, { children: _jsxs(Pane, __assign({ position: "absolute", top: "35px", left: "35px", display: "flex", flexDirection: "row", alignItems: "center" }, { children: [_jsx(ChevronLeftIcon, {}), _jsx(Paragraph, { children: "Zur\u00FCck" })] })) })), _jsxs(Pane, __assign({ width: "50%", padding: 50, display: "flex", flexDirection: "column", justifyContent: "space-between" }, { children: [_jsx(Pane, { children: _jsx(Heading, __assign({ marginTop: 30, width: "100%", fontSize: "48px", color: "#2D3B51", size: "large" }, { children: "Deine Fakten" })) }), _jsxs(Pane, __assign({ marginTop: 30, height: "100%", width: "100%" }, { children: [allSystemsInLocations.length !== 0 ? (_jsx(Alert, { intent: "success", title: "Deine Warenwirtschaft ".concat(allSystemsInLocations.length === 1 ? "ist" : "sind", " ").concat(allSystemsInLocations.join(", ")), margin: 10 })) : (_jsx(Alert, { title: "Du hast keinen Standort angelegt!" })), areBusinessLocations.filter(function (location) { return location.type === "main"; })
                                .length > 0 && (_jsx(Alert, { intent: "success", title: "Deine Hauptapotheke ist ".concat(areBusinessLocations.filter(function (location) { return location.type === "main"; })[0].name), margin: 10 })), areBusinessLocations.filter(function (location) { return location.type !== "main"; })
                                .length > 0 && (_jsx(Alert, { intent: "success", title: "Die ".concat(areBusinessLocations.filter(function (location) { return location.type !== "main"; })[0].name, " und ").concat(areBusinessLocations.filter(function (location) { return location.type !== "main"; }).length - 1, " weitere sind deine Nebenapotheken"), margin: 10 })), areDrivers.length > 0 ? (_jsx(Alert, { intent: "success", title: "".concat(areDrivers
                                    .map(function (driver) { return driver.firstName; })
                                    .join("u. "), " ").concat(areDrivers.length > 1 ? "fahren" : "fährt", " f\u00FCr Dich"), margin: 10 })) : (_jsx(Alert, { intent: "info", title: "Kein Fahrer angelegt" })), _jsx(Alert, { intent: "success", margin: 10, title: "Du hast dich f\u00FCr den ".concat(isPlan === null || isPlan === void 0 ? void 0 : isPlan.subscriptionLabel.replace("-", " "), " Plan entschieden") }), _jsx(Heading, __assign({ marginTop: 30, width: "50%", fontSize: "30px", color: "#2D3B51", size: "large" }, { children: "Du willst mehr lernen?" })), _jsxs(Paragraph, __assign({ width: "70%" }, { children: ["Lerne mit unserem Handbuch Schritt f\u00FCr Schritt alle Funktionen kennen. ", _jsx("br", {}), _jsx(Link, __assign({ to: { pathname: "www.apomap.de/handbuch" } }, { children: "Hier geht es zum Handbuch" }))] })), _jsx(Link, __assign({ style: { textDecoration: "none" }, to: "/dashboard" }, { children: _jsx(Button, __assign({ marginTop: 10, backgroundColor: "#2F2E41", appearance: "primary", size: "large" }, { children: "Mit apomap starten" })) }))] }))] })), _jsxs(Pane, __assign({ width: "50%", padding: 50, display: "flex", flexDirection: "column", justifyContent: "space-between", backgroundColor: "#49beef", textAlign: "center" }, { children: [_jsxs(Heading, __assign({ marginTop: 30, fontSize: "48px", size: "large", color: "white", fontWeight: 800 }, { children: ["Gl\u00FCckwunsch! ", _jsx("br", {}), " Du hast es geschafft"] })), _jsxs(Paragraph, __assign({ fontFamily: "bold", width: "100%", color: "white" }, { children: ["Nun bist du bereit. ", _jsx("br", {}), "Willkommen bei apomap."] })), _jsx("img", { src: Firework, alt: "Person", style: { width: "100%", height: "50%", marginTop: 20 } })] }))] })));
}
