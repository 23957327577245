var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Alert, Button, Card, Pane, Paragraph, SelectMenu, Table, TextInput, TrashIcon, } from "evergreen-ui";
import { forwardRef, useImperativeHandle, useMemo, useState } from "react";
import { variablesOptions } from "../SuperChat/SuperChatConfig";
import { defaultStatus } from "../SuperChat/SuperChatLocationConfig";
var HealviLocationConfig = forwardRef(function (_a, ref) {
    var templates = _a.templates, template = _a.template, templatesMap = _a.templatesMap, status = _a.status, name = _a.name, setConfig = _a.setConfig, locationId = _a.locationId, locationsMap = _a.locationsMap;
    // locations
    var location = useMemo(function () { return locationsMap[locationId]; }, [locationId, locationsMap]);
    // Template-Management
    var _b = useState(template !== null && template !== void 0 ? template : undefined), selectedTemplateId = _b[0], setSelectedTemplateId = _b[1];
    var selectedTemplate = useMemo(function () { return templatesMap[selectedTemplateId !== null && selectedTemplateId !== void 0 ? selectedTemplateId : ""]; }, [templatesMap, selectedTemplateId]);
    // Status-Management
    var _c = useState(status !== null && status !== void 0 ? status : undefined), selectedStatusId = _c[0], setSelectedStatusId = _c[1];
    var statusMap = useMemo(function () { return Object.fromEntries(defaultStatus.map(function (e) { return [e.value, e.label]; })); }, []);
    // Nickname-Management
    var _d = useState(name !== null && name !== void 0 ? name : undefined), selectedName = _d[0], setSelectedName = _d[1];
    useImperativeHandle(ref, function () {
        return {
            getConfiguration: function () {
                return {
                    templateId: selectedTemplateId,
                    locationId: locationId,
                    status: selectedStatusId,
                    name: selectedName,
                };
            },
        };
    }, [locationId, selectedName, selectedStatusId, selectedTemplateId]);
    return (_jsxs(Card, __assign({ background: "white", elevation: 1, padding: 12, marginTop: 8 }, { children: [_jsx(Paragraph, __assign({ fontWeight: 600, marginY: 6 }, { children: "Konfiguration gilt f\u00FCr Standort" })), _jsx(Paragraph, __assign({ marginY: 6 }, { children: location.name })), _jsx(Paragraph, __assign({ fontWeight: 600, marginY: 6 }, { children: "Grundeinstellungen" })), _jsx(Paragraph, __assign({ marginY: 6 }, { children: "2. W\u00E4hle dein aktives Template" })), _jsx(SelectMenu, __assign({ title: "Verf\u00FCgbare Vorlagen", options: templates.map(function (template) { return ({
                    label: template.name,
                    value: template.template_id,
                }); }), selected: selectedTemplateId !== null && selectedTemplateId !== void 0 ? selectedTemplateId : undefined, onSelect: function (item) {
                    setSelectedTemplateId(item.value.toString());
                } }, { children: _jsx(Button, { children: (selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.name) || "Vorlage auswählen..." }) })), (selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.template_id) && (_jsx(_Fragment, { children: _jsx(Paragraph, __assign({ marginY: 12, backgroundColor: "white", padding: 8, borderRadius: 8 }, { children: selectedTemplate.components[0].text })) })), _jsx(Alert, { intent: "none", title: "Von apomap bereitgestellte Variablen. Diese kannst du in deinem Template referenzieren!", marginY: 12 }), _jsxs(Table, { children: [_jsxs(Table.Head, { children: [_jsx(Table.TextHeaderCell, { children: "Position" }), _jsx(Table.TextHeaderCell, { children: "Name" }), _jsx(Table.TextHeaderCell, { children: "Beispiel" })] }), _jsx(Table.Body, { children: variablesOptions.map(function (v, i) { return (_jsxs(Table.Row, __assign({ isSelectable: true }, { children: [_jsx(Table.TextCell, __assign({ isNumber: true }, { children: i + 1 })), _jsx(Table.TextCell, { children: v.name }), _jsx(Table.TextCell, { children: v.example })] }))); }) })] }), _jsx(Paragraph, __assign({ marginY: 6 }, { children: "3.3 Bei welchem Status soll die Nachricht versendet werden" })), _jsx(SelectMenu, __assign({ title: "Verf\u00FCgbare Stati", options: defaultStatus.map(function (_a) {
                    var value = _a.value, label = _a.label;
                    return ({
                        label: label,
                        value: value,
                    });
                }), isMultiSelect: false, selected: selectedStatusId !== null && selectedStatusId !== void 0 ? selectedStatusId : undefined, onSelect: function (item) { return setSelectedStatusId(item.value.toString()); } }, { children: _jsx(Button, { children: selectedStatusId
                        ? statusMap[selectedStatusId]
                        : "Status auswählen" }) })), _jsx(Paragraph, __assign({ fontWeight: 600 }, { children: "Optionen" })), _jsxs(Pane, __assign({ display: "flex", flexDirection: "column" }, { children: [_jsx(Paragraph, { children: "Konfigurationsname" }), _jsx(TextInput, { marginBottom: 8, placeholder: "Name f\u00FCr Konfiguration", value: selectedName, onChange: function (e) { return setSelectedName(e.target.value); } })] })), _jsx(Button, __assign({ onClick: function () { return setConfig(function (s) { return s.filter(function (i) { return i.name !== name; }); }); }, intent: "danger", iconBefore: TrashIcon }, { children: "Konfiguration f\u00FCr diesen Standort l\u00F6schen" }))] })));
});
export default HealviLocationConfig;
