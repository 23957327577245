var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card, Heading, Pane, Paragraph, SideSheet, Tab, Tablist, } from "evergreen-ui";
import React, { useContext, useState } from "react";
import QRCode from "react-qr-code";
import firebase from "../config";
import "firebase/firestore";
import QuestionDetails from "../container/QuestionDetails";
import { QuestionContext } from "../contexts/QuestionContext";
import Chat from "./Chat";
import Call from "./Call";
export default function QuestionModal(_a) {
    var isShown = _a.isShown, setIsShown = _a.setIsShown;
    var question = useContext(QuestionContext).question;
    var focusedQuestion = question[0], setFocusedQuestion = question[1];
    var _b = useState(0), selectedIndex = _b[0], setSelectedIndex = _b[1];
    var _c = useState(), data = _c[0], setData = _c[1];
    var getQuestion = function () {
        firebase
            .firestore()
            .collection("app_questions")
            .doc(focusedQuestion.task_id)
            .get()
            .then(function (doc) {
            if (doc.exists) {
                setData(doc.data());
            }
        })
            .catch(function (err) { return console.log(err); });
    };
    var goBack = function () {
        setData();
        setFocusedQuestion();
        setIsShown(false);
    };
    return (_jsxs(SideSheet, __assign({ isShown: isShown, onCloseComplete: function () { return goBack(); }, onOpenComplete: function () { return getQuestion(); }, containerProps: {
            display: "flex",
            flex: "1",
            flexDirection: "column",
        } }, { children: [_jsxs(Pane, __assign({ zIndex: 1, flexShrink: 0, elevation: 0, backgroundColor: "white" }, { children: [_jsxs(Pane, __assign({ padding: 16, borderBottom: "muted" }, { children: [_jsx(Heading, __assign({ size: 600 }, { children: focusedQuestion === null || focusedQuestion === void 0 ? void 0 : focusedQuestion.customer_name })), _jsx(Paragraph, __assign({ size: 400, color: "muted" }, { children: focusedQuestion === null || focusedQuestion === void 0 ? void 0 : focusedQuestion.task_address }))] })), _jsx(Pane, __assign({ display: "flex", padding: 8 }, { children: _jsx(Tablist, { children: ["Fragen", "Chat", "Video-Beratung", "QR-Code"].map(function (tab, index) { return (_jsx(Tab, __assign({ isSelected: selectedIndex === index, onSelect: function () { return setSelectedIndex(index); } }, { children: tab }), tab)); }) }) }))] })), selectedIndex === 0 && _jsx(QuestionDetails, { question: data }), selectedIndex === 1 && (_jsx(Chat, { chatNode: "chat_" + (data === null || data === void 0 ? void 0 : data.id) + "_" + firebase.auth().currentUser.uid, id: data === null || data === void 0 ? void 0 : data.id })), selectedIndex === 2 && _jsx(Call, { question: data }), selectedIndex === 3 && (_jsx(Pane, __assign({ flex: "1", overflowY: "scroll", background: "tint1", padding: 16 }, { children: _jsx(Card, __assign({ backgroundColor: "white", elevation: 0, height: 240, display: "flex", alignItems: "center", justifyContent: "center" }, { children: _jsx(QRCode, { size: 150, value: focusedQuestion === null || focusedQuestion === void 0 ? void 0 : focusedQuestion.task_id }) })) })))] })));
}
