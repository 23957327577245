var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Badge, Button, Combobox, IconButton, LayoutHierarchyIcon, MenuOpenIcon, Pane, Paragraph, Spinner, Table, Tooltip, } from "evergreen-ui";
import "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import firebase from "../config";
import { DriverContext } from "../contexts/DriverContext";
import { detachTaskFromTour } from "../utility/detachTaskFromTour";
import { getLabelFromType } from "../utility/getLabelFromType";
import { normaliseMoneyString } from "../utility/normaliseMoneyString";
import { optimizeTour } from "../utility/optimizeTour";
import TableSortHeader from "./TableSortHeader";
export default function UnoptimizedTasksList(_a) {
    var _this = this;
    var tasksToAdd = _a.tasksToAdd, tour = _a.tour;
    var _b = useState([]), toAddTasks = _b[0], setToAddTasks = _b[1];
    var _c = useState(true), loading = _c[0], setLoading = _c[1];
    var _d = useState(false), optimizeLoading = _d[0], setOptimizeLoading = _d[1];
    var _e = useState(), selectedDriver = _e[0], setSelectedDriver = _e[1];
    var drivers = useContext(DriverContext).drivers;
    var allDrivers = drivers[0];
    var getTasks = function () { return __awaiter(_this, void 0, void 0, function () {
        var tourOpenTasks;
        var _this = this;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!tasksToAdd.length) {
                        setLoading(false);
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, Promise.all(tasksToAdd.map(function (taskId) { return __awaiter(_this, void 0, void 0, function () {
                            var doc, data;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, firebase
                                            .firestore()
                                            .collection("tasks")
                                            .doc(taskId)
                                            .get()];
                                    case 1:
                                        doc = _a.sent();
                                        data = doc.data();
                                        return [2 /*return*/, data];
                                }
                            });
                        }); }))];
                case 1:
                    tourOpenTasks = _a.sent();
                    setToAddTasks(tourOpenTasks);
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var reoptimize = function () { return __awaiter(_this, void 0, void 0, function () {
        var error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    setLoading(true);
                    return [4 /*yield*/, optimizeTour(tour)];
                case 1:
                    _a.sent();
                    setLoading(false);
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    setLoading(false);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var makeDriverChange = function (_a) {
        var data = _a.data;
        firebase
            .firestore()
            .collection("tours")
            .doc(tour.id)
            .update({ driver: data.id })
            .then(function () { return setSelectedDriver(data.name); });
    };
    useEffect(function () {
        getTasks();
    }, [tasksToAdd]);
    return (_jsxs(_Fragment, { children: [_jsxs(Pane, __assign({ display: "flex", style: { flexDirection: "row", alignItems: "center" } }, { children: [!("quickList" in tour) && tour.driver && (_jsx(Button, __assign({ marginLeft: 10, isLoading: optimizeLoading, onClick: reoptimize, iconAfter: LayoutHierarchyIcon, intent: "success" }, { children: "Jetzt optimieren" }))), !tour.driver && (_jsx(Combobox, { style: { width: 180 }, marginLeft: 10, items: allDrivers.map(function (driver) { return ({
                            name: driver.firstName + " " + driver.lastName,
                            data: driver,
                        }); }), selectedItem: selectedDriver, onChange: makeDriverChange, itemToString: function (item) { return (item ? item.name : ""); }, placeholder: "Fahrer w\u00E4hlen" }))] })), _jsxs(Pane, __assign({ display: "flex", flexDirection: "row", alignItems: "center" }, { children: [loading && _jsx(Spinner, { size: 16 }), _jsx(Paragraph, __assign({ fontWeight: 600, marginTop: 20 }, { children: "Hinzukommende Autr\u00E4ge" }))] })), !loading && (_jsxs(_Fragment, { children: [_jsxs(Table.Head, __assign({ style: { backgroundColor: "#F8FBFF" } }, { children: [_jsx(TableSortHeader, { title: "Typ" }), _jsx(TableSortHeader, { title: "Name" }), _jsx(TableSortHeader, { title: "Adresse" }), _jsx(TableSortHeader, { title: "Auftraggeber" }), _jsx(TableSortHeader, { title: "Betrag" })] })), _jsx(Pane, __assign({ style: { width: "100%", overflow: "scroll" } }, { children: toAddTasks.length &&
                            (toAddTasks === null || toAddTasks === void 0 ? void 0 : toAddTasks.map(function (task) { return (_jsxs(Table.Row, { children: [_jsx(Table.TextCell, { children: _jsx(Pane, __assign({ display: "flex" }, { children: _jsx(Badge, __assign({ color: "#48B77B" }, { children: getLabelFromType(task === null || task === void 0 ? void 0 : task.task_type) })) })) }), _jsx(Table.TextCell, { children: task === null || task === void 0 ? void 0 : task.customer_name }), _jsx(Table.TextCell, { children: task === null || task === void 0 ? void 0 : task.task_address }), _jsx(Table.TextCell, {}), _jsx(Table.TextCell, { children: _jsxs(Pane, __assign({ display: "flex", flexDirection: "row", alignItems: "center" }, { children: [_jsx("p", { children: normaliseMoneyString(task === null || task === void 0 ? void 0 : task.task_open_amount) }), _jsx(Tooltip, __assign({ content: "Entziehen" }, { children: _jsx(IconButton, { onClick: function () {
                                                            return detachTaskFromTour(task === null || task === void 0 ? void 0 : task.task_id, tour, task.task_prepared);
                                                        }, icon: MenuOpenIcon, marginLeft: 15 }) }))] })) })] })); })) }))] }))] }));
}
