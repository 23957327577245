var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Avatar, Heading } from "evergreen-ui";
import React, { useContext } from "react";
import DashboardCard from "./DashboardCard";
import { TaskContext } from "../../contexts/TaskContext";
import { DriverContext } from "../../contexts/DriverContext";
var ToursOverviewCard = function () {
    //   const tours = useTours();
    //   const drivers = useDrivers();
    var tours = useContext(TaskContext).tours;
    var areTours = tours[0], setTours = tours[1];
    var drivers = useContext(DriverContext).drivers;
    var areDrivers = drivers[0], _ = drivers[1];
    if (tours === null || areDrivers === null) {
        return _jsx("p", { children: "Loading..." });
    }
    return (_jsx(DashboardCard, __assign({ title: "Heutige Touren", size: 2, horizontalPadding: false, contentStyle: {
            overflowY: "auto",
            maxHeight: 280,
        } }, { children: _jsx("div", __assign({ style: {
                width: "100%",
                height: "100%",
                overflowY: "auto",
            } }, { children: areTours === null || areTours === void 0 ? void 0 : areTours.map(function (tour) {
                var responsibleDriver = areDrivers.find(function (entry) { return entry.id === (tour === null || tour === void 0 ? void 0 : tour.driver); });
                var driverName = (responsibleDriver === null || responsibleDriver === void 0 ? void 0 : responsibleDriver.firstName)
                    ? "".concat(responsibleDriver === null || responsibleDriver === void 0 ? void 0 : responsibleDriver.firstName, " ").concat(responsibleDriver === null || responsibleDriver === void 0 ? void 0 : responsibleDriver.lastName)
                    : "Nicht festgelegt";
                return (_jsxs("div", __assign({ style: {
                        display: "flex",
                        flexDirection: "row",
                        boxShadow: "0px 10px 35px #D8DAE5",
                        borderTopLeftRadius: 27,
                        borderBottomLeftRadius: 27,
                        backgroundColor: "#FFF",
                        marginBottom: 18,
                        gap: 10,
                        marginLeft: 18,
                        marginRight: 18,
                    } }, { children: [_jsx(Avatar, { name: driverName, size: 54 }), _jsx("div", __assign({ style: { flex: 3, display: "flex", alignItems: "center" } }, { children: _jsx(Heading, __assign({ size: 500 }, { children: driverName })) })), tour.status === "prepared" && (_jsxs("div", __assign({ style: {
                                flex: 2,
                                backgroundColor: "#49BEEF",
                                display: "flex",
                                flexDirection: "column",
                                justifyItems: "center",
                                padding: 5,
                            } }, { children: [_jsx(Heading, __assign({ size: 500, color: "#2D3B51" }, { children: "Startzeit" })), _jsxs("p", __assign({ style: {
                                        fontSize: "1.2rem",
                                        fontWeight: "bold",
                                        margin: 0,
                                        color: "#2D3B51",
                                    } }, { children: [tour.startTime, " Uhr"] }))] }))), tour.status === "ontheway" && (_jsxs("div", __assign({ style: {
                                flex: 2,
                                backgroundColor: "#FFB020",
                                display: "flex",
                                flexDirection: "column",
                                justifyItems: "center",
                                padding: 5,
                            } }, { children: [_jsx(Heading, __assign({ size: 500, color: "#2D3B51" }, { children: "Endzeit" })), _jsxs("p", __assign({ style: {
                                        fontSize: "1.2rem",
                                        fontWeight: "bold",
                                        margin: 0,
                                        color: "#2D3B51",
                                    } }, { children: [tour.quickList.reduce(function (accu, curr) {
                                            if (curr.type === "end") {
                                                return curr.time;
                                            }
                                            else {
                                                return accu;
                                            }
                                        }, ""), " ", "Uhr"] }))] })))] })));
            }) })) })));
};
export default ToursOverviewCard;
