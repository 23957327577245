var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Pane, ThemeProvider } from "evergreen-ui";
import "firebase/auth";
import { useParams } from "react-router-dom";
import AddDataCard from "../components/AddDataCard";
import AddDriverCard from "../components/AddDriverCard";
import AddLocationCard from "../components/AddLocationCard";
import AddPlanCard from "../components/AddPlanCard";
import FinishOnboardingCard from "../components/FinishOnboardingCard";
import FooterBar from "../components/FooterBar";
import OnboardingProgressBar from "../components/OnboardingProgressBar";
import theme from "../theme";
export default function Onboarding() {
    var step = useParams().step;
    return (_jsx(ThemeProvider, __assign({ value: theme }, { children: _jsxs(Pane, __assign({ style: {
                width: "100%",
                height: "100%",
                background: "linear-gradient(#E4EBF5 0%, rgba(255,255,255,1) 100%)",
            } }, { children: [_jsx(OnboardingProgressBar, { progress: step * 20 }), step === "1" && _jsx(AddDataCard, {}), step === "2" && _jsx(AddPlanCard, { controls: true }), step === "3" && _jsx(AddLocationCard, {}), step === "4" && _jsx(AddDriverCard, {}), step === "5" && _jsx(FinishOnboardingCard, {}), _jsx(FooterBar, { additionalText: step === "2" && "*Grundpreis pro Monat zzgl. MwSt." })] })) })));
}
