var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, CrossIcon, Dialog, Heading, Pane, Paragraph, Spinner, Table, TableCell, Tooltip, } from "evergreen-ui";
import "firebase/firestore";
import moment from "moment";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import BlueBar from "../components/BlueBar";
import firebase from "../config";
import { DriverContext } from "../contexts/DriverContext";
import useTaskTempStore from "../store/taskTempStore";
import calculateTrackerStats from "../utility/calculateTempTrackerStats";
import { normaliseMoneyString } from "../utility/normaliseMoneyString";
import processTrackers from "../utility/processTrackers";
import SignatureViewer from "./SignatureViewer";
import StatusBadge from "./StatusBadge";
var TableText = function (_a) {
    var children = _a.children;
    return _jsx(Paragraph, __assign({ style: { fontFamily: "regular" } }, { children: children }));
};
var TableTitle = function (_a) {
    var children = _a.children;
    return (_jsx(Heading, __assign({ size: 200, style: { fontFamily: "bold" } }, { children: children })));
};
var TableTooltip = function (_a) {
    var children = _a.children, content = _a.content;
    return (_jsx(Tooltip, __assign({ content: content, appearance: "card" }, { children: children })));
};
export default function TaskHistory(_a) {
    var _this = this;
    var _b, _c, _d, _e, _f;
    var isShown = _a.isShown, setShown = _a.setShown, task = _a.task;
    var drivers = useContext(DriverContext).drivers;
    var areDrivers = drivers[0], _ = drivers[1];
    var taskTempResponses = useTaskTempStore().taskTempResponses;
    var history = useHistory();
    var _g = useState(), tour = _g[0], setTour = _g[1];
    var tracker = useMemo(function () { var _a; return (_a = tour === null || tour === void 0 ? void 0 : tour.withTracker) !== null && _a !== void 0 ? _a : false; }, [tour, isShown]);
    var driver = useMemo(function () {
        return areDrivers
            ? areDrivers.find(function (driver) { return (driver === null || driver === void 0 ? void 0 : driver.id) === (tour === null || tour === void 0 ? void 0 : tour.driver); })
            : undefined;
    }, [tour, areDrivers]);
    var signature = useMemo(function () {
        var _a, _b;
        return (_b = (_a = task === null || task === void 0 ? void 0 : task.task_history) === null || _a === void 0 ? void 0 : _a.find(function (entry) { return entry.type === "signature"; })) !== null && _b !== void 0 ? _b : undefined;
    }, [task === null || task === void 0 ? void 0 : task.task_history]);
    var getTour = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!task.task_tour) return [3 /*break*/, 2];
                    return [4 /*yield*/, firebase
                            .firestore()
                            .collection("tours")
                            .doc(task.task_tour)
                            .get()
                            .then(function (doc) { return setTour(doc.data()); })];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); }, [task, isShown]);
    var taskDate = useMemo(function () {
        var _a, _b;
        return ((_b = moment
            .utc(((_a = task === null || task === void 0 ? void 0 : task.processed_date) === null || _a === void 0 ? void 0 : _a.seconds) * 1000)
            .add(2, "hour")
            .format("DD.MM.YY HH:mm")) !== null && _b !== void 0 ? _b : moment(task === null || task === void 0 ? void 0 : task.task_date).format("DD.MM.YY HH:mm"));
    }, [(_b = task === null || task === void 0 ? void 0 : task.processed_date) === null || _b === void 0 ? void 0 : _b.seconds, task === null || task === void 0 ? void 0 : task.task_date]);
    var taskDeliveryDate = useMemo(function () {
        return taskDate.split(" ")[0];
    }, [taskDate]);
    var momentStartTime = useMemo(function () {
        var startTime = taskDate.split(" ")[1];
        var reversedTime = moment(startTime, "HH:mm")
            .subtract(2, "h")
            .format("HH:mm"); // 2 hours before because of timezone difference which added 2 hours in the database
        return moment(reversedTime, "HH:mm")
            .subtract(300, "seconds")
            .format("HH:mm");
    }, [taskDate]);
    var momentEndTime = useMemo(function () {
        var endTime = taskDate.split(" ")[1];
        var reversedTime = moment(endTime, "HH:mm")
            .subtract(2, "h")
            .format("HH:mm"); // 2 hours before because of timezone difference which added 2 hours in the database
        return moment(reversedTime, "HH:mm").add(300, "seconds").format("HH:mm");
    }, [taskDate]);
    var taskTempStats = useMemo(function () { return calculateTrackerStats(taskTempResponses); }, [taskTempResponses]);
    useEffect(function () {
        if (isShown & !tour) {
            getTour();
        }
        if (!isShown) {
            setTour(undefined);
        }
    }, [getTour, isShown, tour]);
    useEffect(function () {
        if (tour === null || tour === void 0 ? void 0 : tour.withTracker) {
            processTrackers(tour === null || tour === void 0 ? void 0 : tour.trackers, tour === null || tour === void 0 ? void 0 : tour.date, tour === null || tour === void 0 ? void 0 : tour.date, momentStartTime, momentEndTime, 1 // true
            );
        }
    }, [
        momentEndTime,
        momentStartTime,
        taskDeliveryDate,
        tour === null || tour === void 0 ? void 0 : tour.date,
        tour === null || tour === void 0 ? void 0 : tour.trackers,
        tour === null || tour === void 0 ? void 0 : tour.withTracker,
    ]);
    if (!isShown) {
        return null;
    }
    if (!task)
        return _jsx("span", {});
    return (_jsxs(Dialog, __assign({ isShown: isShown, hasHeader: false, hasFooter: false, isConfirmDisabled: true, onCancel: function () { return setShown(false); }, shouldCloseOnOverlayClick: false, shouldCloseOnEscapePress: false, cancelLabel: "Zurück", width: "80vw", contentContainerProps: {
            className: "dialog-content-container",
            padding: 0,
        }, containerProps: { className: "square-corners" } }, { children: [_jsx(BlueBar, {}), _jsxs(Pane, __assign({ style: {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: 10,
                    width: "100%",
                } }, { children: [_jsxs(Pane, __assign({ display: "flex", flexDirection: "row", alignItems: "center" }, { children: [_jsx(Heading, __assign({ className: "headline", style: { color: "#49beef" } }, { children: "AUFTRAGSHISTORIE" })), _jsxs(Paragraph, __assign({ marginLeft: 15, className: "bodytext", style: { color: "#49beef" } }, { children: ["Job Nr. ", task === null || task === void 0 ? void 0 : task.task_job_no] }))] })), _jsx(CrossIcon, { style: { cursor: "pointer" }, onClick: function () { return setShown(false); } })] })), _jsxs(Pane, __assign({ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }, { children: [_jsx(Table, __assign({ style: { marginBottom: 20, margin: 10, width: "80%" } }, { children: _jsxs(Table.Body, { children: [_jsxs(Table.Row, { children: [_jsx(TableCell, { children: _jsxs(Pane, __assign({ flexDirection: "column" }, { children: [_jsx(TableTitle, { children: "KUNDE*IN" }), _jsx(TableText, { children: task === null || task === void 0 ? void 0 : task.customer_name })] })) }), _jsx(TableCell, { children: _jsxs(Pane, __assign({ flexDirection: "column" }, { children: [_jsx(TableTitle, { children: "FAHRER*IN" }), _jsx(TableText, { children: (driver === null || driver === void 0 ? void 0 : driver.firstName) + " " + (driver === null || driver === void 0 ? void 0 : driver.lastName) })] })) }), _jsx(TableCell, { children: _jsx(Tooltip, __assign({ content: task === null || task === void 0 ? void 0 : task.task_note, position: "right" }, { children: _jsxs(Pane, __assign({ flexDirection: "column" }, { children: [_jsx(TableTitle, { children: "NOTIZ" }), _jsx(TableText, { children: (_c = task === null || task === void 0 ? void 0 : task.task_note) !== null && _c !== void 0 ? _c : "Keine" })] })) })) })] }), tracker ? (_jsx(_Fragment, { children: ((task === null || task === void 0 ? void 0 : task.task_status) === "successfull" ||
                                        (task === null || task === void 0 ? void 0 : task.task_status) === "successful") && (_jsxs(Table.Row, { children: [_jsx(TableCell, { children: _jsxs(Pane, __assign({ flexDirection: "column" }, { children: [_jsx(TableTitle, { children: "TEMPERATUR-TRACKER" }), _jsx(TableText, { children: "Temp. bei Lieferung" })] })) }), !taskTempResponses || taskTempResponses.length === 0 ? (_jsx(TableCell, { children: _jsxs(Pane, __assign({ display: "flex", justifyContent: "center", alignItems: "center", gap: 10 }, { children: [_jsx(Spinner, { size: 24 }), _jsx(Paragraph, { children: "Loading..." })] })) })) : (taskTempStats.map(function (tracker, index) { return (_jsx(TableCell, { children: _jsxs(Pane, __assign({ flexDirection: "column" }, { children: [_jsxs(TableTitle, { children: [" ", tracker.trackerName, " "] }), _jsxs(TableText, { children: [tracker.avgTemp, "\u00B0C"] })] }), index) })); }))] })) })) : null, _jsxs(Table.Row, { children: [_jsx(TableCell, { children: _jsxs(Pane, __assign({ flexDirection: "column" }, { children: [_jsx(TableTitle, { children: "LIEFERDATUM" }), _jsx(TableText, { children: (_e = moment
                                                            .utc(((_d = task === null || task === void 0 ? void 0 : task.processed_date) === null || _d === void 0 ? void 0 : _d.seconds) * 1000)
                                                            // Change on Summertime
                                                            .add(1, "hour")
                                                            .format("DD.MM.YY HH:mm") + " Uhr") !== null && _e !== void 0 ? _e : moment(task === null || task === void 0 ? void 0 : task.task_date).format("DD.MM.YY HH:mm") + " Uhr" })] })) }), _jsx(TableCell, { children: _jsxs(Pane, __assign({ flexDirection: "column" }, { children: [_jsx(TableTitle, { children: "BETRAG" }), _jsx(TableText, { children: normaliseMoneyString(task === null || task === void 0 ? void 0 : task.task_open_amount) })] })) }), _jsx(TableCell, { children: _jsxs(Pane, __assign({ flexDirection: "column" }, { children: [_jsx(TableTitle, { children: "TOUR" }), (task === null || task === void 0 ? void 0 : task.task_tour) ? (_jsx(Button, __assign({ onClick: function () {
                                                            return history.push("/documentation?tourId=\"".concat(task === null || task === void 0 ? void 0 : task.task_tour, "\""));
                                                        } }, { children: "Zur Tour" }))) : (_jsx(Paragraph, { children: "Keiner zugewiesen" }))] })) })] })] }) })), _jsxs(Pane, __assign({ style: {
                            width: "20%",
                            display: "flex",
                            justifyContent: "flex-end",
                            flexDirection: "column",
                        } }, { children: [_jsx(TableTitle, { children: "UNTERSCHRIFT" }), signature ? (_jsxs(_Fragment, { children: [_jsxs(TableText, { children: ["Unterschrieben am", moment(((_f = signature === null || signature === void 0 ? void 0 : signature.timestamp) === null || _f === void 0 ? void 0 : _f.seconds) * 1000).format("DD.MM.YY HH:mm")] }), _jsx(SignatureViewer, { style: { width: "50%" }, image: signature === null || signature === void 0 ? void 0 : signature.msg })] })) : (_jsx(Paragraph, { children: "Keine Unterschrift" }))] }))] })), _jsx(Pane, __assign({ style: {
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    height: "100%",
                    padding: 10,
                } }, { children: _jsxs(Table, __assign({ style: { width: "100%" } }, { children: [_jsxs(Table.Head, { children: [_jsx(Table.TextHeaderCell, { children: "Status" }), _jsx(Table.TextHeaderCell, { children: "Beschreibung" }), _jsx(Table.TextHeaderCell, { children: "Uhrzeit" })] }), _jsx(Table.Body, __assign({ height: 350 }, { children: task === null || task === void 0 ? void 0 : task.task_history.map(function (entry) { return (_jsxs(Table.Row, __assign({ isSelectable: true, appearance: { overflow: "visible" } }, { children: [_jsx(Table.TextCell, { children: _jsx(StatusBadge, { status: entry.type, id: task.task_id, driver: true }) }), _jsxs(Table.TextCell, { children: [(entry === null || entry === void 0 ? void 0 : entry.title) && (_jsx(Paragraph, __assign({ style: { fontFamily: "bold" } }, { children: entry === null || entry === void 0 ? void 0 : entry.title }))), entry.type === "signature" ? (_jsx(SignatureViewer, { image: entry.msg })) : (_jsx(Tooltip, __assign({ content: entry.msg }, { children: _jsx(Paragraph, { children: entry.msg }) })))] }), _jsx(Table.TextCell, { children: moment(entry.timestamp.toDate()).format("DD.MM.YYYY, HH:mm:ss") })] }), entry.id)); }) }))] })) }))] })));
}
