var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Component } from "react";
import "../App.css";
import { Pane, Paragraph } from "evergreen-ui";
import LogoBig from "./LogoBig";
import LocationLabel from "./LocationLabel";
import moment from "moment";
import { normaliseMoneyString } from "../utility/normaliseMoneyString";
var TablePrintRow = function (_a) {
    var obj = _a.obj, index = _a.index;
    var el = null;
    try {
        switch (obj.type) {
            case "task":
                el = (_jsxs("tr", __assign({ style: {
                        borderWidth: 1,
                        borderColor: "black",
                        borderStyle: "solid",
                    } }, { children: [_jsx("td", { children: obj.data.task_type === "delivery" ? "Lieferung" : "Abholung" }), _jsx("td", { children: obj.data.customer_name }), _jsx("td", { children: obj.data.task_address }), _jsx("td", { children: normaliseMoneyString(obj.data.task_open_amount) }), _jsx("td", { children: obj.data.task_note }), _jsx("td", { children: obj.data.customer_phone }), _jsx("td", { children: _jsx(LocationLabel, { id: obj.data.task_origin }) })] })));
                break;
            case "action":
                el = _jsx("span", {});
        }
    }
    catch (e) { }
    return el;
};
var TourPrintLayout = /** @class */ (function (_super) {
    __extends(TourPrintLayout, _super);
    function TourPrintLayout() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TourPrintLayout.prototype.render = function () {
        var _a, _b, _c, _d;
        return (_jsxs(Pane, __assign({ style: { width: "100%", height: "100%", padding: 10 } }, { children: [_jsx(LogoBig, {}), _jsxs(Pane, __assign({ pageBreakBefore: "always", display: "flex", flexDirection: "row", alignItems: "center" }, { children: [_jsxs(Paragraph, { children: ["Datum:", " ", moment(this.props.tour.date, "YY-MM-DD").format("DD.MM.YYYY")] }), _jsxs(Paragraph, __assign({ marginLeft: "5" }, { children: ["Fahrer: ", (_a = this.props.driver) === null || _a === void 0 ? void 0 : _a.firstName, " ", (_b = this.props.driver) === null || _b === void 0 ? void 0 : _b.lastName] }))] })), _jsxs(Pane, __assign({ pageBreakBefore: "always", marginTop: "5", display: "flex", flexDirection: "row", alignItems: "center" }, { children: [_jsxs(Paragraph, { children: ["Startzeit: ", this.props.tour.startTime, " Uhr"] }), _jsxs(Paragraph, __assign({ marginLeft: "5" }, { children: ["Geplante Endzeit:", (_d = (_c = this.props.tour) === null || _c === void 0 ? void 0 : _c.activities.find(function (_a) {
                                    var type = _a.type;
                                    return type === "end";
                                })) === null || _d === void 0 ? void 0 : _d.action_planed_time, "Uhr"] }))] })), _jsxs("table", __assign({ pageBreakBefore: "always" }, { children: [_jsxs("tr", { children: [_jsx("th", { children: "Typ" }), _jsx("th", { children: "Name" }), _jsx("th", { children: "Adresse" }), _jsx("th", { children: "Betrag" }), _jsx("th", { children: "Notiz" }), _jsx("th", { children: "Telefonnummer" }), _jsx("th", { children: "Standort" })] }), this.props.areTasksInTour.map(function (obj, index) {
                            return _jsx(TablePrintRow, { obj: obj, index: index });
                        })] }))] })));
    };
    return TourPrintLayout;
}(Component));
export default TourPrintLayout;
