var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { EditIcon, IconButton, Menu, MinusIcon, MoreIcon, NumberedListIcon, Pane, Paragraph, PlusIcon, Popover, Position, PrintIcon, toaster, TrashIcon, } from "evergreen-ui";
import { useContext, useState } from "react";
import { LayoutContext } from "../contexts/LayoutContext";
import { TaskDialogsContext } from "../contexts/TaskDialogsContext";
import PrintingService from "../services/PrintingService";
import { usePlanInfo } from "../utility/use-plan-info";
export default function TaskEditButton(props) {
    var _this = this;
    var _a;
    function DeleteMenu(props) {
        switch (props.task.task_status) {
            case "unassigned":
                return (_jsx(Menu.Item, __assign({ onClick: function () {
                        setShownDialog({
                            type: "taskDeleteDialog",
                            task: props.task,
                        });
                    }, className: "bodytext", icon: TrashIcon, intent: "danger" }, { children: _jsx(Paragraph, __assign({ className: "bodytext" }, { children: "L\u00F6schen" })) })));
            case "assigned":
                return (_jsx(Menu.Item, __assign({ onClick: function () {
                        setShownDialog({
                            type: "removeTaskToTourDialog",
                            task: props.task,
                        });
                    }, className: "bodytext", icon: MinusIcon, intent: "danger" }, { children: _jsx(Paragraph, __assign({ className: "bodytext" }, { children: "Der Tour entziehen" })) })));
            default:
                return _jsx(Pane, {});
        }
    }
    var _b = useState(false), isShown = _b[0], setShown = _b[1];
    var _c = useState(false), showDeleteTask = _c[0], setShowDeleteTask = _c[1];
    var _d = useState(true), isDeleteActiveAction = _d[0], setDeleteActiveAction = _d[1];
    var _e = useState(0), formKey = _e[0], setFormKey = _e[1];
    var dialogSettings = useContext(TaskDialogsContext).dialogSettings;
    var setShownDialog = dialogSettings[1];
    var state = useContext(LayoutContext).state;
    var isEditMode = state[0], setEditMode = state[1];
    var currentPlan = usePlanInfo().currentPlan;
    function setDeleteAction(remove) {
        setDeleteActiveAction(remove);
        setShowDeleteTask(true);
    }
    function changeDialogKey() {
        setFormKey(formKey + 1);
    }
    return (_jsx(_Fragment, { children: _jsx(Popover, __assign({ position: Position.BOTTOM_LEFT, shouldCloseOnExternalClick: true, shouldCloseOnEscapePress: true, content: _jsxs(Menu, { children: [_jsxs(Menu.Group, __assign({ title: "Aktionen" }, { children: [_jsx(Menu.Item, __assign({ onClick: function () {
                                    setShownDialog({
                                        type: "taskEditDialog",
                                        task: props.task,
                                    });
                                }, icon: EditIcon }, { children: _jsx(Paragraph, __assign({ className: "bodytext" }, { children: "Bearbeiten" })) })), props.task.task_history && (_jsx(Menu.Item, __assign({ onClick: function () {
                                    setShownDialog({
                                        type: "taskHistory",
                                        task: props.task,
                                    });
                                }, className: "bodytext", icon: NumberedListIcon }, { children: _jsx(Paragraph, __assign({ className: "bodytext" }, { children: "Historie" })) }))), !isEditMode && currentPlan !== "apomap-lite" && (_jsx(Menu.Item, __assign({ onClick: function () { return __awaiter(_this, void 0, void 0, function () {
                                    var _a, _b;
                                    return __generator(this, function (_c) {
                                        switch (_c.label) {
                                            case 0:
                                                _b = (_a = console).log;
                                                return [4 /*yield*/, PrintingService.printTaskLabel(props.task, props.task.task_origin)];
                                            case 1:
                                                _b.apply(_a, [_c.sent()]);
                                                toaster.notify("Auftrags-Label wird gedruckt");
                                                return [2 /*return*/];
                                        }
                                    });
                                }); }, icon: PrintIcon, style: { cursor: "pointer" } }, { children: _jsx(Paragraph, __assign({ className: "bodytext" }, { children: "Label drucken" })) })))] })), _jsx(Menu.Divider, {}), _jsxs(Menu.Group, { children: [(props.task.task_status === "unassigned" ||
                                props.task.task_status === "prepared") && (_jsx(Menu.Item, __assign({ onClick: function () {
                                    setShownDialog({
                                        type: "addTaskToTourDialog",
                                        task: props.task,
                                        tasks: [],
                                    });
                                }, className: "bodytext", icon: PlusIcon }, { children: _jsx(Paragraph, __assign({ className: "bodytext" }, { children: "Einer Tour hinzuf\u00FCgen" })) }))), _jsx(DeleteMenu, { task: props.task, setDeleteAction: setDeleteAction })] })] }) }, { children: _jsx(IconButton, { disabled: (_a = props.disabled) !== null && _a !== void 0 ? _a : false, onClick: function () { return setShown(true); }, icon: MoreIcon, style: { borderWidth: 0, borderRadius: 50 }, marginLeft: 5 }) })) }));
}
