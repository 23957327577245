var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
//Popup-Window universal (business/customer)
//for organizing and doing video calls
import React, { useState, useEffect, useContext } from "react";
import { Pane, Button, Card, } from "evergreen-ui";
import firebase from "../config";
import "firebase/database";
import { UserContext } from "../contexts/UserContext";
import { fetchPushToken, sendPushMessage } from "../utility/utility";
import { Task } from "./Task";
require("firebase/firestore");
var calls = firebase
    .app()
    .database("https://apomapdreinull-default-rtdb.europe-west1.firebasedatabase.app/");
//database reference
var db = firebase.firestore();
//WebRTC - Ice server config
var configuration = {
    iceServers: [
        {
            urls: ["stun:stun.l.google.com:19302"],
        },
    ],
    iceCandidatePoolSize: 10,
};
//PeerConnection
var peerConnection;
//Stream of yourself
var localStream = null;
//Stream of peer
var remoteStream = null;
export default function Call(_a) {
    var callNode = _a.callNode;
    //if Popup window is shown
    var _b = useState(false), isShown = _b[0], setShown = _b[1];
    var _c = useState(false), hasOffer = _c[0], setHasOffer = _c[1];
    //connection state for UI-Changes
    //idle = no call nothing
    //connected = business and customer are connected
    //offering = customer is waiting for business
    var _d = useState("idle"), isState = _d[0], setState = _d[1];
    var _e = useState(), callDoc = _e[0], setCallDoc = _e[1];
    //Date selected for Scheduled call
    var _f = useState({
        label: "Termin",
        value: undefined,
    }), selectedDate = _f[0], setSelectedDate = _f[1];
    //Helper Array for date shift from available to booked
    var _g = useState([]), rawAvailableDateArray = _g[0], setRawAvailableDateArray = _g[1];
    var _h = useState([]), rawBookedDateArray = _h[0], setRawBookedDateArray = _h[1];
    //if scheduled call can be done ore customer is to late
    var _j = useState(false), isDisabled = _j[0], setDisabled = _j[1];
    //User Info
    var _k = useContext(UserContext), user = _k.user, ready = _k.ready;
    var isUser = user[0];
    useEffect(function () {
        // checkActive();
        checkOffer();
        return function () {
            hangUp();
        };
    }, []);
    var checkOffer = function () {
        firebase
            .firestore()
            .collection("app_calls")
            .doc(callNode)
            .get()
            .then(function (doc) {
            if (doc.exists) {
                setHasOffer(true);
                setCallDoc(doc.data());
            }
        });
    };
    //gets all streams and setsup medioa for call
    function setupStreams(type) {
        return __awaiter(this, void 0, void 0, function () {
            var constraints, localPlayer, remotePlayer;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        constraints = { audio: true, video: true };
                        //Setup peerconnection with stunservers
                        peerConnection = new RTCPeerConnection();
                        peerConnection.setConfiguration(configuration);
                        localPlayer = document.getElementById("localPlayer");
                        remotePlayer = document.getElementById("remotePlayer");
                        return [4 /*yield*/, navigator.mediaDevices.getUserMedia(constraints)];
                    case 1:
                        //Setup streams
                        localStream = _a.sent();
                        remoteStream = new MediaStream();
                        //Push Local track on video element
                        localStream.getTracks().forEach(function (track) {
                            peerConnection.addTrack(track, localStream);
                        });
                        //Get remotesteam listener
                        peerConnection.ontrack = function (event) {
                            event.streams[0].getTracks().forEach(function (track) {
                                remoteStream.addTrack(track);
                            });
                        };
                        //Get videoelements
                        localPlayer.srcObject = localStream;
                        remotePlayer.srcObject = remoteStream;
                        if (type === "customer") {
                            createOffer();
                        }
                        else {
                            answerOffer();
                        }
                        return [2 /*return*/];
                }
            });
        });
    }
    //creates call offer (customer)
    function createOffer() {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var callDoc, offerCanidates, answerCanidates, offerDescription, offer, token;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        callDoc = db.collection("app_calls").doc(callNode);
                        offerCanidates = callDoc.collection("offerCanidates");
                        answerCanidates = callDoc.collection("answerCanidates");
                        //Get Canidates for caller, save to db
                        peerConnection.onicecandidate = function (event) {
                            event.candidate && offerCanidates.add(event.candidate.toJSON());
                        };
                        return [4 /*yield*/, peerConnection.createOffer()];
                    case 1:
                        offerDescription = _c.sent();
                        return [4 /*yield*/, peerConnection.setLocalDescription(offerDescription)];
                    case 2:
                        _c.sent();
                        offer = {
                            sdp: offerDescription.sdp,
                            type: offerDescription.type,
                        };
                        return [4 /*yield*/, callDoc.set({ offer: offer })];
                    case 3:
                        _c.sent();
                        //Listen to Canages in the doc to detect
                        //Peer joining the call
                        callDoc.onSnapshot(function (snapshot) {
                            var data = snapshot.data();
                            if (!peerConnection.currentRemoteDescription && (data === null || data === void 0 ? void 0 : data.answer)) {
                                setState("connected");
                                var answerDescription = new RTCSessionDescription(data.answer);
                                peerConnection.setRemoteDescription(answerDescription);
                            }
                        });
                        //When answered, add canidate to peer connection
                        answerCanidates.onSnapshot(function (snapshot) {
                            snapshot.docChanges().forEach(function (change) {
                                if (change.type === "added") {
                                    var candidate = new RTCIceCandidate(change.doc.data());
                                    try {
                                        peerConnection.addIceCandidate(candidate);
                                    }
                                    catch (error) { }
                                }
                            });
                        });
                        setState("offering");
                        calls.ref("call_".concat(callNode)).push().set({ calling: true });
                        return [4 /*yield*/, fetchPushToken(callNode.split("_")[0])];
                    case 4:
                        token = _c.sent();
                        sendPushMessage({
                            token: [token],
                            title: (_a = "📞 Anruf von " + (isUser === null || isUser === void 0 ? void 0 : isUser.businessName)) !== null && _a !== void 0 ? _a : "Dashboard",
                            message: "Gehe nun ran!",
                        });
                        calls
                            .ref("notifications_".concat(callNode.split("_")[0]))
                            .push()
                            .set({
                            type: "call",
                            from: firebase.auth().currentUser.uid,
                            name: (_b = isUser === null || isUser === void 0 ? void 0 : isUser.businessName) !== null && _b !== void 0 ? _b : "Dashboard",
                        });
                        return [2 /*return*/];
                }
            });
        });
    }
    //answers offer (business)
    function answerOffer() {
        return __awaiter(this, void 0, void 0, function () {
            var callDoc, offerCanidates, answerCanidates, callData, offerDescription, answerDescription, answer;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        callDoc = db.collection("app_calls").doc(callNode);
                        offerCanidates = callDoc.collection("offerCanidates");
                        answerCanidates = callDoc.collection("answerCanidates");
                        peerConnection.onicecanidate = function (event) {
                            event.candidate && answerCanidates.add(event.candidate.toJSON());
                        };
                        return [4 /*yield*/, callDoc.get()];
                    case 1:
                        callData = (_a.sent()).data();
                        offerDescription = callData.offer;
                        return [4 /*yield*/, peerConnection.setRemoteDescription(new RTCSessionDescription(offerDescription))];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, peerConnection.createAnswer()];
                    case 3:
                        answerDescription = _a.sent();
                        return [4 /*yield*/, peerConnection.setLocalDescription(answerDescription)];
                    case 4:
                        _a.sent();
                        answer = {
                            type: answerDescription.type,
                            sdp: answerDescription.sdp,
                        };
                        return [4 /*yield*/, callDoc.update({ answer: answer })];
                    case 5:
                        _a.sent();
                        offerCanidates.onSnapshot(function (snapshot) {
                            snapshot.docChanges().forEach(function (change) {
                                if (change.type === "added") {
                                    setState("connected");
                                    var data = change.doc.data();
                                    try {
                                        peerConnection.addIceCandidate(new RTCIceCandidate(data));
                                    }
                                    catch (error) { }
                                }
                            });
                        });
                        setState("offering");
                        return [2 /*return*/];
                }
            });
        });
    }
    //Shifts selected date for call from available to booked
    // function shiftDate(date) {
    //   //gets seconds of selected date
    //   let seconds = date.getTime() / 1000;
    //   //gets the arrays and filters them
    //   let newAvailableDates = rawAvailableDateArray.filter(
    //     (data) => data.seconds !== seconds
    //   );
    //   let newBookedDates = rawBookedDateArray;
    //   newBookedDates.push(date);
    //   //changes dates of business
    //   db.collection("users")
    //     .doc(question.eMail)
    //     .update({
    //       availableCallDates: newAvailableDates,
    //       bookedCallDates: newBookedDates,
    //     })
    //     .then((doc) => {
    //       //sets calldate to request
    //       db.collection("app_questions").doc(question.id).update({
    //         callDate: selectedDate,
    //         cancelReason: firebase.firestore.FieldValue.delete(),
    //       });
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // }
    //hangsup of call
    function hangUp(type) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        //Triggers when cancel is pressed
                        //if call is booked book it
                        // if (selectedDate.value) {
                        //   shiftDate(selectedDate.value);
                        //   toaster.success("Termin gespeichert!");
                        // }
                        //kill local stream
                        if (localStream) {
                            localStream.getTracks().forEach(function (track) { return track.stop(); });
                        }
                        //kill remote steram
                        if (remoteStream) {
                            remoteStream.getTracks().forEach(function (track) { return track.stop(); });
                        }
                        if (document.getElementById("remotePlayer")) {
                            document.getElementById("remotePlayer").srcObject = null;
                        }
                        if (document.getElementById("localPlayer")) {
                            document.getElementById("localPlayer").srcObject = null;
                        }
                        if (!peerConnection) return [3 /*break*/, 2];
                        return [4 /*yield*/, peerConnection.close()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        //Delets call from database
                        db.collection("app_calls").doc(callNode).delete();
                        //delets call from realtime data base
                        calls.ref("call_".concat(callNode)).remove();
                        setState("idle");
                        setShown(false);
                        return [2 /*return*/];
                }
            });
        });
    }
    //Kills local stream if connected
    //only the peer is visible anymore not you
    function renderLocal() {
        if (isState !== "connected") {
            return (_jsx("video", { style: { width: "100%", heigth: "50%" }, id: "localPlayer", autoPlay: true, playsInline: true }));
        }
    }
    return (_jsx(Pane, __assign({ flex: "1", overflowY: "scroll", background: "tint1", padding: 16 }, { children: _jsx(Card, __assign({ backgroundColor: "white", elevation: 0, flexDirection: "column", padding: 20, display: "flex" }, { children: _jsxs("div", __assign({ style: { display: "flex", flexDirection: "column", heigth: "80%" } }, { children: [_jsx("video", { style: {
                            width: "100%",
                            heigth: "50%",
                            display: isState !== "connected" ? "none" : "inline",
                        }, id: "remotePlayer", autoPlay: true, playsInline: true }), _jsx("video", { style: {
                            width: "100%",
                            heigth: "50%",
                            display: isState !== "idle" && isState !== "connected"
                                ? "inline"
                                : "none",
                        }, id: "localPlayer", autoPlay: true, playsInline: true }), (callDoc === null || callDoc === void 0 ? void 0 : callDoc.task) && (_jsx(Task, { task: callDoc === null || callDoc === void 0 ? void 0 : callDoc.task, index: 1 }, callDoc === null || callDoc === void 0 ? void 0 : callDoc.task.task_id)), hasOffer ? (_jsx(Button, __assign({ onClick: function () { return setupStreams("business"); } }, { children: "Annehmen" }))) : (_jsx(Button, __assign({ onClick: function () { return setupStreams("customer"); } }, { children: "Anrufen" }))), isState !== "idle" && (_jsx(Button, __assign({ onClick: function () { return hangUp(); } }, { children: "Auflegen" })))] })) })) })));
}
