var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, CrossIcon, Pane, Paragraph, TextInput } from "evergreen-ui";
import React, { useState } from "react";
//@ts-ignore
import { motion } from "framer-motion/dist/framer-motion";
export var FavoritesEditRow = function (_a) {
    var loading = _a.loading, editMode = _a.editMode, createGroup = _a.createGroup, setEditMode = _a.setEditMode;
    var _b = useState(""), groupName = _b[0], setGroupName = _b[1];
    return (_jsx(_Fragment, { children: editMode && (_jsx(motion.div, __assign({ hover: { scale: 0.95 }, initial: { opacity: 0, y: 100 }, animate: {
                y: editMode ? 0 : 50,
                opacity: editMode ? 1 : 0,
            }, transition: { duration: 0.5 }, style: { position: "absolute", zIndex: 200, bottom: 20 } }, { children: _jsxs(Pane, __assign({ elevation: 2, display: "flex", padding: 15, justifyContent: "space-between", alignItems: "center", position: "relative", style: {
                    backgroundColor: "#2D3B51",
                    width: "65vw",
                    borderRadius: 24,
                } }, { children: [_jsxs("div", { children: [_jsx(Paragraph, __assign({ color: "white", fontWeight: "bold", style: { fontSize: "16px" } }, { children: "Favoritengruppe erstellen" })), _jsx(Paragraph, __assign({ color: "white" }, { children: "W\u00E4hle die Favoriten aus, vergib einen Namen und klick \"Erstellen\"" }))] }), _jsxs(Pane, __assign({ display: "flex", justifyItems: "center", alignItems: "center", gap: 10 }, { children: [_jsx(TextInput, { placeholder: "Gruppenname", value: groupName, 
                                //@ts-ignore
                                onChange: function (e) { return setGroupName(e.target.value); } }), _jsx(Button, __assign({ isLoading: loading, onClick: function () { return createGroup(groupName); }, disabled: groupName === "" }, { children: "Erstellen" })), _jsx(CrossIcon, { onClick: function () { return setEditMode(false); }, marginLeft: 14, cursor: "pointer", color: "white" })] }))] })) }))) }));
};
