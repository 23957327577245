var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import { Pane, Paragraph, Heading, Button, toaster } from "evergreen-ui";
import { ThemeContext } from "evergreen-ui";
import LogoBig from "../components/LogoBig";
import theme from "../theme";
import ApoTextInput from "../components/ApoTextInput";
import { Link, useHistory } from "react-router-dom";
import validator from "email-validator";
import FooterBar from "../components/FooterBar";
import firebase from "../config";
import "firebase/auth";
import "firebase/firestore";
var ThemeProvider = ThemeContext.Provider;
export default function SignUp() {
    var _a = useState(), isEmail = _a[0], setEmail = _a[1];
    var _b = useState(), isPassword = _b[0], setPassword = _b[1];
    var _c = useState(true), available = _c[0], setAvailable = _c[1];
    var _d = useState(), isPasswordCheck = _d[0], setPasswordCheck = _d[1];
    var _e = useState(false), isLoading = _e[0], setLoading = _e[1];
    var history = useHistory();
    function registerForAccount() {
        if (isPassword !== isPasswordCheck) {
            toaster.warning("Die Passwörter stimmen nicht überein");
            return;
        }
        if (!validator.validate(isEmail)) {
            toaster.warning("Bitte geben Sie eine gültige E-Mail Adresse an!");
            return;
        }
        setLoading(true);
        firebase
            .auth()
            .createUserWithEmailAndPassword(isEmail, isPassword)
            .then(function () {
            setLoading(false);
            toaster.success("Ihre Anmeldung war erfolgreich! Beginnen Sie den Onboarding-Prozess.");
            setTimeout(function () {
                history.push("/onboarding/1");
            }, 500);
        })
            .catch(function (err) {
            setLoading(false);
            console.log(err);
            if (err.code === "auth/email-already-in-use") {
                toaster.warning("Diese E-Mail Adresse kann nicht verwendet werden!");
                return;
            }
            if (err.code === "auth/weak-password") {
                toaster.warning("Ihr Passwort ist zu schwach! Nutzen Sie mindestens 6 Zeichen.");
                return;
            }
            setLoading(false);
        });
    }
    useEffect(function () {
        firebase
            .firestore()
            .collection("settings")
            .doc("availability")
            .get()
            .then(function (doc) {
            var data = doc.data();
            setAvailable(data.signUp);
        })
            .catch(function (err) { return console.log(err); });
    }, []);
    return (_jsxs(ThemeProvider, __assign({ value: theme }, { children: [_jsxs(Pane, __assign({ style: {
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                } }, { children: [_jsxs(Pane, __assign({ style: {
                            height: "100%",
                            width: "60%",
                            padding: 35,
                            display: "flex",
                            flexDirection: "column",
                            background: "linear-gradient #E4EBF5 0%, rgba(255,255,255,1) 100%)",
                        }, onKeyPress: function (e) { return e.key === "Enter" && registerForAccount(); } }, { children: [_jsx(Pane, { children: _jsx(LogoBig, {}) }), _jsxs(Pane, __assign({ padding: 10 }, { children: [_jsx(Heading, __assign({ fontSize: "48px", color: "#2D3B51", size: "large" }, { children: "Neuen Account erstellen" })), _jsx(Paragraph, { children: "Bitte f\u00FClle alle mit * gekennzeichneten Felder aus" }), _jsxs(Pane, __assign({ marginTop: 15, width: "50%" }, { children: [_jsx(ApoTextInput, { label: _jsx(Paragraph, __assign({ fontFamily: "bold" }, { children: "E-Mail Adresse*" })), spellCheck: false, placeholder: "max.munstermann@apomap.de", value: isEmail, onChange: function (e) { return setEmail(e.target.value); } }), _jsx(ApoTextInput, { label: _jsx(Paragraph, __assign({ fontFamily: "bold" }, { children: "Passwort*" })), placeholder: "min. 6 Zeichen", spellCheck: false, type: "password", value: isPassword, onChange: function (e) { return setPassword(e.target.value); } }), _jsx(ApoTextInput, { label: _jsx(Paragraph, __assign({ fontFamily: "bold" }, { children: "Passwort best\u00E4tigen*" })), spellCheck: false, type: "password", placeholder: "min. 6 Zeichen", value: isPasswordCheck, onChange: function (e) { return setPasswordCheck(e.target.value); } }), _jsx(Paragraph, { children: "Wir schlie\u00DFen keine Vertr\u00E4ge mit Verbrauchern (\u00A7 13 BGB). Bitte beachte unsere Hinweise zum Datenschutz. Es gelten unsere allgemeinen Gesch\u00E4ftsbedingungen." }), _jsx(Button, __assign({ appearance: "primary", backgroundColor: "#2D3B51", marginTop: 15, size: "large", onClick: function () { return registerForAccount(); }, isLoading: isLoading }, { children: "Registrierung starten" }))] }))] }))] })), _jsxs(Pane, __assign({ style: {
                            height: "100%",
                            width: "40%",
                            backgroundColor: "#49beef",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                            padding: 35,
                        } }, { children: [_jsx(Heading, __assign({ fontSize: "48px", size: "large", color: "white", fontWeight: 800 }, { children: "Du hast einen Account?" })), _jsx(Paragraph, __assign({ fontFamily: "bold", width: "50%", marginTop: 10, color: "white" }, { children: "Dann logge dich jetzt in deinen bestehenden Account ein" })), _jsx(Link, __assign({ style: { textDecoration: "none" }, to: "/signin" }, { children: _jsx(Button, __assign({ backgroundColor: "#2D3B51", marginTop: 10, size: "large", appearance: "primaryWhite" }, { children: "Zum Login" })) }))] }))] })), _jsx(FooterBar, {})] })));
}
