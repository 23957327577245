var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import moment from "moment";
import firebase from "../config";
import useTaskTempStore from "../store/taskTempStore";
import useTempTrackerStore from "../store/tempTrackerStore";
var makeRequest = function (tracker, startDate, endDate, startTime, endTime) { return __awaiter(void 0, void 0, void 0, function () {
    var authAtTec4MedSecure, data, token, startTimeISO, endTimeISO, url, response, tempData, transformedData_1, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 4, , 5]);
                authAtTec4MedSecure = firebase
                    .app()
                    .functions("europe-west3")
                    .httpsCallable("authAtTec4MedSecure");
                return [4 /*yield*/, authAtTec4MedSecure()];
            case 1:
                data = (_a.sent()).data;
                token = data.token;
                startTimeISO = moment
                    .utc("".concat(startDate).concat(startTime), "YY-MM-DD HH:mm")
                    .toISOString();
                endTimeISO = endDate && endTime
                    ? moment.utc("".concat(endDate).concat(endTime), "YY-MM-DD HH:mm").toISOString()
                    : moment().endOf("day").toISOString();
                url = "https://tracking.tec4med.com/api/device/distinct/".concat(tracker.trackerId, "/measurements/").concat(startTimeISO, "/").concat(endTimeISO);
                return [4 /*yield*/, fetch(url, {
                        method: "GET",
                        headers: {
                            Authorization: "Bearer ".concat(token),
                        },
                    })];
            case 2:
                response = _a.sent();
                return [4 /*yield*/, response.json()];
            case 3:
                tempData = _a.sent();
                transformedData_1 = {
                    trackerName: tracker.trackerName,
                    temp: [],
                    time: [],
                };
                tempData.forEach(function (item) {
                    var timestamp = moment.utc(item.timestamp);
                    transformedData_1.temp.push(item.temp);
                    var momentTime = moment(timestamp, "HH:mm").format("HH:mm");
                    transformedData_1.time.push(momentTime);
                });
                return [2 /*return*/, transformedData_1];
            case 4:
                error_1 = _a.sent();
                console.error("Error fetching data for Tracker ".concat(tracker.trackerId, ":"), error_1);
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}); };
var processTrackers = function (trackers, startDate, endDate, startTime, endTime, isTaskTemp // 0 = false, 1 = true
) { return __awaiter(void 0, void 0, void 0, function () {
    var requests, transformedDataArray, filteredDataArray, error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                // Make a request for each tracker
                if (!trackers) {
                    useTempTrackerStore.getState().setResponse([]);
                    return [2 /*return*/];
                }
                requests = trackers.map(function (tracker) {
                    return makeRequest(tracker, startDate, endDate, startTime, endTime);
                });
                return [4 /*yield*/, Promise.all(requests)];
            case 1:
                transformedDataArray = _a.sent();
                filteredDataArray = transformedDataArray.filter(function (data) { return data !== undefined; });
                if (isTaskTemp === 1) {
                    useTaskTempStore.getState().setTaskTempResponse(filteredDataArray);
                }
                else {
                    useTempTrackerStore.getState().setResponse(filteredDataArray);
                }
                return [3 /*break*/, 3];
            case 2:
                error_2 = _a.sent();
                console.error("Error processing trackers:", error_2);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export default processTrackers;
