import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Checkbox, EditIcon, IconButton, Table, TrashIcon } from "evergreen-ui";
import React, { useEffect, useImperativeHandle, useState } from "react";
var FavoritesRow = React.forwardRef(function (_a, ref) {
    var _b;
    var fav = _a.favorite, setSelectedFav = _a.setSelectedFav, setShown = _a.setShown, remove = _a.remove, editMode = _a.editMode, s = _a.selected;
    var _c = useState(false), selected = _c[0], setSelected = _c[1];
    useEffect(function () {
        if (s)
            setSelected(s);
    }, [s]);
    useImperativeHandle(ref, function () {
        return {
            getSelected: function () { return ({ id: fav.uid, state: selected }); },
        };
    });
    return (_jsxs(Table.Row, { children: [editMode && (_jsx(Checkbox, { marginTop: 24, checked: selected, onChange: function (e) { return setSelected(e.target.checked); }, marginLeft: 8 })), _jsx(Table.TextCell, { children: fav === null || fav === void 0 ? void 0 : fav.name }), _jsx(Table.TextCell, { children: (_b = fav === null || fav === void 0 ? void 0 : fav.location) === null || _b === void 0 ? void 0 : _b.address }), _jsxs(Table.TextCell, { children: [_jsx(IconButton, { onClick: function () {
                            setSelectedFav(fav);
                            setShown({ visable: true, type: "update" });
                        }, marginRight: 15, icon: EditIcon }), _jsx(IconButton, { onClick: function () { return remove(fav); }, icon: TrashIcon, intent: "danger" })] })] }));
});
export default FavoritesRow;
