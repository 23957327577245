var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AddIcon, Button, CrossIcon, DuplicateIcon, ExchangeIcon, ExpandAllIcon, LabelIcon, Pane, Paragraph, PrintIcon, Spinner, toaster, TrashIcon, } from "evergreen-ui";
import "firebase/firestore";
import { useContext, useMemo, useState } from "react";
import firebase from "../../../config";
//@ts-ignore
import { motion } from "framer-motion/dist/framer-motion";
import { TaskDialogsContext } from "../../../contexts/TaskDialogsContext";
import { TaskEditContext } from "../../../contexts/TaskEditContext";
import PrintingService from "../../../services/PrintingService";
import { UserContext } from "../../../contexts/UserContext";
import { parseAddress } from "../../../utility/parseAddress";
import { usePlanInfo } from "../../../utility/use-plan-info";
export var EditActionBar = function (_a) {
    var active = _a.active, showActionBar = _a.showActionBar, setEditMode = _a.setEditMode, setShowActionBar = _a.setShowActionBar, shownTasks = _a.shownTasks;
    var _b = useContext(TaskEditContext), tasks = _b.tasks, setTasks = _b.setTasks;
    var _c = useState(false), deleteLoading = _c[0], setDeleteLoading = _c[1];
    var _d = useState(false), preparedLoading = _d[0], setPreparedLoading = _d[1];
    var _e = useState(false), addLoading = _e[0], setAddLoading = _e[1];
    var currentPlan = usePlanInfo().currentPlan;
    //@ts-ignore
    var dialogSettings = useContext(TaskDialogsContext).dialogSettings;
    var setShownDialog = dialogSettings[1];
    var _f = useState(false), openActionsBox = _f[0], setOpenActionsBox = _f[1];
    var _g = useState(false), isGlsLoading = _g[0], setIsGlsLoading = _g[1];
    // @ts-ignore
    var user = useContext(UserContext).user;
    var isUser = user[0];
    var endEditMode = function () {
        if (setTasks) {
            setTasks([]);
        }
        setEditMode(false);
        setShowActionBar(false);
        setOpenActionsBox(false);
        setIsGlsLoading(false);
    };
    // Filters selected tasks to make sure attached tasks are not deleted
    var tasksToDelete = useMemo(function () {
        return tasks.filter(function (taskId) {
            var task = shownTasks.find(function (item) { return item.task_id === taskId; });
            return task
                ? task.task_status !== "attached" &&
                    task.task_status !== "assigned" &&
                    task.task_status !== "ontheway"
                : true;
        });
    }, [shownTasks, tasks]);
    var deleteTasks = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setDeleteLoading(true);
                    setOpenActionsBox(false);
                    return [4 /*yield*/, Promise.all(tasksToDelete.map(function (id) {
                            return firebase.firestore().collection("tasks").doc(id).delete();
                        }))];
                case 1:
                    _a.sent();
                    if (setTasks) {
                        setTasks([]);
                    }
                    setDeleteLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var markPrepared = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setPreparedLoading(true);
                    setOpenActionsBox(false);
                    return [4 /*yield*/, Promise.all(tasks.map(function (id) {
                            return firebase
                                .firestore()
                                .collection("tasks")
                                .doc(id)
                                .update({ task_status: "prepared" });
                        }))];
                case 1:
                    _a.sent();
                    if (setTasks) {
                        setTasks([]);
                    }
                    setPreparedLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var addToTour = function () { return __awaiter(void 0, void 0, void 0, function () {
        var toAddTasks;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setAddLoading(true);
                    setOpenActionsBox(false);
                    return [4 /*yield*/, Promise.all(tasks.map(function (id) { return __awaiter(void 0, void 0, void 0, function () {
                            var doc;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, firebase
                                            .firestore()
                                            .collection("tasks")
                                            .doc(id)
                                            .get()];
                                    case 1:
                                        doc = _a.sent();
                                        return [2 /*return*/, doc.data()];
                                }
                            });
                        }); }))];
                case 1:
                    toAddTasks = _a.sent();
                    setShownDialog({
                        type: "addTaskToTourDialog",
                        task: undefined,
                        tasks: toAddTasks,
                    });
                    if (setTasks) {
                        setTasks([]);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var handleLabelPrinting = function () { return __awaiter(void 0, void 0, void 0, function () {
        var promises;
        return __generator(this, function (_a) {
            setPreparedLoading(true);
            setOpenActionsBox(false);
            promises = [];
            tasks.forEach(function (id) { return __awaiter(void 0, void 0, void 0, function () {
                var taskRef, task;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, firebase
                                .firestore()
                                .collection("tasks")
                                .doc(id)
                                .get()];
                        case 1:
                            taskRef = _a.sent();
                            task = taskRef.data();
                            // ToDo: Check that this is the correct location id
                            promises.push(PrintingService.printTaskLabel(task, task.task_origin));
                            return [2 /*return*/];
                    }
                });
            }); });
            setPreparedLoading(false);
            return [2 /*return*/];
        });
    }); };
    var mergeTasks = function () { return __awaiter(void 0, void 0, void 0, function () {
        var toAddTasks;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setOpenActionsBox(false);
                    return [4 /*yield*/, Promise.all(tasks.map(function (id) { return __awaiter(void 0, void 0, void 0, function () {
                            var doc;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, firebase
                                            .firestore()
                                            .collection("tasks")
                                            .doc(id)
                                            .get()];
                                    case 1:
                                        doc = _a.sent();
                                        return [2 /*return*/, doc.data()];
                                }
                            });
                        }); }))];
                case 1:
                    toAddTasks = _a.sent();
                    setShownDialog({
                        type: "taskEditDialog",
                        task: __assign(__assign({}, toAddTasks[0]), { customer_name: "", task_open_amount: toAddTasks.reduce(function (a, b) { var _a; return a + (b ? parseFloat((_a = b.task_open_amount) !== null && _a !== void 0 ? _a : "0") : 0); }, 0) }),
                        tasks: toAddTasks,
                    });
                    endEditMode();
                    return [2 /*return*/];
            }
        });
    }); };
    var handleGlsTasks = function () { return __awaiter(void 0, void 0, void 0, function () {
        var glsTasks, createGlsTask, parsedGlsTasks, payload;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsGlsLoading(true);
                    return [4 /*yield*/, Promise.all(tasks.map(function (id) { return __awaiter(void 0, void 0, void 0, function () {
                            var doc;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, firebase
                                            .firestore()
                                            .collection("tasks")
                                            .doc(id)
                                            .get()];
                                    case 1:
                                        doc = _a.sent();
                                        return [2 /*return*/, doc.data()];
                                }
                            });
                        }); }))];
                case 1:
                    glsTasks = _a.sent();
                    createGlsTask = firebase
                        .app()
                        .functions("europe-west3")
                        .httpsCallable("createGlsTask");
                    parsedGlsTasks = glsTasks.map(function (task) {
                        var parsedAddress = parseAddress(task === null || task === void 0 ? void 0 : task.task_address);
                        return __assign(__assign({}, task), { task_address: parsedAddress });
                    });
                    payload = {
                        tasks: parsedGlsTasks,
                    };
                    createGlsTask(payload).then(function (result) {
                        if (result.data.msg === "success") {
                            setIsGlsLoading(false);
                            toaster.success("Aufträge erfolgreich an GLS übertragen");
                        }
                        else {
                            setIsGlsLoading(false);
                            toaster.danger("Aufträge konnten nicht an GLS übertragen werden");
                        }
                    });
                    if (setTasks) {
                        setTasks([]);
                    }
                    setIsGlsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsx(_Fragment, { children: active && showActionBar && (_jsx(motion.div, __assign({ hover: { scale: 0.95 }, initial: { opacity: 0, y: 100 }, animate: {
                y: active ? 0 : 50,
                opacity: active ? 1 : 0,
            }, transition: { duration: 0.5 }, style: { position: "absolute", zIndex: 200, bottom: 20 } }, { children: _jsxs(Pane, __assign({ elevation: 2, display: "flex", padding: 15, justifyContent: "space-between", alignItems: "center", position: "relative", style: {
                    backgroundColor: "#2D3B51",
                    width: "65vw",
                    borderRadius: 24,
                } }, { children: [isGlsLoading ? (_jsxs(_Fragment, { children: [_jsx(Spinner, {}), _jsx(Paragraph, { children: "Bitte warten Sie, bis die GLS Auftr\u00E4ge erstellt wurden" })] })) : (_jsxs(_Fragment, { children: [_jsxs(Paragraph, __assign({ color: "white", fontWeight: "bold", marginBottom: "6px", style: { fontSize: "16px" } }, { children: [tasks.length, " ausgew\u00E4hlte Auftr\u00E4ge"] })), _jsxs(Pane, __assign({ display: "flex", justifyItems: "center", alignItems: "center", gap: 10 }, { children: [_jsx(Button, __assign({ style: {
                                            color: "black",
                                            padding: "1rem",
                                            borderRadius: "3rem",
                                            border: "none",
                                        }, onClick: function () { return setOpenActionsBox(function (prev) { return !prev; }); }, iconBefore: ExpandAllIcon }, { children: "Aktionen" })), _jsx(CrossIcon, { marginLeft: 14, onClick: endEditMode, cursor: "pointer", color: "white" })] }))] })), openActionsBox && (_jsxs(Pane, __assign({ style: {
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-evenly",
                            gap: "0.5rem",
                            overflowY: "scroll",
                            backgroundColor: "#2D3B51",
                            padding: "1rem",
                            height: "300px",
                            borderRadius: "12px",
                            position: "absolute",
                            transform: "translateY(-100%)",
                            top: 0,
                            right: 0,
                        } }, { children: [(isUser === null || isUser === void 0 ? void 0 : isUser.shouldSeeGls) ? (_jsx(Button, __assign({ style: {
                                    color: "black",
                                    padding: "1rem",
                                    borderRadius: "3rem",
                                }, disabled: tasks.length < 1, onClick: handleGlsTasks, iconBefore: ExchangeIcon }, { children: "An GLS ubertragen" }))) : null, _jsx(Button, __assign({ style: {
                                    color: "black",
                                    padding: "1rem",
                                    borderRadius: "3rem",
                                }, disabled: tasks.length < 2, onClick: mergeTasks, iconBefore: DuplicateIcon }, { children: "Sammelauftrag" })), currentPlan !== "apomap-lite" ? (_jsx(Button, __assign({ onClick: function () { return handleLabelPrinting(); }, disabled: tasks.length === 0, iconBefore: PrintIcon, style: {
                                    color: "black",
                                    padding: "1rem",
                                    borderRadius: "3rem",
                                    cursor: "pointer",
                                } }, { children: "Label drucken" }))) : null, _jsx(Button, __assign({ onClick: addToTour, disabled: tasks.length === 0, iconBefore: AddIcon, style: {
                                    color: "black",
                                    padding: "1rem",
                                    borderRadius: "3rem",
                                } }, { children: "Einer Tour hinzuf\u00FCgen" })), _jsx(Button, __assign({ isLoading: preparedLoading, iconBefore: LabelIcon, marginLeft: 4, onClick: markPrepared, disabled: tasks.length === 0, style: {
                                    color: "black",
                                    padding: "1rem",
                                    borderRadius: "3rem",
                                } }, { children: "Vorbereitet markieren" })), tasksToDelete.length > 0 ? (_jsx(Button, __assign({ onClick: deleteTasks, marginLeft: 4, iconBefore: _jsx(TrashIcon, {}), intent: "danger", isLoading: deleteLoading, disabled: tasks.length === 0, style: {
                                    color: "red",
                                    padding: "1rem",
                                    borderRadius: "3rem",
                                } }, { children: "L\u00F6schen" }))) : null] })))] })) }))) }));
};
