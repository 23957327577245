var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
//This hook manages all logic for the kbar
import _debounce from "lodash/debounce";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { create } from "zustand";
import firebase from "../../../config";
var initialDocumentationState = {
    documentationOffset: 0,
    documentationFilter: null,
    documentationSorting: "desc",
    documentationResults: [],
    offset: 0,
};
var initialBarState = {
    searchType: "CUSTOMER_NAME",
    isLoading: false,
    commandBarOpen: false,
};
//Zustand Store Hook
var useDocumentationStore = create(function (set) { return (__assign(__assign(__assign({}, initialBarState), initialDocumentationState), { setDocumentationSorting: function (documentationSorting) {
        return set(function () { return ({ documentationSorting: documentationSorting }); });
    }, setDocumentationFilter: function (documentationFilter) {
        return set(function () { return ({ documentationFilter: documentationFilter }); });
    }, setDocumentationOffset: function (documentationOffset) {
        return set(function () { return ({ documentationOffset: documentationOffset }); });
    }, setDocumentationResults: function (documentationResults) {
        return set(function () { return ({ documentationResults: documentationResults }); });
    }, setOffset: function (offset) { return set(function () { return ({ offset: offset }); }); }, incrementOffset: function () { return set(function (state) { return ({ offset: state.offset + 5 }); }); }, decrementOffset: function () { return set(function (state) { return ({ offset: state.offset - 5 }); }); }, changeSearchType: function (searchType) { return set(function () { return ({ searchType: searchType }); }); }, setLoading: function (isLoading) { return set(function () { return ({ isLoading: isLoading }); }); }, toggleCommandBar: function () {
        return set(function (state) { return ({ commandBarOpen: !state.commandBarOpen }); });
    }, resetBar: function () { return set(function () { return (__assign({}, initialBarState)); }); } })); });
//Wrapper-Hook to add logic to the pure zustand store
export var useDocumentation = function () {
    var _a = useDocumentationStore(), searchType = _a.searchType, toggleCommandBar = _a.toggleCommandBar, isLoading = _a.isLoading, setLoading = _a.setLoading, commandBarOpen = _a.commandBarOpen, changeSearchType = _a.changeSearchType, offset = _a.offset, setOffset = _a.setOffset, setDocumentationResults = _a.setDocumentationResults, documentationResults = _a.documentationResults, documentationOffset = _a.documentationOffset, setDocumentationOffset = _a.setDocumentationOffset, documentationFilter = _a.documentationFilter, setDocumentationFilter = _a.setDocumentationFilter, documentationSorting = _a.documentationSorting, setDocumentationSorting = _a.setDocumentationSorting, resetBar = _a.resetBar;
    var _b = useState(""), searchInput = _b[0], setSearchInput = _b[1];
    var _c = useState([]), searchResults = _c[0], setSearchResults = _c[1];
    var _d = useState(false), paginationDisabled = _d[0], setPaginationDisabled = _d[1];
    //Calls the search endpoint with different fields depending on the searchType
    var searchInCloud = function (input, offset) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, new Promise(function (res, rej) { return __awaiter(void 0, void 0, void 0, function () {
                    var search;
                    return __generator(this, function (_a) {
                        setLoading(true);
                        search = firebase
                            .app()
                            .functions("europe-west3")
                            .httpsCallable("search_v2");
                        return [2 /*return*/, search(__assign(__assign(__assign(__assign({}, (searchType === "CUSTOMER_NAME" && { customer_name: input })), (searchType === "JOB_NO" && { task_job_no: input })), (searchType === "TASK_ADDRESS" && { task_address: input })), { offset: offset }))
                                .then(function (result) { return res(result); })
                                .catch(function (err) { return rej(err); })
                                .finally(function () { return setLoading(false); })];
                    });
                }); })];
        });
    }); };
    var searchDocumentation = function (filters, offset, sorting) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, new Promise(function (res, rej) { return __awaiter(void 0, void 0, void 0, function () {
                    var search;
                    return __generator(this, function (_a) {
                        setLoading(true);
                        search = firebase
                            .app()
                            .functions("europe-west3")
                            .httpsCallable("documentation");
                        return [2 /*return*/, search(__assign(__assign({ startDate: filters.startDate, endDate: filters.endDate }, (filters.driverIds && { driverIds: filters.driverIds })), { offset: offset, sorting: sorting }))
                                .then(function (result) { return res(result); })
                                .catch(function (err) { return rej(err); })
                                .finally(function () { return setLoading(false); })];
                    });
                }); })];
        });
    }); };
    //Converts the data from tasks into proper format
    var convertData = function (input) {
        return input.map(function (task) { return ({
            customer_name: task._source.customer_name,
            task_id: task._source.task_id,
            task_address: task._source.task_address,
            task_status: task._source.task_status,
            task_date: moment
                .unix(task._source.task_date._seconds)
                .format("DD.MM.YYYY"),
            task_job_no: task._source.task_job_no,
            task_tour: task._source.task_tour,
        }); });
    };
    var resetBarState = function () {
        resetBar();
        setSearchInput("");
    };
    //Setter Function in Debounce
    var doSearch = useCallback(function (searchInput, offset) { return __awaiter(void 0, void 0, void 0, function () {
        var result, data, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, searchInCloud(searchInput, offset)];
                case 1:
                    result = _a.sent();
                    data = convertData(result.data.result);
                    //@ts-ignore
                    setSearchResults(data);
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    setSearchResults([]);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchType, offset, searchInput]);
    //Debounces the search from the input
    // eslint-disable-next-line react-hooks/exhaustive-deps
    var debounceSearch = useCallback(_debounce(doSearch, 1000), [searchType]);
    //When input in command bar changes
    var changeSearchInput = function (input) {
        setSearchInput(input);
        debounceSearch(input);
    };
    var convertToTours = function (input) {
        return input.map(function (tour) {
            var _source = tour._source;
            return __assign({}, _source);
        });
    };
    var getDocumentation = useCallback(function (filters, offset, sorting) { return __awaiter(void 0, void 0, void 0, function () {
        var result, data, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    setDocumentationFilter(filters);
                    return [4 /*yield*/, searchDocumentation(filters, offset !== null && offset !== void 0 ? offset : documentationOffset, sorting !== null && sorting !== void 0 ? sorting : "desc")];
                case 1:
                    result = _a.sent();
                    data = convertToTours(result.data.result);
                    if (data)
                        setDocumentationResults(data);
                    return [3 /*break*/, 3];
                case 2:
                    error_2 = _a.sent();
                    console.log(error_2);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    var nextPage = function (offset) {
        setPaginationDisabled(true);
        setOffset(offset);
        debounceSearch(searchInput, offset);
    };
    var previousPage = function (offset) {
        setPaginationDisabled(true);
        setOffset(offset);
        debounceSearch(searchInput, offset);
    };
    useEffect(function () {
        setPaginationDisabled(false);
    }, [searchResults]);
    //For refetching of the documentation, when filters change
    useEffect(function () {
        if (documentationFilter) {
            getDocumentation(documentationFilter, documentationOffset, documentationSorting);
        }
    }, [documentationOffset, documentationSorting]);
    //For resetting the bar when closed
    useEffect(function () {
        if (!commandBarOpen)
            resetBarState();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [commandBarOpen]);
    //For resetting input field when the search type is changed
    useEffect(function () {
        setSearchInput("");
    }, [searchType]);
    return {
        searchType: searchType,
        toggleCommandBar: toggleCommandBar,
        isLoading: isLoading,
        commandBarOpen: commandBarOpen,
        changeSearchType: changeSearchType,
        searchInput: searchInput,
        changeSearchInput: changeSearchInput,
        searchResults: searchResults,
        offset: offset,
        nextPage: nextPage,
        previousPage: previousPage,
        getDocumentation: getDocumentation,
        documentationResults: documentationResults,
        setDocumentationOffset: setDocumentationOffset,
        documentationOffset: documentationOffset,
        documentationSorting: documentationSorting,
        setDocumentationSorting: setDocumentationSorting,
        paginationDisabled: paginationDisabled,
    };
};
