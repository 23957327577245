var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Checkbox, Combobox, CrossIcon, Dialog, Heading, Pane, Paragraph, RadioGroup, Switch, Table, ThemeContext, toaster, } from "evergreen-ui";
import "firebase/auth";
import "firebase/firestore";
import moment from "moment";
import { useCallback, useContext, useEffect, useMemo, useRef, useState, } from "react";
import { AddonHintBox } from "../components/Addons";
import { useAddons } from "../components/Addons/utils/use-addons";
import ApoLocationInput from "../components/ApoLocationInput";
import ApoSelect from "../components/ApoSelect";
import ApoTextInput from "../components/ApoTextInput";
import BarcodeScannerInput from "../components/BarcodeScannerInput";
import BlueBar from "../components/BlueBar";
import BarcodeHint from "../components/Packing/BarcodeHint";
import PaginatedTable from "../components/PaginatedTable";
import TableSortHeader from "../components/TableSortHeader";
import firebase from "../config";
import { DriverContext } from "../contexts/DriverContext";
import { LayoutContext } from "../contexts/LayoutContext";
import { LoadingPanelContext } from "../contexts/LoadingPanelContext";
import { LocationContext } from "../contexts/LocationContext";
import { MapContext } from "../contexts/MapContext";
import { PaymentContext } from "../contexts/PaymentContext";
import { PlanContext } from "../contexts/PlanContext";
import { TaskContext } from "../contexts/TaskContext";
import { TaskEditContext } from "../contexts/TaskEditContext";
import { TaskRefContext } from "../contexts/TaskRefContext";
import { UserContext } from "../contexts/UserContext";
import { useLocation } from "../hooks/useLocation";
import { usePlan } from "../hooks/usePlan";
import theme from "../theme";
import "../theme/styles.css";
import { getLabelForVehicle } from "../utility/getLabelForVehicle";
var _ = require("lodash");
var ThemeProvider = ThemeContext.Provider;
var DriverSelectBox = function (_a) {
    var _b;
    var disabled = _a.disabled, driver = _a.driver, selected = _a.selected, selectedDriver = _a.selectedDriver, onCheckboxChange = _a.onCheckboxChange, onRadioChange = _a.onRadioChange, onSelectChange = _a.onSelectChange;
    var _c = useState(false), individualStart = _c[0], setIndividualStart = _c[1];
    var _d = useState(false), individualEnd = _d[0], setIndividualEnd = _d[1];
    var _e = useState(undefined), selectedStartPointId = _e[0], setSelectedStartPointId = _e[1];
    var _f = useState(undefined), selectedEndPointId = _f[0], setSelectedEndPointId = _f[1];
    var locations = useContext(LocationContext).locations;
    var areBusinessLocations = locations[0], setBusinessLocations = locations[1];
    var _g = useContext(PaymentContext), locationManagementState = _g.locationManagementState, quotaManagementState = _g.quotaManagementState;
    var setLocationManagementShown = locationManagementState[1];
    var createAddressObj = function () {
        var arr = [];
        //First Side-Locations
        areBusinessLocations
            .filter(function (l) { return !(l === null || l === void 0 ? void 0 : l.archived); })
            .map(function (b) {
            if (b.type !== "main") {
                b.driverId = driver === null || driver === void 0 ? void 0 : driver.id;
                arr.push({ label: b.name, value: JSON.stringify(b) });
            }
        });
        //then individual
        arr.push({
            label: "Individuelle Adresse",
            value: driver.id + "_individual",
        });
        //finally main Address
        areBusinessLocations
            .filter(function (l) { return !(l === null || l === void 0 ? void 0 : l.archived); })
            .map(function (b) {
            if (b.type === "main") {
                b.driverId = driver.id;
                arr.push({ label: b.name, value: JSON.stringify(b) });
            }
        });
        return arr;
    };
    var options = useMemo(function () {
        return driver.vehicles.map(function (vehicle) { return ({
            label: getLabelForVehicle(vehicle),
            value: (driver === null || driver === void 0 ? void 0 : driver.id) + "_" + vehicle,
        }); });
    }, [driver === null || driver === void 0 ? void 0 : driver.id, driver.vehicles]);
    var _h = useState(null), value = _h[0], setValue = _h[1];
    useEffect(function () {
        var _a, _b;
        setSelectedStartPointId((_a = JSON.stringify(selectedDriver === null || selectedDriver === void 0 ? void 0 : selectedDriver.selectedStartPoint)) !== null && _a !== void 0 ? _a : undefined);
        setSelectedEndPointId((_b = JSON.stringify(selectedDriver === null || selectedDriver === void 0 ? void 0 : selectedDriver.selectedEndPoint)) !== null && _b !== void 0 ? _b : undefined);
    }, [selectedDriver]);
    return (_jsxs(Pane, __assign({ backgroundColor: "#ECF0F9", marginRight: 15, style: {
            width: 230,
            flex: "none",
            marginTop: 15,
            overflow: "hidden",
            pointerEvents: disabled ? "none" : "all",
            opacity: disabled ? 0.4 : 1,
        } }, { children: [_jsxs("span", __assign({ onClick: function () { return onCheckboxChange(driver); }, style: { cursor: "pointer" } }, { children: [_jsx(Pane, { height: 5, width: "100%", backgroundColor: driver.color }), _jsxs(Pane, __assign({ display: "flex", flexDirection: "row", alignItems: "center", padding: 5, pointerEvents: "none" }, { children: [_jsx(Checkbox, { checked: selected }), _jsxs(Paragraph, __assign({ fontFamily: "bold", marginLeft: 5, textAlign: "center" }, { children: [driver.firstName, " ", driver.lastName] }))] }))] })), _jsxs(Pane, __assign({ style: {
                    padding: 5,
                    justifyContent: "space-between",
                    display: "flex",
                    flexDirection: "column",
                } }, { children: [_jsx(Paragraph, { children: "Startpunkt" }), _jsx(ApoSelect, { required: true, value: selectedStartPointId, options: createAddressObj(), onChange: function (el) {
                            if (el.target.value.split("_")[1] === "individual") {
                                setIndividualStart(true);
                            }
                            else {
                                setIndividualEnd(false);
                                var obj = JSON.parse(el.target.value);
                                onSelectChange(driver, {
                                    type: "selectedStartPoint",
                                    value: obj,
                                });
                            }
                        } }), individualStart && (_jsx(ApoLocationInput, { placeholder: "Adresse eingeben", marginBottom: 5, required: true, value: (_b = selectedDriver === null || selectedDriver === void 0 ? void 0 : selectedDriver.individualStartPoint) !== null && _b !== void 0 ? _b : undefined, onChange: function (el) {
                            selectedDriver.individualStartPoint = el;
                        } })), _jsx(Paragraph, { children: "Endpunkt" }), _jsx(ApoSelect, { required: true, value: selectedEndPointId, options: createAddressObj(), onChange: function (el) {
                            if (el.target.value.split("_")[1] === "individual") {
                                setIndividualEnd(true);
                            }
                            else {
                                setIndividualEnd(false);
                                var obj = JSON.parse(el.target.value);
                                onSelectChange(driver, {
                                    type: "selectedEndPoint",
                                    value: obj,
                                });
                            }
                        } }), individualEnd && (_jsx(ApoLocationInput, { placeholder: "Adresse eingeben", required: true, onChange: function (el) {
                            selectedDriver.individualEndPoint = el;
                        } })), _jsx(Pane, __assign({ display: "flex", flexDirection: "row", flexWrap: "wrap" }, { children: _jsx(RadioGroup, { label: "Fahrzeuge", value: (selectedDriver === null || selectedDriver === void 0 ? void 0 : selectedDriver.selectedVehicle)
                                ? driver.id + "_" + (selectedDriver === null || selectedDriver === void 0 ? void 0 : selectedDriver.selectedVehicle)
                                : driver.id + "_car", options: options, onChange: function (e) {
                                setValue(e.target.value);
                                onRadioChange(e.target.value);
                            } }) }))] }))] })));
};
export default function OptimizationModal(props) {
    var _a, _b;
    var userPlan = usePlan();
    var apothekeLocations = useLocation();
    var refs = useContext(TaskRefContext).refs;
    var areTaskRefs = refs[0], setTaskRefs = refs[1];
    var layoutState = useContext(LayoutContext).state;
    var isEditMode = layoutState[0], setEditMode = layoutState[1];
    var locations = useContext(LocationContext).locations;
    var areBusinessLocations = locations[0], setBusinessLocations = locations[1];
    var _c = useState(moment().format("HH:mm")), isStarttime = _c[0], setStarttime = _c[1];
    var plan = useContext(PlanContext).plan;
    var isPlan = plan[0], setPlan = plan[1];
    var _d = useContext(UserContext), user = _d.user, ready = _d.ready;
    var isUser = user[0];
    var _e = useState([]), areFilters = _e[0], setFilters = _e[1];
    var drivers = useContext(DriverContext).drivers;
    var areDrivers = drivers[0], setDrivers = drivers[1];
    var _f = useContext(TaskContext), headers = _f.headers, tasks = _f.tasks, tours = _f.tours, ownersOpenTasks = _f.ownersOpenTasks;
    var _g = useState([]), areRouteIds = _g[0], setRouteIds = _g[1];
    var _h = useContext(MapContext), mapRef = _h.mapRef, setMapRef = _h.setMapRef;
    var _j = useState("car"), isSelectedVehicle = _j[0], setSelectedVehicle = _j[1];
    var _k = useState([
        [13.3, 52.5],
        [8.5256412, 51.2005384],
    ]), arePickups = _k[0], setPickups = _k[1];
    var areTasks = tasks[0], setTasks = tasks[1];
    var areTours = tours[0], setTours = tours[1];
    var allOwnersOpenTasks = ownersOpenTasks[0], setAllOwnersOpenTasks = ownersOpenTasks[1];
    console.log(allOwnersOpenTasks.filter(function (t) { return !t.task_date; }));
    var _l = useState(areTasks), areShownTasks = _l[0], setShownTasks = _l[1];
    var _m = useState(false), isLoading = _m[0], setLoading = _m[1];
    var _o = useState(false), isAllSelected = _o[0], setAllSelected = _o[1];
    var state = useContext(LoadingPanelContext).state;
    var loadingPanelShown = state[0], setLoadingPanelShown = state[1];
    var _p = useState(1), currentPage = _p[0], setCurrentPage = _p[1];
    var _q = useState(1), currentDriverPage = _q[0], setCurrentDriverPage = _q[1];
    var _r = useState({}), selectedTasks = _r[0], setSelectedTasks = _r[1];
    var _s = useContext(TaskEditContext), editTasks = _s.tasks, setEditTasks = _s.setTasks;
    //Zentraler Delivery
    var _t = useState(false), centralDelivery = _t[0], setCentralDelivery = _t[1];
    var _u = useState(null), centralDeliveryStartPoint = _u[0], setCentralDeliveryStartPoint = _u[1];
    //Temperatur Tracking
    var _v = useState(false), withTracker = _v[0], setWithTracker = _v[1];
    var _w = useState([]), highlightedTasks = _w[0], setHighlightedTasks = _w[1];
    //PaymentDialog
    var quotaManagementState = useContext(PaymentContext).quotaManagementState;
    var quotaManagementShown = quotaManagementState[0], setQuotaManagementShown = quotaManagementState[1];
    var _x = useState(null), sortNamesBy = _x[0], setSortNamesBy = _x[1]; // null | asc | desc
    var _y = useState(null), apoFilter = _y[0], setApoFilter = _y[1]; // Apos to include in results
    var _z = useState(["unassigned", "prepared"]), stateFilter = _z[0], setStateFilter = _z[1];
    var _0 = useState(""), timeStart = _0[0], setTimeStart = _0[1];
    var _1 = useState(""), timeEnd = _1[0], setTimeEnd = _1[1];
    var _2 = useState(""), search = _2[0], setSearch = _2[1];
    var _3 = useState(""), addressSearch = _3[0], setAddressSearch = _3[1];
    var _4 = useState([]), tagFilter = _4[0], setTagFilter = _4[1];
    var _5 = useState(moment().toISOString()), dialogKey = _5[0], setDialogKey = _5[1];
    var _6 = useState(0), adiutabytePreset = _6[0], setAdiutabytePreset = _6[1];
    var _7 = useState(false), scanModeEnabled = _7[0], setScanModeEnabled = _7[1];
    var _8 = useState(false), isPlanExceeded = _8[0], setIsPlanExceeded = _8[1];
    var adiutabytePresets = [
        {
            label: "apomap Preset",
            value: 0,
        },
        {
            label: "Zeitfensterorientiert",
            value: 2,
        },
        {
            label: "Gebietsorientiert",
            value: 3,
        },
    ];
    var checkTimeWindows = function (startTime, shipments) {
        var tasksWithTimeWindowEndBeforeTourStart = [];
        shipments.map(function (task) {
            try {
                if (moment
                    .unix(task.delivery.time_windows[0].latest)
                    .diff(moment(startTime, "HH:mm"), "seconds") < 0) {
                    tasksWithTimeWindowEndBeforeTourStart.push(task);
                }
            }
            catch (error) { }
            try {
                if (moment
                    .unix(task.pickup.time_windows[0].latest)
                    .diff(moment(startTime, "HH:mm"), "seconds") < 0) {
                    tasksWithTimeWindowEndBeforeTourStart.push(task);
                }
            }
            catch (error) { }
        });
        return tasksWithTimeWindowEndBeforeTourStart;
    };
    var resetFilters = function () {
        setSortNamesBy(null);
        setApoFilter(null);
        setTagFilter([]);
        setAddressSearch("");
        setTimeStart("");
        setTimeEnd("");
        setSearch("");
        setStateFilter(["unassigned", "prepared"]);
    };
    var closeDialog = function () {
        setDialogKey(moment().toISOString());
        props.setIsShown(false);
        resetFilters();
        setCentralDelivery(false);
        setSelectedDrivers({});
        setSelectedTasks({});
        setEditTasks([]);
        setEditMode(false);
        setCurrentPage(1);
        deselectShown();
    };
    var sendOptimizationSettingToAdiutabyte = function (tasks, empty) {
        var mainBranch;
        areBusinessLocations.map(function (location) {
            if (location.type === "main") {
                mainBranch = location;
            }
        });
        var taskIds = tasks.map(function (task) { return task.task_id; });
        //Check if timewindows are in the past
        var timeWindowPast = tasks.filter(function (task) {
            return task.task_latest &&
                moment(task.task_latest, "HH:mm").isBefore(moment(isStarttime, "HH:mm"));
        });
        if (timeWindowPast.length > 0) {
            toaster.warning("Zeitfenster in der Vergangenheit! - Fehlerhafte Aufträge sind rot markiert");
            setHighlightedTasks(function (state) { return __spreadArray(__spreadArray([], state, true), timeWindowPast.map(function (task) { return task.task_id; }), true); });
            return;
        }
        var vehicles = cureTour
            ? [
                {
                    driver: {
                        id: "cure",
                    },
                    startLocation: mainBranch.coords,
                    startLocationId: mainBranch.id,
                    endLocation: mainBranch.coords,
                    endLocationId: mainBranch.id,
                    vehicle: "bike",
                },
            ]
            : Object.values(selectedDrivers).map(function (driver) {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
                return ({
                    driver: {
                        id: driver.id,
                    },
                    startLocation: ((_a = driver === null || driver === void 0 ? void 0 : driver.individualStartPoint) === null || _a === void 0 ? void 0 : _a.coords)
                        ? (_b = driver === null || driver === void 0 ? void 0 : driver.individualStartPoint) === null || _b === void 0 ? void 0 : _b.coords
                        : (_d = (_c = driver === null || driver === void 0 ? void 0 : driver.selectedStartPoint) === null || _c === void 0 ? void 0 : _c.coords) !== null && _d !== void 0 ? _d : mainBranch.coords,
                    startLocationId: ((_e = driver === null || driver === void 0 ? void 0 : driver.individualStartPoint) === null || _e === void 0 ? void 0 : _e.coords)
                        ? "individual"
                        : ((_f = driver === null || driver === void 0 ? void 0 : driver.selectedStartPoint) === null || _f === void 0 ? void 0 : _f.id)
                            ? (_g = driver === null || driver === void 0 ? void 0 : driver.selectedStartPoint) === null || _g === void 0 ? void 0 : _g.id
                            : mainBranch.id,
                    endLocation: ((_h = driver === null || driver === void 0 ? void 0 : driver.individualEndPoint) === null || _h === void 0 ? void 0 : _h.coords)
                        ? (_j = driver === null || driver === void 0 ? void 0 : driver.individualEndPoint) === null || _j === void 0 ? void 0 : _j.coords
                        : (_l = (_k = driver === null || driver === void 0 ? void 0 : driver.selectedEndPoint) === null || _k === void 0 ? void 0 : _k.coords) !== null && _l !== void 0 ? _l : mainBranch.coords,
                    endLocationId: ((_m = driver === null || driver === void 0 ? void 0 : driver.individualEndPoint) === null || _m === void 0 ? void 0 : _m.coords)
                        ? "individual"
                        : ((_o = driver === null || driver === void 0 ? void 0 : driver.selectedEndPoint) === null || _o === void 0 ? void 0 : _o.id)
                            ? (_p = driver === null || driver === void 0 ? void 0 : driver.selectedEndPoint) === null || _p === void 0 ? void 0 : _p.id
                            : mainBranch.id,
                    vehicle: (_q = driver === null || driver === void 0 ? void 0 : driver.selectedVehicle) !== null && _q !== void 0 ? _q : "car",
                });
            });
        var beginTime = moment(isStarttime, "HH:mm").format("HH:mm");
        var startDate = moment(props.selectedDate).format("YYYY-MM-DD");
        var startTime = "".concat(startDate, " ").concat(beginTime);
        var prepareDataForOptimization = firebase
            .app()
            .functions("europe-west3")
            .httpsCallable("prepareDataForOptimization");
        closeDialog();
        if (!empty) {
            firebase
                .firestore()
                .collection("customerPlans")
                .doc(firebase.auth().currentUser.uid)
                .update({
                ongoingOptimizations: firebase.firestore.FieldValue.increment(1),
                ongoingOptimizationsSet: firebase.firestore.Timestamp.now().toMillis() + 5000,
            });
        }
        prepareDataForOptimization(__assign(__assign({ taskIds: taskIds, vehicles: vehicles, startTime: startTime, centralDeliveryStartPoint: centralDelivery
                ? centralDeliveryStartPoint !== null
                    ? __assign(__assign({}, centralDeliveryStartPoint), { coords: [
                            centralDeliveryStartPoint.coords[0],
                            centralDeliveryStartPoint.coords[1],
                        ] }) : null
                : false }, (adiutabytePreset && { preset: adiutabytePreset.value })), { withTracker: withTracker }))
            .then(function (result) {
            if ((result.data && result.data.msg === "error: usagequota exhausted") ||
                result.data.msg === "info: now 0.10€ will be charged") {
                var optIn = window.localStorage.getItem("apomap_feeOptIn");
                setQuotaManagementShown(true);
            }
            setLoading(false);
        })
            .catch(function (err) {
            setLoading(false);
            console.log(err);
        });
    };
    function deselectShown() {
        setEditTasks(function (state) {
            var newState = [];
            return newState;
        });
        try {
            itemsRef.current.map(function (el) { return el.deselectTask(); });
        }
        catch (error) { }
        setSelectedTasks({});
    }
    function makeOptimization(empty) {
        return __awaiter(this, void 0, void 0, function () {
            var filteredTaskIds, main_1, uid, taskResponses, responseData, filteredTasks, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (isPlan.currentUsage > isPlan.availableUsage) {
                            setIsPlanExceeded(true);
                        }
                        if (empty && editTasks.length !== 0) {
                            setEditTasks([]);
                            deselectShown();
                            return [2 /*return*/];
                        }
                        filteredTaskIds = editTasks;
                        if (Object.keys(selectedTasks).length !== 0) {
                            main_1 = __spreadArray(__spreadArray([], editTasks, true), Object.keys(selectedTasks), true);
                            filteredTaskIds = main_1.filter(function (item, index) { return main_1.indexOf(item) === index; });
                        }
                        if (filteredTaskIds.length === 0 && !empty) {
                            toaster.notify("Es muss mindestens ein Auftrag ausgewählt sein!");
                            return [2 /*return*/];
                        }
                        uid = firebase.auth().currentUser.uid;
                        if (!uid) {
                            toaster.notify("Die Optimierung konnte nicht gestartet werden. Bitte versuchen Sie es erneut.");
                            return [2 /*return*/];
                        }
                        taskResponses = filteredTaskIds.map(function (taskId) {
                            return firebase.firestore().collection("tasks").doc(taskId).get();
                        });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, Promise.all(taskResponses)];
                    case 2:
                        responseData = _a.sent();
                        filteredTasks = empty
                            ? []
                            : responseData.map(function (response) { return response.data(); });
                        sendOptimizationSettingToAdiutabyte(filteredTasks, empty);
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        toaster.warning("Die Optimierung konnte nicht gestartet werden, da mindestens ein ausgewählter Auftrag nicht mehr im System verfügbar ist. Bitte versuchen Sie es erneut.");
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    }
    useEffect(function () {
        setStarttime(moment().add(15, "minutes").format("HH:mm"));
    }, [props.isShown]);
    var paginationTable = useRef();
    function selectShown() {
        var currentlyShownTasks = paginationTable.current.getAllTasks();
        setEditTasks(function (state) {
            var newState = __spreadArray([], state, true);
            currentlyShownTasks.map(function (task) {
                if (task.task_status === "unassigned" ||
                    task.task_status === "prepared") {
                    if (!newState.includes(task.task_id)) {
                        newState.push(task.task_id);
                    }
                }
            });
            return newState;
        });
        try {
            itemsRef.current.map(function (el) { return el.selectTask(); });
        }
        catch (error) { }
    }
    var _9 = useState({}), selectedDrivers = _9[0], setSelectedDrivers = _9[1];
    var _10 = useState(false), cureTour = _10[0], setCureTour = _10[1];
    // Filtering search results by selected date
    var handleSearchResultsChange = useCallback(function (results) {
        setShownTasks(results.sort(function (aTask, bTask) {
            var a = aTask.customer_name.split(" ");
            var b = bTask.customer_name.split(" ");
            var aSur = a[a.length - 1];
            var bSur = b[b.length - 1];
            switch (sortNamesBy) {
                case null: {
                    return bTask.task_created.seconds - aTask.task_created.seconds;
                }
                case "asc": {
                    return aSur.localeCompare(bSur);
                }
                case "desc": {
                    return bSur.localeCompare(aSur);
                }
                default: {
                    return 0;
                }
            }
        }));
    }, []);
    var itemsRef = useRef([]);
    useEffect(function () {
        setShownTasks(areTasks.sort(function (aTask, bTask) {
            var a = aTask.customer_name.split(" ");
            var b = bTask.customer_name.split(" ");
            var aSur = a[a.length - 1];
            var bSur = b[b.length - 1];
            switch (sortNamesBy) {
                case null: {
                    return bTask.task_created.seconds - aTask.task_created.seconds;
                }
                case "asc": {
                    return aSur.localeCompare(bSur);
                }
                case "desc": {
                    return bSur.localeCompare(aSur);
                }
                default: {
                    return 0;
                }
            }
        }));
    }, [
        areTasks,
        timeStart,
        timeEnd,
        apoFilter,
        stateFilter,
        search,
        addressSearch,
        tagFilter,
        sortNamesBy,
    ]);
    useEffect(function () {
        itemsRef.current = itemsRef.current.slice(0, areTasks.length);
    }, [areTasks]);
    var shownTasks = useMemo(function () {
        return areShownTasks.filter(function (task) {
            return task.task_status === "unassigned" || task.task_status === "prepared";
        });
    }, [areShownTasks]);
    // * #################################
    var tasksToPlan = allOwnersOpenTasks.flatMap(function (task) {
        var _a, _b, _c, _d;
        var newTaskDate;
        var planTasks = [];
        var filterDate = moment(props.selectedDate).format("YYYY-MM-DD");
        var presentDate = moment().format("YYYY-MM-DD");
        if (((_a = task.task_date) === null || _a === void 0 ? void 0 : _a.nanoseconds) > 0) {
            newTaskDate = moment
                .unix(((_b = task.task_date) === null || _b === void 0 ? void 0 : _b.seconds) + ((_c = task.task_date) === null || _c === void 0 ? void 0 : _c.nanoseconds) / 1000000000)
                .format("YYYY-MM-DD");
        }
        else {
            newTaskDate = moment
                .unix(((_d = task.task_date) === null || _d === void 0 ? void 0 : _d.seconds) + 0)
                .format("YYYY-MM-DD");
        }
        if (filterDate > presentDate) {
            if (newTaskDate === filterDate) {
                planTasks.push(task);
            }
        }
        if (filterDate === presentDate) {
            if (newTaskDate <= filterDate) {
                planTasks.push(task);
            }
        }
        return planTasks.flatMap(function (task) { return task !== null && task !== void 0 ? task : []; });
    });
    // * #################################
    var sortForNames = useCallback(function (tasks) {
        return tasks.sort(function (aTask, bTask) {
            var a = aTask.customer_name.split(" ");
            var b = bTask.customer_name.split(" ");
            var aSur = a[a.length - 1];
            var bSur = b[b.length - 1];
            switch (sortNamesBy) {
                case null: {
                    return bTask.task_created.seconds - aTask.task_created.seconds;
                }
                case "asc": {
                    return aSur.localeCompare(bSur);
                }
                case "desc": {
                    return bSur.localeCompare(aSur);
                }
                default: {
                    return 0;
                }
            }
        });
    }, [sortNamesBy]);
    var openTasks = useMemo(function () {
        return sortForNames(tasksToPlan.filter(function (_a) {
            var task_status = _a.task_status, isMerged = _a.isMerged;
            return (task_status === "unassigned" || task_status === "prepared") &&
                !isMerged;
        }));
    }, [sortForNames, tasksToPlan]);
    // * #################################
    useEffect(function () {
        Object.keys(selectedTasks).map(function (taskId) {
            itemsRef.current.map(function (ref) {
                if (ref !== null) {
                    if ((ref === null || ref === void 0 ? void 0 : ref.getId()) === taskId) {
                        ref.selectTask();
                    }
                }
            });
        });
    }, [selectedTasks, shownTasks]);
    //Highlight error tasks
    useEffect(function () {
        if (highlightedTasks.length > 0)
            highlightedTasks.map(function (taskId) {
                itemsRef.current.map(function (ref) {
                    if (ref !== null) {
                        if (ref.getId() === taskId) {
                            ref.highlight();
                        }
                    }
                });
            });
    }, [highlightedTasks]);
    // Select all apos on liad
    useEffect(function () {
        if (apoFilter === null) {
            if (locations[0].length !== 0) {
                setApoFilter(locations[0].map(function (loc) { return loc.id; }));
            }
        }
    }, [apoFilter, locations]);
    var availableDrivers = useMemo(function () { return areDrivers.filter(function (driver) { return !driver.archived; }); }, [areDrivers]);
    // * #####################
    var _11 = useState(0), currentPaginatedPage = _11[0], setCurrentPaginatedPage = _11[1];
    var handlePageChange = function (page) {
        setCurrentPaginatedPage(page);
    };
    // * #####################
    var _12 = useAddons(), checkPriceIdUsability = _12.checkPriceIdUsability, loading = _12.loading;
    var canUseTemperatureTracking = useMemo(function () { return checkPriceIdUsability("price_1Nrb89GjTI7kgs1jReGKe5Qn"); }, [checkPriceIdUsability]);
    return (_jsx(ThemeProvider, __assign({ value: theme }, { children: _jsx(Dialog, __assign({ preventBodyScrolling: true, isShown: props.isShown, shouldCloseOnOverlayClick: false, shouldCloseOnEscapePress: false, hasHeader: false, hasFooter: false, contentContainerProps: {
                marginTop: 0,
                marginBottom: 0,
                padding: 0,
                justifyContent: "space-between",
            }, style: { marginTop: 0, marginBottom: 0 }, containerProps: {
                marginTop: 0,
                marginBottom: 0,
            }, width: "100vw" }, { children: _jsxs(Pane, { children: [_jsx(BlueBar, {}), _jsx(CrossIcon, { onClick: closeDialog, style: {
                            position: "absolute",
                            right: 20,
                            top: 20,
                            cursor: "pointer",
                        } }), currentPage === 1 && (_jsx(_Fragment, { children: _jsxs(Pane, __assign({ style: {
                                width: "100%",
                                padding: 20,
                            } }, { children: [_jsxs(Pane, __assign({ style: { display: "flex", flexDirection: "column" } }, { children: [_jsx(Paragraph, __assign({ fontFamily: "bold", marginBottom: 5, size: 500 }, { children: "1. GRUNDEINSTELLUNGEN" })), _jsxs(Pane, __assign({ style: { display: "flex", flexDirection: "row" } }, { children: [_jsxs(Pane, { children: [_jsx(Paragraph, __assign({ fontFamily: "bold", marginBottom: 5, size: 500 }, { children: "Startzeit w\u00E4hlen" })), _jsx(ApoTextInput, { value: isStarttime, type: "time", onChange: function (e) { return setStarttime(e.target.value); } })] }), _jsxs(Pane, __assign({ marginLeft: 50 }, { children: [_jsx(Paragraph, __assign({ fontFamily: "bold", marginBottom: 10, size: 500 }, { children: "Zentraler Botendienst" })), _jsxs(Pane, __assign({ style: {
                                                                alignItems: "center",
                                                                display: "flex",
                                                                flexDirection: "row",
                                                            } }, { children: [_jsx(Switch, { checked: centralDelivery, onChange: function () { return setCentralDelivery(!centralDelivery); } }), _jsx(Combobox, { marginLeft: 10, disabled: !centralDelivery, items: areBusinessLocations
                                                                        .filter(function (loc) { return !loc.archived; })
                                                                        .map(function (_a) {
                                                                        var coords = _a.coords, name = _a.name, id = _a.id;
                                                                        return ({
                                                                            coords: coords,
                                                                            id: id,
                                                                            name: name,
                                                                        });
                                                                    }), onChange: function (selected) {
                                                                        return setCentralDeliveryStartPoint(selected);
                                                                    }, itemToString: function (item) { return (item ? item.name : ""); }, placeholder: "Standort w\u00E4hlen" })] }))] })), canUseTemperatureTracking ? (_jsxs(Pane, __assign({ marginLeft: 50 }, { children: [_jsx(Paragraph, __assign({ fontFamily: "bold", marginBottom: 10, size: 500 }, { children: "Temperaturtracking" })), _jsxs(Pane, __assign({ display: "flex", flexDirection: "row", alignItems: "center", marginY: "6px" }, { children: [_jsx(Switch, { checked: withTracker, onChange: function () { return setWithTracker(function (state) { return !state; }); } }), _jsx(Paragraph, __assign({ marginLeft: "6px" }, { children: "Temperatur-Tracker notwendig" }))] }))] }))) : (_jsx(AddonHintBox, { title: "Temperatur w\u00E4hrend der Fahrt messen? ", subTitle: "Buche jetzt unser Temperatur-Tracking Modul inklusive Tracker." })), _jsxs(Pane, __assign({ marginLeft: 50 }, { children: [_jsx(Paragraph, __assign({ fontFamily: "bold", marginBottom: 10, size: 500 }, { children: "Routenoptimierung" })), _jsx(Pane, __assign({ style: {
                                                                alignItems: "center",
                                                                display: "flex",
                                                                flexDirection: "row",
                                                            } }, { children: _jsx(Combobox, { marginLeft: 10, items: adiutabytePresets.map(function (_a) {
                                                                    var label = _a.label, value = _a.value;
                                                                    return ({
                                                                        label: label,
                                                                        value: value,
                                                                    });
                                                                }), onChange: function (selected) { return setAdiutabytePreset(selected); }, itemToString: function (item) { return (item ? item.label : ""); }, placeholder: "apomap Preset" }) }))] }))] })), _jsx(Paragraph, __assign({ fontFamily: "bold", marginBottom: 5, size: 500 }, { children: "2. FAHRER*INNEN W\u00C4HLEN" })), _jsxs(Pane, __assign({ style: {
                                                width: "100%",
                                                display: "flex",
                                                flexWrap: "wrap",
                                                padding: 5,
                                                marginBottom: 85,
                                            } }, { children: [availableDrivers.map(function (driver) { return (_jsx(DriverSelectBox, { disabled: cureTour, selected: selectedDrivers[driver.id] ? true : false, selectedDriver: selectedDrivers[driver.id], driver: driver, onRadioChange: function (value) {
                                                        return setSelectedDrivers(function (state) {
                                                            var newState = __assign({}, state);
                                                            if (newState[value.split("_")[0]]) {
                                                                newState[value.split("_")[0]].selectedVehicle =
                                                                    value.split("_")[1];
                                                            }
                                                            else {
                                                                newState[value.split("_")[0]] = driver;
                                                                newState[value.split("_")[0]].selectedVehicle =
                                                                    value.split("_")[1];
                                                            }
                                                            return newState;
                                                        });
                                                    }, onSelectChange: function (driver, obj) {
                                                        return setSelectedDrivers(function (state) {
                                                            var newState = __assign({}, state);
                                                            newState[driver.id] = driver;
                                                            if (obj) {
                                                                newState[driver.id][obj.type] = __assign({}, obj.value);
                                                            }
                                                            return newState;
                                                        });
                                                    }, onCheckboxChange: function (driver) {
                                                        return setSelectedDrivers(function (state) {
                                                            var newState = __assign({}, state);
                                                            if (newState[driver.id]) {
                                                                delete newState[driver.id];
                                                            }
                                                            else {
                                                                newState[driver.id] = driver;
                                                            }
                                                            return newState;
                                                        });
                                                    } })); }), (isUser === null || isUser === void 0 ? void 0 : isUser.motionToolsApiKey) && (_jsxs(Pane, __assign({ backgroundColor: "#ECF0F9", marginRight: 15, style: {
                                                        width: 230,
                                                        flex: "none",
                                                        marginTop: 15,
                                                        overflow: "hidden",
                                                    } }, { children: [_jsx(Pane, { height: 5, width: "100%", backgroundColor: "rgb(7,112,101)" }), _jsxs(Pane, __assign({ display: "flex", flexDirection: "row", alignItems: "center", padding: 5 }, { children: [_jsx(Checkbox, { onChange: function (e) { return setCureTour(e.target.checked); }, checked: cureTour }), _jsx(Paragraph, __assign({ fontFamily: "bold", marginLeft: 5, textAlign: "center" }, { children: "Cure Fahrer" }))] })), _jsx(Pane, __assign({ padding: 6 }, { children: _jsx(Paragraph, { children: "Lasse deine Auftr\u00E4ge von Cure Fahrern ausliefern. Wenn Du diese Option w\u00E4hlst, werden die Auftr\u00E4ge an Cure \u00FCbertragen und verarbeitet." }) }))] })))] }))] })), _jsxs(Pane, __assign({ style: {
                                        display: "flex",
                                        padding: 20,
                                        justifyContent: "space-between",
                                        position: "absolute",
                                        bottom: 0,
                                        width: "calc(100% - 40px)",
                                        backgroundColor: "white",
                                        zIndex: 10,
                                    } }, { children: [_jsx(Button, __assign({ size: "large", appearance: "primary", onClick: closeDialog }, { children: "Abbrechen" })), _jsx(Button, __assign({ size: "large", appearance: "primary", intent: "success", onClick: function () {
                                                return Object.keys(selectedDrivers).length > 0 || cureTour
                                                    ? setCurrentPage(2)
                                                    : toaster.notify("Sie müssen mindestens einen Fahrer wählen!");
                                            } }, { children: "Weiter" }))] }))] })) })), currentPage === 2 && (_jsx(_Fragment, { children: _jsxs(Pane, __assign({ style: {
                                width: "100%",
                                padding: 20,
                                paddingBottom: 0,
                            } }, { children: [_jsx(BarcodeScannerInput, { onSubmission: function (data) {
                                        if (!scanModeEnabled)
                                            return;
                                        // Process apomap-Task__ UUID Scans
                                        if (data.startsWith("apomap-Task__")) {
                                            var taskId_1 = data.substring("apomap-Task__".length);
                                            var task_1 = tasksToPlan.find(function (t) { return t.task_id === taskId_1; });
                                            if (task_1 === undefined) {
                                                toaster.warning("Der gescannte Auftrag existiert entweder nicht, oder er kann aktuell nicht geplant werden.");
                                                return;
                                            }
                                            setEditTasks(function (state) { return __spreadArray(__spreadArray([], state, true), [task_1.task_id], false); });
                                            setSelectedTasks(function (state) {
                                                var newState = __assign({}, state);
                                                if (!newState[task_1.task_id]) {
                                                    newState[task_1.task_id] = task_1;
                                                }
                                                return newState;
                                            });
                                            return;
                                        }
                                        if (data.length < 4) {
                                            // This input is likely a mistake, so just ignore it silently
                                            return;
                                        }
                                        // If nothing else matched, this is likely a job number, so attempt that
                                        var task = tasksToPlan.find(function (t) { return t.task_job_no === data; });
                                        if (task === undefined) {
                                            toaster.warning("Der gescannte Auftrag existiert entweder nicht, oder er kann aktuell nicht geplant werden.");
                                            return;
                                        }
                                        setEditTasks(function (state) { return __spreadArray(__spreadArray([], state, true), [task.task_id], false); });
                                        setSelectedTasks(function (state) {
                                            var newState = __assign({}, state);
                                            if (!newState[task.task_id]) {
                                                newState[task.task_id] = task;
                                            }
                                            return newState;
                                        });
                                    }, active: scanModeEnabled }), _jsx(Heading, __assign({ size: "large", marginBottom: 20 }, { children: "TOURENPLANUNG" })), _jsxs(Pane, __assign({ display: "flex", flexDirection: "row", alignItems: "center" }, { children: [_jsx(Heading, __assign({ style: {
                                                color: "#2D3B51",
                                                marginBottom: 15,
                                                marginTop: 15,
                                            }, size: "medium" }, { children: "3. AUFTR\u00C4GE AUSW\u00C4HLEN" })), _jsxs(Paragraph, __assign({ marginLeft: "10" }, { children: [editTasks.length, " Auftr\u00E4ge gew\u00E4hlt"] }))] })), _jsxs(Pane, __assign({ display: "flex", flexDirection: "row", marginBottom: 15, alignItems: "center" }, { children: [_jsx(Button, __assign({ onKeyDown: function (e) { return console.log(e); }, marginRight: 15, onClick: function () { return selectShown(); } }, { children: "Alle anw\u00E4hlen" })), _jsx(Button, __assign({ marginRight: 15, onClick: function () { return deselectShown(); } }, { children: "Alle abw\u00E4hlen" })), _jsxs(Pane, __assign({ display: "flex", flexDirection: "row", alignItems: "center" }, { children: [_jsx(Switch, { marginLeft: 30, marginRight: 10, checked: scanModeEnabled, onChange: function (evt) { return setScanModeEnabled(evt.target.checked); } }), _jsx(Paragraph, { children: "Scan Modus" })] }))] })), _jsx(Pane, __assign({ style: { overflow: "scroll", paddingBottom: "85px" } }, { children: _jsxs(Table, { children: [_jsx(Table.Head, __assign({ style: { backgroundColor: "#F8FBFF", padding: 12 } }, { children: !scanModeEnabled ? (_jsxs(_Fragment, { children: [_jsx("div", __assign({ style: {
                                                                position: "relative",
                                                            } }, { children: _jsx(TableSortHeader, { searchPlaceholder: "Nach Namen filtern...", search: true, searchState: search, onSearchValueChange: setSearch, sort: true, sortState: sortNamesBy, onSortSet: setSortNamesBy, filter: true, filterTitle: "Nach Tags filtern", filterOptions: (_b = (_a = isUser === null || isUser === void 0 ? void 0 : isUser.tags) === null || _a === void 0 ? void 0 : _a.map(function (t) {
                                                                    return { label: t, value: t };
                                                                })) !== null && _b !== void 0 ? _b : [], filterState: tagFilter, onFilterChanged: setTagFilter, filterResetBehavior: "none" }) })), _jsx(TableSortHeader, { title: "Status", filter: true, filterTitle: "Nach Status filtern", filterOptions: [
                                                                {
                                                                    label: "Nicht zugeteilt",
                                                                    value: "unassigned",
                                                                },
                                                                {
                                                                    label: "Zugeteilt",
                                                                    value: "assigned",
                                                                },
                                                                {
                                                                    label: "Vorbereitet",
                                                                    value: "prepared",
                                                                },
                                                                {
                                                                    label: "Angehangen",
                                                                    value: "attached",
                                                                },
                                                                {
                                                                    label: "Auf dem Weg",
                                                                    value: "ontheway",
                                                                },
                                                                {
                                                                    label: "Nicht angetroffen",
                                                                    value: "declined",
                                                                },
                                                                {
                                                                    label: "Erfolgreich",
                                                                    value: "successfull",
                                                                },
                                                                {
                                                                    label: "In Optimierung",
                                                                    value: "optimizing",
                                                                },
                                                                {
                                                                    label: "Fehlerhaft",
                                                                    value: "error",
                                                                },
                                                            ], filterState: stateFilter, onFilterChanged: setStateFilter, filterResetBehavior: "all" }), _jsx(TableSortHeader, { title: "Adresse", searchPlaceholder: "Nach Adresse filtern...", search: true, searchState: addressSearch, onSearchValueChange: setAddressSearch }), _jsx(TableSortHeader, { title: "Apotheke", filter: true, filterTitle: "Nach Apotheke filtern", filterSearch: true, filterOptions: locations[0].map(function (loc) {
                                                                return { label: loc.name, value: loc.id };
                                                            }), filterState: apoFilter, onFilterChanged: setApoFilter, filterResetBehavior: "all" }), _jsx(TableSortHeader, { title: "Zeitfenster", timespanFilter: true, timeStart: timeStart, timeEnd: timeEnd, onTimeStartChange: setTimeStart, onTimeEndChange: setTimeEnd })] })) : (_jsx("span", __assign({ style: {
                                                        textTransform: "none",
                                                        fontWeight: "normal",
                                                    } }, { children: _jsxs(BarcodeHint, { children: ["Bitte verwenden Sie Ihren Barcode Scanner,", _jsx("br", {}), "um Auftr\u00E4ge auszuw\u00E4hlen"] }) }))) })), _jsx(Table.Body, { children: _jsx(PaginatedTable, { itemsRef: itemsRef, ref: paginationTable, data: openTasks, plan: userPlan, extended: true, select: true, setSelectedTasks: setSelectedTasks, locations: apothekeLocations, search: search, stateFilter: stateFilter, timeStart: timeStart, timeEnd: timeEnd, addressSearch: addressSearch, tagFilter: tagFilter, apoFilter: apoFilter }) })] }) })), _jsxs(Pane, __assign({ style: {
                                        display: "flex",
                                        padding: 20,
                                        justifyContent: "space-between",
                                        position: "absolute",
                                        bottom: 0,
                                        width: "calc(100% - 40px)",
                                        backgroundColor: "white",
                                        zIndex: 10,
                                    } }, { children: [_jsx(Button, __assign({ size: "large", appearance: "primary", marginRight: 10, onClick: function () {
                                                setCurrentPage(1);
                                            } }, { children: "ZUR\u00DCCK" })), _jsxs(Pane, { children: [_jsx(Button, __assign({ marginRight: 10, size: "large", appearance: "secondary", intent: "success", isLoading: isLoading, onClick: function () { return makeOptimization(true); } }, { children: "LEERE TOUR ERSTELLEN" })), _jsx(Button, __assign({ size: "large", appearance: "primary", intent: "success", isLoading: isLoading, onClick: function () { return makeOptimization(false); } }, { children: "TOUR ERSTELLEN" }))] })] }))] })) }))] }, dialogKey) })) })));
}
