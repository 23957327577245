var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-nocheck
import { Pagination, Pane } from "evergreen-ui";
import { forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useState, } from "react";
import { TaskRefContext } from "../contexts/TaskRefContext";
import compareTimes from "../utility/compareTimes";
import { paginate } from "../utility/paginate";
import { Task } from "./Task";
export default forwardRef(function PaginatedTable(_a, ref) {
    var data = _a.data, editMode = _a.editMode, isExpandedView = _a.isExpandedView, selectedDate = _a.selectedDate, plan = _a.plan, locations = _a.locations, extended = _a.extended, select = _a.select, setSelectedTasks = _a.setSelectedTasks, search = _a.search, stateFilter = _a.stateFilter, timeStart = _a.timeStart, timeEnd = _a.timeEnd, addressSearch = _a.addressSearch, tagFilter = _a.tagFilter, apoFilter = _a.apoFilter, setVisibleTasks = _a.setVisibleTasks, itemsRef = _a.itemsRef;
    var refs = useContext(TaskRefContext).refs;
    var setTaskRefs = refs[1];
    var tasksPerPage = 50;
    var pageCount = Math.ceil(data.length / tasksPerPage);
    var _b = useState(data), filteredData = _b[0], setFilteredData = _b[1];
    var _c = useState(1), currentPaginatedPage = _c[0], setCurrentPaginatedPage = _c[1];
    var handlePageChange = function (page) {
        setCurrentPaginatedPage(page);
    };
    useEffect(function () {
        var updatedData = data
            .filter(function (task) {
            var _a;
            if (search !== "") {
                if (!isNaN(parseInt(search)) || (search === null || search === void 0 ? void 0 : search.startsWith(".A"))) {
                    return (_a = task.task_job_no) === null || _a === void 0 ? void 0 : _a.includes(search);
                }
                else if (search === null || search === void 0 ? void 0 : search.startsWith("apomap-Task")) {
                    var _b = search === null || search === void 0 ? void 0 : search.split("apomap-Task__"), prefix = _b[0], taskId = _b[1];
                    return task.task_id === taskId;
                }
                else {
                    return task.customer_name
                        .toLowerCase()
                        .includes(search.toLowerCase());
                }
            }
            return true;
        })
            .filter(function (task) {
            // Address Filter
            if (addressSearch !== "") {
                return task.task_address
                    .toLowerCase()
                    .includes(addressSearch.toLowerCase());
            }
            return true;
        })
            .filter(function (task) { return stateFilter.includes(task.task_status); })
            .filter(function (task) {
            // Ist kein Zeitfenster gesetzt aber der Filter ist gesetzt, alle ohne Zeitfenster nicht zeigen
            if ((timeStart !== "" || timeEnd !== "") &&
                (task.task_earliest === "" || task.task_latest === "")) {
                return false;
            }
            // Startzeit testen
            if (timeStart !== "") {
                var timeDiff = compareTimes(task.task_earliest, timeStart);
                if (timeDiff === -1) {
                    // Zeitfenster liegt vor gesetzter Zeit
                    return false;
                }
            }
            // Endzeit testen
            if (timeEnd !== "") {
                var timeDiff = compareTimes(task.task_latest, timeEnd);
                if (timeDiff === 1) {
                    // Zeitfenster liegt nach gesetzter Zeit
                    return false;
                }
            }
            return true;
        })
            .filter(function (task) {
            if (apoFilter === null)
                return true;
            return apoFilter.includes(task.task_origin);
        })
            .filter(function (task) {
            // Tag Filter
            if (tagFilter.length === 0)
                return true;
            if (task.task_tags !== undefined &&
                task.task_tags !== null &&
                task.task_tags.length !== 0) {
                for (var _i = 0, _a = task.task_tags; _i < _a.length; _i++) {
                    var tag = _a[_i];
                    if (tagFilter.includes(tag)) {
                        return true;
                    }
                }
                return false;
            }
            return false;
        });
        setFilteredData(updatedData);
    }, [
        addressSearch,
        apoFilter,
        data,
        search,
        stateFilter,
        tagFilter,
        timeEnd,
        timeStart,
    ]);
    var paginatedTasks = useMemo(function () { return paginate(filteredData, currentPaginatedPage, tasksPerPage); }, [currentPaginatedPage, filteredData]);
    useImperativeHandle(ref, function () {
        return {
            getShownTasks: function () {
                return paginatedTasks;
            },
            getAllTasks: function () {
                return filteredData;
            },
            getAllRefs: function () {
                console.log("Run");
                return itemsRef;
            },
        };
    }, [filteredData, itemsRef, paginatedTasks]);
    return (_jsxs(Pane, { children: [_jsx(Pane, { children: paginatedTasks.map(function (task, index) { return (_jsx(Task, { editMode: editMode, isExpandedView: isExpandedView, ref: function (el) { return (itemsRef === null || itemsRef === void 0 ? void 0 : itemsRef.current[index] = el); }, task: task, index: index, selectedDate: selectedDate, plan: plan, locations: locations, extended: extended, select: select, setSelectedTasks: setSelectedTasks }, task.task_id)); }) }), data.length > 50 && (_jsx(Pane, __assign({ elevtion: 4, style: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "40px",
                    padding: "1rem",
                    margin: "0 auto",
                } }, { children: _jsx(Pagination, { marginY: 16, page: currentPaginatedPage, totalPages: pageCount, onPageChange: handlePageChange }) })))] }));
});
