var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { Alert, Heading, Pane, Paragraph, Spinner, Switch, Table, TableBody, } from 'evergreen-ui';
import 'firebase/firestore';
import { useEffect, useMemo, useState } from 'react';
import { CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis, } from 'recharts';
import firebase from '../config';
import useTempTrackerStore from '../store/tempTrackerStore';
import calculateTrackerStats from '../utility/calculateTempTrackerStats';
import processTrackers from '../utility/processTrackers';
import { AddonHintBox } from './Addons';
import { useAddons } from './Addons/utils/use-addons';
import TourTrackerListItem from './TourTrackerListItem';
dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.extend(timezone);
function convertToBerlinTime(utcTime, date) {
    var format = 'HH:mm'; // Expected input and output format
    // We add a default date (e.g., today) for the conversion to work properly.
    var utcDateTime = "".concat(date, " ").concat(utcTime); // Combine the date with the given time
    // Convert from UTC to Europe/Berlin timezone
    var berlinTime = dayjs
        .utc(utcDateTime, 'YY-MM-DD HH:mm')
        .tz('Europe/Berlin');
    return berlinTime.format(format);
}
var Chart = function (_a) {
    var date = _a.date;
    var responses = useTempTrackerStore().responses;
    // Check if responses array is empty
    if (responses.length === 0) {
        return null;
    }
    // Find the response with the most data points
    var maxDataPointsResponse = responses[0];
    responses.forEach(function (response) {
        if (response.time.length > maxDataPointsResponse.time.length) {
            maxDataPointsResponse = response;
        }
    });
    var data = maxDataPointsResponse.time.map(function (time, index) {
        var tempData = {};
        responses.forEach(function (response) {
            tempData[response.trackerName] = response.temp[index];
        });
        var convertedTime = convertToBerlinTime(time, date);
        return __assign({ time: convertedTime }, tempData);
    });
    var colors = ['#8884d8', '#82ca9d', '#ffc658']; // Define an array of colors for each line
    return (data.length > 0 && (_jsxs(LineChart, __assign({ width: 500, height: 300, data: data }, { children: [_jsx(CartesianGrid, { strokeDasharray: "3 3" }), _jsx(XAxis, { dataKey: "time" }), _jsx(YAxis, {}), _jsx(Tooltip, {}), _jsx(Legend, {}), responses.map(function (response, index) { return (_jsx(Line, { type: "monotone", dataKey: response.trackerName, name: response.trackerName, stroke: colors[index % colors.length], dot: false }, response.trackerName)); })] }))));
};
export default function TourTrackerList(_a) {
    var _this = this;
    var tour = _a.tour;
    var _b = useState(false), withTracker = _b[0], setWithTracker = _b[1];
    var _c = useTempTrackerStore(), responses = _c.responses, setResponse = _c.setResponse;
    var trackerStats = useMemo(function () { return calculateTrackerStats(responses); }, [responses]);
    var updateTemperatureTrackingSettings = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, firebase.firestore().collection('tours').doc(tour.id).update({
                        withTracker: !withTracker,
                    })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        setWithTracker(tour === null || tour === void 0 ? void 0 : tour.withTracker);
        return function () {
            setResponse([]);
        };
    }, [setResponse, tour]);
    useEffect(function () {
        if (withTracker) {
            processTrackers(tour.trackers, tour.date, tour.date, tour.actualStartTime, tour.actualEndTime, 0 // false
            );
        }
    }, [
        tour.id,
        tour.actualEndTime,
        tour.actualStartTime,
        tour.date,
        tour.trackers,
        withTracker,
    ]);
    var checkPriceIdUsability = useAddons().checkPriceIdUsability;
    var canUseTemperatureTracking = useMemo(function () { return checkPriceIdUsability('price_1Nrb89GjTI7kgs1jReGKe5Qn'); }, [checkPriceIdUsability]);
    return (_jsxs(_Fragment, { children: [canUseTemperatureTracking ? (_jsxs(_Fragment, { children: [_jsx(Pane, __assign({ display: "inline-block", flexDirection: "row", alignItems: "center", marginY: "6px", position: "relative" }, { children: _jsxs(Pane, __assign({ display: "flex", flexDirection: "row", alignItems: "center", marginY: "6px" }, { children: [_jsx(Switch, { checked: withTracker, onChange: updateTemperatureTrackingSettings }), _jsx(Paragraph, __assign({ marginLeft: "6px" }, { children: "Temperatur-Tracker notwendig" }))] })) })), !(tour === null || tour === void 0 ? void 0 : tour.trackers) && withTracker && (_jsx(Pane, __assign({ padding: 4, marginBottom: 8 }, { children: _jsx(Alert, __assign({ intent: "warning", title: "Noch kein Tracker zugeteilt" }, { children: "Scanne den QR-Code auf dem Temperatur-Tracker, mit der apomap Fahrer-App, um ihn dieser Tour zuzuweisen." })) })))] })) : (_jsx(AddonHintBox, { title: "Temperatur w\u00E4hrend der Fahrt messen? ", subTitle: "Buche jetzt unser Temperatur-Tracking Modul inklusive Tracker." })), _jsx(_Fragment, { children: withTracker && (tour === null || tour === void 0 ? void 0 : tour.trackers) && (_jsxs(Pane, { children: [_jsx(Heading, { children: "Registrierte Tracker" }), _jsx(Table, __assign({ marginY: 12 }, { children: _jsx(TableBody, __assign({ display: "flex", justifyContent: "space-between", alignItems: "start", gap: 12, padding: 12, elevation: 4 }, { children: !responses || responses.length === 0 ? (_jsxs(Pane, __assign({ display: "flex", justifyContent: "center", alignItems: "center", gap: 10 }, { children: [_jsx(Spinner, { size: 24 }), _jsx(Paragraph, { children: "Loading..." })] }))) : (_jsxs(_Fragment, { children: [_jsx(Pane, __assign({ style: {
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'space-evenly',
                                                alignItems: 'start',
                                                border: '1px solid #eee',
                                                width: '60%',
                                            } }, { children: trackerStats.map(function (tracker, index) {
                                                return tracker.maxTemp === 'n/a' ? (_jsxs(Paragraph, __assign({ margin: 6 }, { children: ["Wir haben noch keine aktuellen Daten des Trackers", _jsxs("b", { children: [" ", tracker.trackerName, " "] }), "\u00FCbermittelt bekommen. Dies kann bis zu 10 Minuten in Anspruch nehmen."] }))) : (_jsx(TourTrackerListItem, { tracker: tracker }, index));
                                            }) })), _jsx(Pane, { children: _jsx(Chart, { date: tour.date, responses: responses }, tour.id) })] })) })) }))] })) })] }));
}
